import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BC_Product } from "../../api/types/BC_Product";
import FontStyle from "./fontstyle";

interface FontProps {
	productConfig: BC_Product | undefined;
	editConfiguration: boolean;
	noValue: string;
}
export default function Fonts(props: FontProps) {
	if (props?.productConfig?.data?.fonts || props.editConfiguration)
		return (
			<>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<FontStyle
						name="Big Body"
						style={props?.productConfig?.data?.fonts?.styles?.big_body}
						noValue={props.noValue}
					/>
					<FontStyle
						name="Big Title"
						style={props?.productConfig?.data?.fonts?.styles?.big_title}
						noValue={props.noValue}
					/>
					<FontStyle
						name="Body"
						style={props?.productConfig?.data?.fonts?.styles?.body}
						noValue={props.noValue}
					/>

					<FontStyle
						name="Button"
						style={props?.productConfig?.data?.fonts?.styles?.button}
						noValue={props.noValue}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<FontStyle
						name="Control active"
						style={props.productConfig?.data?.fonts?.styles?.control_active}
						noValue={props.noValue}
					/>
					<FontStyle
						name="Control inactive"
						style={props?.productConfig?.data?.fonts?.styles?.control_inactive}
						noValue={props.noValue}
					/>
					<FontStyle
						name="Header 1"
						style={props?.productConfig?.data?.fonts?.styles?.header_1}
						noValue={props.noValue}
					/>
					<FontStyle
						name="Header 2"
						style={props?.productConfig?.data?.fonts?.styles?.header_2}
						noValue={props.noValue}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<FontStyle
						name="Label"
						style={props?.productConfig?.data?.fonts?.styles?.label}
						noValue={props.noValue}
					/>

					<FontStyle
						name="Link"
						style={props?.productConfig?.data?.fonts?.styles?.link}
						noValue={props.noValue}
					/>
					<FontStyle
						name="Subtitle"
						style={props?.productConfig?.data?.fonts?.styles?.subtitle}
						noValue={props.noValue}
					/>

					<FontStyle
						name="Tab active"
						style={props?.productConfig?.data?.fonts?.styles?.tab_active}
						noValue={props.noValue}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<FontStyle
						name="Tab inactive"
						style={props?.productConfig?.data?.fonts?.styles?.tab_inactive}
						noValue={props.noValue}
					/>
					<FontStyle
						name="Title"
						style={props?.productConfig?.data?.fonts?.styles?.title}
						noValue={props.noValue}
					/>
					<FontStyle
						name="Huge title"
						style={props?.productConfig?.data?.fonts?.styles?.huge_title}
						noValue={props.noValue}
					/>

					<Col></Col>
				</Form.Group>
				<br />
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<Form.Label
						column
						sm={2}
					>
						Regular
					</Form.Label>
					<Col sm={4}>
						<Form.Control
							required={props?.productConfig?.data?.fonts?.fonts?.regular === undefined}
							type="input"
							id="regular_font"
							placeholder={props?.productConfig?.data?.fonts?.fonts?.regular ?? props.noValue}
						/>
						<Form.Text muted>{props?.productConfig?.data?.fonts?.fonts?.regular ?? "No changes to regular font"}</Form.Text>
					</Col>
					<Form.Label
						column
						sm={2}
					>
						Bold
					</Form.Label>
					<Col sm={4}>
						<Form.Control
							required={props?.productConfig?.data?.fonts?.fonts?.bold === undefined}
							type="input"
							id="bold_font"
							placeholder={props?.productConfig?.data?.fonts?.fonts?.bold ?? props.noValue}
						/>
						<Form.Text muted>{props?.productConfig?.data?.fonts?.fonts?.bold ?? "No changes to bold font"}</Form.Text>
					</Col>
				</Form.Group>
			</>
		);
	else return <>No changes to Fonts</>;
}
