import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { getButtonActions } from "../../api/brandconfig_apimanager_v2";
import { BC_Product } from "../../api/types/BC_Product";
import { ButtonType } from "../../api/types/ButtonType";
import { ListItem } from "../../api/types/ListItem";

interface Props {
	productConfig: BC_Product | undefined;
	productType: string | undefined;
	editConfiguration: boolean;
	noValue: string;
	buttonActions: ListItem[] | undefined;
}

//TODO Replace the buttonoptions to here

export default function ButtonOptions(props: Props) {
	if ((props?.productConfig?.data?.button_options || props.editConfiguration))
		return (
			<Row>
				<Col>
					<br />
					Double press
					<Select
						isMulti
						closeMenuOnSelect={false}
						required
						options={props.buttonActions}
						defaultValue={props.buttonActions?.filter((e) => props?.productConfig?.data?.button_options?.double_press.find((ee) => ee === e.value) !== undefined)}
						name="double_press"
						id="double_press"
					/>
					Double tap
					<Select
						isMulti
						closeMenuOnSelect={false}
						required
						options={props.buttonActions}
						defaultValue={props.buttonActions?.filter((e) => props?.productConfig?.data?.button_options?.double_tap.find((ee) => ee === e.value) !== undefined)}
						name="double_tap"
						id="double_tap"
					/>
					Long press
					<Select
						isMulti
						closeMenuOnSelect={false}
						required
						options={props.buttonActions}
						defaultValue={props.buttonActions?.filter((e) => props?.productConfig?.data?.button_options?.long_press.find((ee) => ee === e.value) !== undefined)}
						name="long_press"
						id="long_press"
					/>
					Short press
					<Select
						isMulti
						closeMenuOnSelect={false}
						required
						options={props.buttonActions}
						defaultValue={props.buttonActions?.filter((e) => props?.productConfig?.data?.button_options?.short_press.find((ee) => ee === e.value) !== undefined)}
						name="short_press"
						id="short_press"
					/>
					Single tap
					<Select
						isMulti
						closeMenuOnSelect={false}
						required
						options={props.buttonActions}
						defaultValue={props.buttonActions?.filter((e) => props?.productConfig?.data?.button_options?.single_tap.find((ee) => ee === e.value) !== undefined)}
						name="single_tap"
						id="single_tap"
					/>
				</Col>
			</Row>
		);

	else return <>No changes to Button Options</>;
}
