import React from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { BC_EQpreset } from "../../api/types/BC_Product";
import { isNotEmpty } from "../helpers";
import { WarningLabel } from "./warninglabel";

interface Props {
	id: number;
	preset: BC_EQpreset | undefined;
	noValue: string;
}

//TODO add a tooltip to show the min/max values
export default function EQprofile(props: Props) {
	var i = props.id;
	return (
		<>
			<Accordion.Header>
				Preset {i} {props.preset ? "" : `${props.noValue}`}
			</Accordion.Header>
			<Accordion.Body>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<Col>
						<Form.Label>Name NL</Form.Label>
						<Form.Control
							required={props?.preset?.name[0].value === undefined}
							id={`eq_preset_${i}_nl`}
							type="input"
							placeholder={props?.preset?.name[0].value ?? props.noValue}
						></Form.Control>
					</Col>
					<Col>
						<Form.Label>Name EN</Form.Label>
						<Form.Control
							required={props?.preset?.name[1].value === undefined}
							id={`eq_preset_${i}_en`}
							type="input"
							placeholder={props?.preset?.name[1].value ?? props.noValue}
						></Form.Control>
					</Col>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<Col>
						<Form.Label>
							{" "}
							Values <WarningLabel />{" "}
						</Form.Label>
						<br />
						<Form.Text>Pre-gain</Form.Text>
						<Form.Control
							required={props?.preset?.values[0] === undefined}
							className="mb-3"
							id={`eq_preset_${i}_0`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[0]) ? props?.preset?.values[0].toString() : props.noValue}
							></Form.Control>
					</Col>
					<Row>
						<Col>
							<Form.Text>Frequency</Form.Text>

							<Form.Control
							required={props?.preset?.values[1] === undefined}
							id={`eq_preset_${i}_1`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[1]) ? props?.preset?.values[1].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Text>Q</Form.Text>

							<Form.Control
							required={props?.preset?.values[2] === undefined}
							id={`eq_preset_${i}_2`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[2]) ? props?.preset?.values[2].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Text>Gain</Form.Text>

							<Form.Control
							required={props?.preset?.values[3] === undefined}
							id={`eq_preset_${i}_3`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[3]) ? props?.preset?.values[3].toString() : props.noValue}
							></Form.Control>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Control
							required={props?.preset?.values[4] === undefined}
							id={`eq_preset_${i}_4`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[4]) ? props?.preset?.values[4].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Control
							required={props?.preset?.values[5] === undefined}
							id={`eq_preset_${i}_5`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[5]) ? props?.preset?.values[5].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Control
							required={props?.preset?.values[6] === undefined}
							id={`eq_preset_${i}_6`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[6]) ? props?.preset?.values[6].toString() : props.noValue}
							></Form.Control>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Control
							required={props?.preset?.values[7] === undefined}
							id={`eq_preset_${i}_7`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[7]) ? props?.preset?.values[7].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Control
							required={props?.preset?.values[8] === undefined}
							id={`eq_preset_${i}_8`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[8]) ? props?.preset?.values[8].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Control
							required={props?.preset?.values[9] === undefined}
							id={`eq_preset_${i}_9`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[9]) ? props?.preset?.values[9].toString() : props.noValue}
							></Form.Control>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Control
							required={props?.preset?.values[10] === undefined}
							id={`eq_preset_${i}_10`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[10]) ? props?.preset?.values[10].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Control
							required={props?.preset?.values[11] === undefined}
							id={`eq_preset_${i}_11`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[11]) ? props?.preset?.values[11].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Control
							required={props?.preset?.values[12] === undefined}
							id={`eq_preset_${i}_12`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[12]) ? props?.preset?.values[12].toString() : props.noValue}
							></Form.Control>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Control
							required={props?.preset?.values[13] === undefined}
							id={`eq_preset_${i}_13`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[13]) ? props?.preset?.values[13].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Control
							required={props?.preset?.values[14] === undefined}
							id={`eq_preset_${i}_14`}
							type="input"
							placeholder={isNotEmpty(props?.preset?.values[14]) ? props?.preset?.values[14].toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Control
							required={props?.preset?.values[15] === undefined}
								id={`eq_preset_${i}_15`}
								type="input"
								placeholder={isNotEmpty(props?.preset?.values[15]) ? props?.preset?.values[15].toString() : props.noValue}
							></Form.Control>
						</Col>
					</Row>
				</Form.Group>
			</Accordion.Body>
		</>
	);
}
