import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { addCustomer, addDepartment, getTenant } from "../api/orderportal_apimanager";
import { Tenant } from "../api/types/Tenant";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import toastManager from "../modules/toastmanager";

export default function Company() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const [pressedAdd, setPressedAdd] = useState<boolean>(false);
	const tenantID = localStorage.getItem("tenant");

	const [tenant, setTenant] = useState<Tenant>();

	function handleAddCompany(event: any) {
		setPressedAdd(true);
		const etf = event.target.form;
		if (etf.name.value && etf.description.value && etf.email.value && etf.address1.value && etf.address2.value && etf.logourl.value) {
			if (tenantID) {
				addCustomer(tenantID, etf.name.value, etf.description.value, etf.email.value, etf.address1.value, etf.address2.value, etf.logourl.value).then((response) => {
					if (response) {
						toastManager("success", `Succesfully added ${etf.name.value}`, `Added ${etf.name.value} to ${tenant?.name}`);
						setPressedAdd(false);
						addDepartment(response.id, "Default", "Default department, automatically added to the customer", "0", "0", "0dB", "0dB").then((response) => {
							toastManager("success", "Default department added", `Successfully added a default department to ${etf.name.value}`);
						});
					}
				});
			} else {
				toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");
			}
		}
	}

	useEffect(
		function RetrieveTenant() {
			if (tenantID && token && token.length)
				getTenant(token, tenantID).then((response) => {
					setTenant(response as Tenant);
				});
		},
		[tenantID, token]
	);

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<Container className="paddingTopBottom">
					<BackButton
						route="/home"
						title="homepage"
					/>
					<h1>Add a new Customer for tenant {tenant?.name}</h1>
					<Form validated={pressedAdd}>
						<Row>
							<Col>
								<Form.Label className="fw-bold">Customer name:</Form.Label>
								<Form.Control
									required
									type="text"
									maxLength={50}
									id="name"
								></Form.Control>
							</Col>
							<Col>
								<Form.Label className="fw-bold">Contact email:</Form.Label>
								<Form.Control
									required
									type="email"
									maxLength={100}
									id="email"
								></Form.Control>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Label className="fw-bold">Customer description:</Form.Label>
								<Form.Control
									required
									as="textarea"
									maxLength={250}
									id="description"
									rows={1}
								></Form.Control>
							</Col>
							<Col>
								<Form.Label className="fw-bold">Logo url</Form.Label>
								<Form.Control
									required
									type="text"
									maxLength={400}
									id="logourl"
								></Form.Control>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Label className="fw-bold">Addressline 1</Form.Label>
								<Form.Control
									required
									type="text"
									maxLength={50}
									id="address1"
								></Form.Control>
							</Col>
							<Col>
								<Form.Label className="fw-bold">Addressline 2</Form.Label>
								<Form.Control
									required
									type="text"
									maxLength={50}
									id="address2"
								></Form.Control>
							</Col>
						</Row>
						<br />

						<Button
							variant="primary"
							className="right"
							onClick={handleAddCompany}
						>
							<Icon.BuildingsFill /> Add customer
						</Button>
					</Form>
				</Container>
			)}
			{!isAuthenticated && (
				<NotSignedIn/>
			)}
		</>
	);
}
