import * as React from "react";
import { Container } from "react-bootstrap";
import StatusComponent from "../modules/statuscomponent";

export default function Status() {
	const queryParameters = new URLSearchParams(window.location.search);
	const orderid = queryParameters.get("orderid");
	if (orderid)
		return (
			<>
				<Container className="paddingTopBottom">
					<h1>Status:</h1>
					<StatusComponent orderid={orderid} />
				</Container>
			</>
		);
	else {
		return <></>;
	}
}
