import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";


// This renders a tooltip when hovering over the warning label
const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Only change this if you know what you are doing
  </Tooltip>
);

// Adds a warning label when <Warning/> is added to an item 
export const WarningLabel = () => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}>
      <Badge bg="danger">Danger</Badge>
    </OverlayTrigger>
  )
}
