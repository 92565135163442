import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BC_Product } from "../../api/types/BC_Product";
import { isNotEmpty } from "../helpers";

interface DeviceConfigProps {
	productConfig: BC_Product | undefined;
	editConfiguration: boolean;
	noValue: string;
}

export default function DeviceConfig(props: DeviceConfigProps) {
	if (props?.productConfig?.data?.device_config || props.editConfiguration)
		return (
			<>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<Col>
						<Form.Label>Actions</Form.Label>
						<Form.Control
							required
							id="level_detect_actions"
							type="input"
							placeholder={isNotEmpty(props?.productConfig?.data?.device_config?.level_detect_actions) ? props?.productConfig?.data.device_config.level_detect_actions.toString() : props.noValue}
						></Form.Control>
					</Col>
					<Col>
						<Form.Label>Audio profile</Form.Label>
						<Form.Control
							required
							id="level_detect_audio_profile"
							type="input"
							placeholder={
								isNotEmpty(props?.productConfig?.data?.device_config?.level_detect_audio_profile) ? props?.productConfig?.data.device_config.level_detect_audio_profile.toString() : props.noValue
							}
						></Form.Control>
					</Col>
					<Col>
						<Form.Label>High</Form.Label>
						<Form.Control
							required
							id="level_detect_high"
							type="input"
							placeholder={isNotEmpty(props?.productConfig?.data?.device_config?.level_detect_high) ? props?.productConfig?.data.device_config.level_detect_high.toString() : props.noValue}
						></Form.Control>
					</Col>
					<Col>
						<Form.Label>Low</Form.Label>
						<Form.Control
							required
							id="level_detect_low"
							type="input"
							placeholder={isNotEmpty(props?.productConfig?.data?.device_config?.level_detect_low) ? props?.productConfig?.data.device_config.level_detect_low.toString() : props.noValue}
						></Form.Control>
					</Col>
					<Col sm={1}>
						<Form.Label>On/off</Form.Label>
						<Form.Check
							type="switch"
							id="level_detect_on"
							defaultChecked={props?.productConfig?.data?.device_config?.level_detect_on}
						/>
					</Col>
					<Col>
						<Form.Label>VPU</Form.Label>
						<Form.Select
							required
							id="vpu"
							defaultValue={props?.productConfig?.data?.device_config?.vpu}
						>
							<option value="enabled">Enabled</option>
							<option value="disabled">Disabled</option>
							<option value="user"> User</option>
						</Form.Select>
					</Col>
					<Col>
						<Form.Label>Volume balance</Form.Label>
						<Form.Select
							required
							id="volume_balance_options"
							defaultValue={props?.productConfig?.data?.device_config?.volume_balance_options}
						>
							<option value="custom">Custom</option>
							<option value="left_right">Left right</option>
							<option value="disabled">Disabled</option>
						</Form.Select>
					</Col>
				</Form.Group>
			</>
		);
	else return <>No changes to Device config</>;
}
