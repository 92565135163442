import * as React from "react";
import { Accordion } from "react-bootstrap";
import AudioProfile from "./audioprofile";
import { BC_Product } from "../../api/types/BC_Product";

interface AudioProfileProps {
	productConfig: BC_Product | undefined;
	editConfiguration: boolean;
	noValue: string;

}

export default function AudioProfiles(props: AudioProfileProps) {
	if (props?.productConfig?.data?.audio_profiles || props.editConfiguration)
		return (
			<>
				<Accordion alwaysOpen>
					<AudioProfile
						name="Profile 1"
						audioprofile={props?.productConfig?.data?.audio_profiles?.profile1}
						noValue={props.noValue}
					/>
					<AudioProfile
						name="Profile 2"
						audioprofile={props?.productConfig?.data?.audio_profiles?.profile2}
						noValue={props.noValue}

					/>
					<AudioProfile
						name="Profile 3"
						audioprofile={props?.productConfig?.data?.audio_profiles?.profile3}
						noValue={props.noValue}

					/>
				</Accordion>
			</>
		);
	else return <>No changes to Audio profiles</>;
}
