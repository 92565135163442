import * as React from "react";
import { useEffect, useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Consumer } from "../api/types/Consumer";
import { ListItem } from "../api/types/ListItem";

interface Props {
	consumers: Consumer[];
	departments: ListItem[] | undefined;
	firstnameFilter: string;
	lastnameFilter: string;
	nicknameFilter: string;
	departmentFilter: string;
	productsFilter: number;
	customer: string;
}

export default function ConsumersList(props: Props) {
	var returnConsumers: JSX.Element[] | undefined = [];
	const navigate = useNavigate();

	const [consumers, setConsumers] = useState<Consumer[] | undefined>();

	// Get the department name from the value
	function GetDepartmentName(departmentID: string) {
		const found = props.departments?.find((dep) => {
			return dep.value === departmentID;
		});
		if (found) return found.label;
		else {
			return "No department found"; // This should not be possible and an incorrect department is used
		}
	}

	useEffect(() => {
		setConsumers(props.consumers);
	}, [props.consumers]);

	if (consumers !== undefined) {
		var cons = consumers;
		if (props.productsFilter === -1) {
			cons = consumers;
		} else if (props.productsFilter < 3) {
			cons = consumers.filter((c) => c.product_count === props.productsFilter);
		} else if (props.productsFilter === 3) {
			cons = consumers.filter((c) => c.product_count >= props.productsFilter);
		}

		cons.filter(
			(consumer) =>
				consumer.given_name.toLowerCase().includes(props.firstnameFilter.toLowerCase()) &&
				consumer.family_name.toLowerCase().includes(props.lastnameFilter.toLowerCase()) &&
				consumer.short_name.toLowerCase().includes(props.nicknameFilter.toLowerCase()) &&
				consumer.department.toLowerCase().includes(props.departmentFilter.toLowerCase())
		).forEach((filteredConsumer) => {
			var departmentName: string = GetDepartmentName(filteredConsumer.department);
			if (returnConsumers !== undefined) {
				returnConsumers.push(
					<ListGroup.Item
						key={filteredConsumer.id}
						action
						onClick={() => {
							navigate(`/profile?user=${filteredConsumer.id}&customer=${props.customer}`);
						}}
					>
						<Row>
							<Col>{filteredConsumer.given_name}</Col>
							<Col className="text-truncate">{filteredConsumer.family_name}</Col>
							<Col className="d-none d-lg-block ">{filteredConsumer.short_name}</Col>
							<Col className="text-truncate d-none d-lg-block">{departmentName}</Col>
							<Col className="center">{filteredConsumer.product_count}</Col>
						</Row>
					</ListGroup.Item>
				);
			}
		});
	}

	return <>{returnConsumers}</>;
}
