import axios, { AxiosInstance } from "axios";
import { Buffer } from "buffer";
import { toast } from "sonner";
import ErrorHandler from "./errorhandler";
import { ImageType } from "./types/ImageType";

var client: AxiosInstance;

export function createCDNClient(token: string) {
	if (!client)
		client = axios.create({
			baseURL: process.env.REACT_APP_CDN_BASEURL,
			headers: {
				"Access-Control-Allow-Origin": "*",
				Accept: "*/*",
				"x-app-id": "nl.earsonly.ribbon",
				Authorization: `Bearer ${token}`,
				"Content-Type": "multipart/form-data",
			},
		});
}

/**
 * * This tag is required for the Hermes store
 * @returns A base64url tag based on the current time
 */
export function generateTag(): string {
	// This should've worked with base64url instead of base64 encoding.... but it doesn't.
	// Maybe it suddenly works in the future and if so, all the replace methods and the trimEnd method can be removed
	// and the 'base64' encoding can be replaced by 'base64url' :)
	return Buffer.from(Math.round(new Date().getTime() / 60000) + "", "utf-8")
		.toString("base64")
		.replace("=", "")
		.replace("+", "-")
		.replace("/", "_")
		.trimEnd();
}

/**
 * * Retrieves a file from the cache, for images use GetImageFromCache()
 * @param token JWT token
 * @param tenant_id ID of the current tenant
 * @param customer_id ID of the customer this file is stored for
 * @param document_id The UUID of the wanted file
 * @returns A file
 */
export async function getFileFromCache(token: string, store_name: "tassel" | "hermes", tenant_id: string, customer_id: string, document_id: string) {
	if (!client) {
		createCDNClient(token);
	}
	try {
		const { data } = await client.get(`/cache/${store_name}/${tenant_id}/${customer_id}/${document_id}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

/**
 * * Retrieves a file from the store, for images use GetImageFromStore()
 *
 * @param token JWT token
 * @param store_name The name of the store the file is for ['tassel' | 'hermes']
 * @param tenant_id ID of the current tenant
 * @param customer_id ID of the customer this file is stored for
 * @param document_id The UUID of the wanted file
 * @returns A file
 */
export async function getFileFromStore(token: string, store_name: "tassel" | "hermes", tenant_id: string, customer_id: string, document_id: string) {
	if (!client) {
		createCDNClient(token);
	}

	if (store_name === "tassel") {
		try {
			const { data } = await client.get(`/store/${store_name}/${tenant_id}/${customer_id}/${document_id}`);
			return data;
		} catch (error) {
			ErrorHandler(error);
			return undefined;
		}
	}

	if (store_name === "hermes") {
		var tag = generateTag();
		try {
			const { data } = await client.get(`/store/${store_name}/${tenant_id}/${customer_id}/${document_id}/?tag=${tag}`);
			return data;
		} catch (error) {
			ErrorHandler(error);
			return undefined;
		}
	}
}
/**
 * * Download a file from the store
 *
 * @param token JWT token
 * @param store_name The name of the store the file is for ['tassel' | 'hermes']
 * @param tenant_id ID of the current tenant
 * @param customer_id ID of the customer this file is stored for
 * @param document_id The UUID of the wanted file
 * @returns A file
 */
export async function downloadFileFromStore(token: string, store_name: "tassel" | "hermes", document_id: string) {
	if (!client) {
		createCDNClient(token);
	}

	if (store_name === "tassel") {
		try {
			const { data } = await client.get(`${document_id}`);
			return data;
		} catch (error) {
			ErrorHandler(error);
			return undefined;
		}
	}

	if (store_name === "hermes") {
		var tag = generateTag();
		try {
			const { data } = await client.get(`${document_id}/download?tag=${tag}`);
			return data;
		} catch (error) {
			ErrorHandler(error);
			return undefined;
		}
	}
}

/**
 *  * Retrieves an image from the cache, for files use GetFilesFromCache
 * @param token JWT token
 * @param store_name The name of the store the image is for ['tassel' | 'hermes']
 * @param tenant_id ID of the current tenant
 * @param customer_id ID of the customer the image is stored for
 * @param document_id The UUID of the wanted image
 * @returns An image
 */
export async function getImageFromHermesCache(tenant_id: string, customer_id: string, document_id: string) {
	if (!client) {
		toast.error("Something went wrong, please reload the page and try again.");
	}
	try {
		const { data, headers } = await client.get(`/cache/hermes/${tenant_id}/${customer_id}/${document_id}`, { responseType: "arraybuffer" });
		let base64ImageString = Buffer.from(data, "binary").toString("base64");
		var image: ImageType = { base64: base64ImageString, title: headers["x-dopplecdn-filename"] };
		return image as ImageType;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
/**
 *  * Retrieves an image from the cache, for files use GetFilesFromCache
 * @param token JWT token
 * @param document_id The UUID of the wanted image
 * @returns An image
 */
export async function getImageFromTasselCache(document_id: string) {
	if (!client) {
		toast.error("Something went wrong, please reload the page and try again.");
	}
	try {
		const { data, headers, request } = await client.get(`/cache/tassel/${document_id}`, { responseType: "arraybuffer" });
		let base64ImageString = Buffer.from(data, "binary").toString("base64");
		var image: ImageType = { base64: base64ImageString, title: headers["x-dopplecdn-filename"] };
		return image as ImageType;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

/**
 * * Retrieves an image from the selected store
 * @param token JWT token
 * @param store_name The name of the store the image is for ['tassel' | 'hermes']
 * @param tenant_id ID of the current tenant
 * @param customer_id ID of the customer the image is stored for
 * @param document_id The UUID of the wanted image
 * @returns An image as ImageType {base64: string; title: string;}
 */
export async function getImageFromStore(store_name: "tassel" | "hermes", tenant_id: string, customer_id: string, document_id: string) {
	if (!client) {
		toast.error("Something went wrong, please reload the page and try again.");
	}
	try {
		const { data, headers } = await client.get(`/store/${store_name}/${tenant_id}/${customer_id}/${document_id}`, { responseType: "arraybuffer" });
		let base64ImageString = Buffer.from(data, "binary").toString("base64");
		var image: ImageType = { base64: base64ImageString, title: headers["x-dopplecdn-filename"] };
		return image as ImageType;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
/**
 * * Retrieves an image from the selected store
 * @param token JWT token
 * @param store_name The name of the store the image is for ['tassel' | 'hermes']
 * @param tenant_id ID of the current tenant
 * @param customer_id ID of the customer the image is stored for
 * @param document_id The UUID of the wanted image
 * @returns An image as ImageType {base64: string; title: string;}
 */
export async function getImageFromTasselStore(document_id: string) {
	if (!client) {
		toast.error("Something went wrong, please reload the page and try again.");
	}
	try {
		const { data, headers } = await client.get(`/store/tassel/${document_id}`, { responseType: "arraybuffer" });
		let base64ImageString = Buffer.from(data, "binary").toString("base64");
		var image: ImageType = { base64: base64ImageString, title: headers["x-dopplecdn-filename"] };
		return image as ImageType;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

/**
 * * Uploads a file to the cache, after saving the file url in a monitored space, the file gets sent to permanent storage automatically
 * @param store_name The name of the store the file is for ['tassel' | 'hermes']
 * @param tenant_id ID of the current tenant
 * @param customer_id ID of the customer this file is uploaded for
 * @param file The file that has to be uploaded
 * @returns Object with success and data object with documentid as document
 */
export async function uploadToHermesCache(tenant_id: string, customer_id: string, file: File) {
	if (!client) {
		toast.error("Something went wrong, please reload the page and try again.");
	}

	try {
		const { data } = await client.post(`/upload`, {
			appId: "hermes",
			tenantId: tenant_id,
			customerId: customer_id,
			file: file,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
/**
 * * Uploads a file to the cache, after saving the file url in a monitored space, the file gets sent to permanent storage automatically
 * @param store_name The name of the store the file is for ['tassel' | 'hermes']
 * @param tenant_id ID of the current tenant
 * @param customer_id ID of the customer this file is uploaded for
 * @param file The file that has to be uploaded
 * @returns Object with success and data object with documentid as document
 */
export async function uploadToTasselCache(file: File) {
	if (!client) {
		toast.error("Something went wrong, please reload the page and try again.");
	}

	try {
		const { data } = await client.post(`/upload`, {
			appId: "tassel",
			file: file,
		});

		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
