import * as React from "react";
import { Accordion } from "react-bootstrap";
import { BC_Product } from "../../api/types/BC_Product";
import EQprofile from "./eqprofile";

interface EqualizerProps {
	productConfig: BC_Product | undefined;
	editConfiguration: boolean;
	noValue: string;

}

export default function Equalizer(props: EqualizerProps) {
	if (props?.productConfig?.data?.equalizer || props.editConfiguration)
		return (
			<>
				<Accordion>
					<Accordion.Item eventKey="3d">
						<EQprofile
							id={1}
							preset={props?.productConfig?.data?.equalizer?.presets[0]}
							noValue={props.noValue}
						/>
					</Accordion.Item>
					<Accordion.Item eventKey="3e">
						<EQprofile
							id={2}
							preset={props?.productConfig?.data?.equalizer?.presets[1]}
							noValue={props.noValue}

						/>
					</Accordion.Item>
					<Accordion.Item eventKey="3f">
						<EQprofile
							id={3}
							preset={props?.productConfig?.data?.equalizer?.presets[2]}
							noValue={props.noValue}

						/>
					</Accordion.Item>
					<Accordion.Item eventKey="3g">
						<EQprofile
							id={4}
							preset={props?.productConfig?.data?.equalizer?.presets[3]}
							noValue={props.noValue}

						/>
					</Accordion.Item>
					<Accordion.Item eventKey="3h">
						<EQprofile
							id={5}
							preset={props?.productConfig?.data?.equalizer?.presets[4]}
							noValue={props.noValue}

						/>
					</Accordion.Item>
					<Accordion.Item eventKey="3i">
						<EQprofile
							id={6}
							preset={props?.productConfig?.data?.equalizer?.presets[5]}
							noValue={props.noValue}

						/>
					</Accordion.Item>
				</Accordion>
			</>
		);
        else return <>No changes to Equalizer</>
}
