import axios, { AxiosInstance } from "axios";
import ErrorHandler from "./errorhandler";
import { BC_Brand } from "./types/BC_Brand";
import { BC_Product } from "./types/BC_Product";
import toastManager from "../modules/toastmanager";

var client: AxiosInstance;
export function createBCClient(token: string) {
	client = axios.create({
		baseURL: process.env.REACT_APP_BRANDCONFIG_BASEURL,
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
}

export async function getButtonActions(productType: string) {
	var temp_client = axios.create({
		baseURL: "https://app.earsonly.nl/extended-api/buttons/app",
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"x-app-id": "nl.earsonly.portal",
		},
	});
	try {
		const { data } = await temp_client.get(`/${productType}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
	}
}

//#region getTree
export async function getProductTree(token: string) {
	if (!client) createBCClient(token);
	if (client)
		try {
			const { data } = await client.get(`/config/v4/product/tree`);
			return data;
		} catch (error) {
			ErrorHandler(error);
		}
}

export async function getBrandTree(token: string, tenantID: string) {
	if (!client) createBCClient(token);
	if (client)
		try {
			const { data } = await client.get(`/config/v4/brand/tree`, {
				headers: {
					"x-app-tenant-id": tenantID,
				},
			});
			return data;
		} catch (error) {
			ErrorHandler(error);
		}
}
//#endregion

//#region Product configuration
/**
 *
 * @param refineSearch Add a trailing '/' when not empty
 * @returns
 */
export async function getProductConfig(token: string, refineSearch: string) {
	if (!client) createBCClient(token);
	if (client)
		try {
			const { data } = await client.get(`/config/v4/${refineSearch}`);
			return data as BC_Product;
		} catch (error) {
			ErrorHandler(error);
		}
}
export async function postProductConfig(refineSearch: string, configdata: any) {
	if (client)
		try {
			const { data } = await client.post(`/config/v4/${refineSearch}`, configdata);
			return data;
		} catch (error) {
			ErrorHandler(error);
		}
}

export async function updateProductConfig(refineSearch: string, configdata: any) {
	if (client)
		try {
			const { data } = await client.put(`/config/v4/${refineSearch}`, configdata);
			return data;
		} catch (error: any) {
			if (error.response.status === 404) {
				postProductConfig(refineSearch, configdata).then((response) => {
					toastManager("success", `Created product config: ${response.config_name}`, "");
					return response;
				});
			} else ErrorHandler(error);
		}
}
export async function deleteProductConfig(refineSearch: string, configdata: any) {
	if (client)
		try {
			const { data } = await client.delete(`/config/v4/${refineSearch}`, configdata);
			return data;
		} catch (error) {
			ErrorHandler(error);
		}
}

//#endregion

//#region Brand configuration

/**
 *
 * @param refineSearch Add a trailing '/' when not empty
 * @returns
 */
export async function getBrandConfig(token: string, tenantID: string, refineSearch: string) {
	if (!client) createBCClient(token);
	if (client)
		try {
			const { data } = await client.get(`/config/v4/brand${refineSearch}`, {
				headers: {
					"x-app-tenant-id": tenantID,
				},
			});
			return data as BC_Brand;
		} catch (error) {
			ErrorHandler(error);
		}
}

export async function postBrandConfig(refineSearch: string, tenantID: string, configdata: any) {
	if (client)
		try {
			const { data } = await client.post(`/config/v4/brand${refineSearch}`, configdata, {
				headers: {
					"x-app-tenant-id": tenantID,
				},
			});
			return data;
		} catch (error) {
			ErrorHandler(error);
		}
}

export async function updateBrandConfig(refineSearch: string, tenantID: string, configdata: any) {
	if (client)
		try {
			const { data } = await client.put(`/config/v4/brand${refineSearch}`, configdata, {
				headers: {
					"x-app-tenant-id": tenantID,
				},
			});
			return data;
		} catch (error: any) {
			if (error.response.status === 404) {
				postBrandConfig(refineSearch, tenantID, configdata).then((response) => {
					toastManager("success", `Created brandconfig: ${response.config_name}`, "");
					return error;
				});
			} else ErrorHandler(error);
		}
}
//#endregion

//#region Helper functions
/**
 * @param hexValue A hex-formatted string '#FF6D2D'
 * @param alpha A number between 0-100
 * @returns Color object {red: 0-255, green: 0-255, blue 0-255, alpha 0-100}
 */
export function convertColorToRGBA(hexValue: string, alpha: string) {
	var color = {
		red: parseInt(hexValue.substring(1, 3), 16),
		green: parseInt(hexValue.substring(3, 5), 16),
		blue: parseInt(hexValue.substring(5, 7), 16),
		alpha: parseInt(alpha),
	};
	return color;
}

//#endregion
