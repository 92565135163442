import React from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { BC_Product } from "../../api/types/BC_Product";
import ImageForm from "./imageform";

interface Props {
	productConfig: BC_Product | undefined;
	editConfiguration: boolean;
	noValue: string;
}

export default function Assets(props: Props) {
	function PopulateAssetGroups() {
		var temp_assets_array: JSX.Element[] = [];
		props?.productConfig?.data?.assets?.assets.forEach((assetColor, index) => {
			temp_assets_array.push(
				<Accordion.Item eventKey={assetColor.color_code}>
					<Accordion.Header>{assetColor.color_code}</Accordion.Header>
					<Accordion.Body>
						<Form.Group
							as={Row}
							className="mb-3"
						>
							<Col>
								<Form.Check
									defaultChecked
									label={"Include"}
								/>
								<Form.Label>Color code: (001-999)</Form.Label>
								<Form.Control
									type="input"
										inputMode="numeric"
										minLength={3}
									maxLength={3}
									id="color_code"
									pattern="[0-9]{3}"
									placeholder={assetColor.color_code}
								/>
							</Col>
						</Form.Group>
						<Row>
							<Col className="fw-bold center">Cradle closed</Col>
							<Col className="fw-bold center">Cradle open</Col>
						</Row>
						<Row>
							<ImageForm
								name={`cradle_closed`}
								path={assetColor.assets.cradle_closed}
							/>
							<ImageForm
								name="cradle_open"
								path={assetColor.assets.cradle_open}
							/>
						</Row>
						<hr />
						<Row>
							<Col className="fw-bold center">Left earbud front</Col>
							<Col className="fw-bold center">Right earbud front</Col>
						</Row>
						<Row>
							<ImageForm
								name={`left_earbud_front`}
								path={assetColor.assets.earbud_left}
							/>

							<ImageForm
								name="right_earbud_front"
								path={assetColor.assets.earbud_right}
							/>
						</Row>
						<hr />

						<Row>
							<Col className="fw-bold center">Left earbud back</Col>
							<Col className="fw-bold center">Right earbud back</Col>
						</Row>
						<Row>
							<ImageForm
								name={`left_earbud_back`}
								path={assetColor.assets.earbud_left_back}
							/>
							<ImageForm
								name={`right_earbud_back`}
								path={assetColor.assets.earbud_right_back}
							/>
						</Row>
						<hr />

						<Row>
							<Col className="fw-bold center">Left in cradle</Col>
							<Col className="fw-bold center">Right in cradle</Col>
						</Row>
						<Row>
							<ImageForm
								name={`left_in_cradle`}
								path={assetColor.assets.left_in_cradle}
							/>
							<ImageForm
								name={`right_in_cradle`}
								path={assetColor.assets.right_in_cradle}
							/>
						</Row>
						<hr />
						<Row>
							<Col className="fw-bold center">Left outside cradle</Col>
							<Col className="fw-bold center">Right outside cradle</Col>
						</Row>

						<Row>
							<ImageForm
								name={`left_outside_cradle`}
								path={assetColor.assets.left_outside_cradle}
							/>
							<ImageForm
								name="right_outside_cradle"
								path={assetColor.assets.right_outside_cradle}
							/>
						</Row>
						<hr />
						<Row>
							<Col className="fw-bold center">Left outside cradle primary</Col>
							<Col className="fw-bold center">Right outside cradle primary</Col>
						</Row>
						<Row>
							<ImageForm
								name="left_outside_cradle_primary"
								path={assetColor.assets.left_outside_cradle_primary}
							/>
							<ImageForm
								name="right_outside_cradle_primary"
								path={assetColor.assets.right_outside_cradle_primary}
							/>
						</Row>
						<hr />
						<Row>
							<Col className="fw-bold center">Earbuds</Col>
							<Col className="fw-bold center">Transition</Col>
						</Row>
						<Row>
							<ImageForm
								name="earbuds"
								path={assetColor.assets.earbuds}
							/>
							<ImageForm
								name="transition"
								path={assetColor.assets.transition}
							/>
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			);
		});

		temp_assets_array.push(
			<Accordion.Item eventKey={"NEW"}>
				<Accordion.Header>{"Add new assets"}</Accordion.Header>
				<Accordion.Body>
					<Form.Check
						defaultChecked={false}
						label={"Include"}
					/>
					<Form.Label>Color code: </Form.Label>
					<Form.Group
						as={Row}
						className="mb-3"
					>
						<Col>
							<Form.Control
								type="input"
								id="color_code"
								placeholder={"NEW"}
							/>
						</Col>
					</Form.Group>
					<Row>
						<Col className="fw-bold center">Cradle closed</Col>
						<Col className="fw-bold center">Cradle open</Col>
					</Row>
					<Row>
						<ImageForm
							name="Cradle closed"
							path={undefined}
						/>
						<ImageForm
							name="Cradle open"
							path={undefined}
						/>
					</Row>
					<hr />
					<Row>
						<Col className="fw-bold center">Left earbud front</Col>
						<Col className="fw-bold center">Right earbud front</Col>
					</Row>
					<Row>
						<ImageForm
							name="Left earbud front"
							path={undefined}
						/>

						<ImageForm
							name="Right earbud front"
							path={undefined}
						/>
					</Row>
					<hr />

					<Row>
						<Col className="fw-bold center">Left earbud back</Col>
						<Col className="fw-bold center">Right earbud back</Col>
					</Row>
					<Row>
						<ImageForm
							name="Left earbud back"
							path={undefined}
						/>
						<ImageForm
							name="Right earbud back"
							path={undefined}
						/>
					</Row>
					<hr />

					<Row>
						<Col className="fw-bold center">Left in cradle</Col>
						<Col className="fw-bold center">Right in cradle</Col>
					</Row>
					<Row>
						<ImageForm
							name="Left in cradle"
							path={undefined}
						/>
						<ImageForm
							name="Right in cradle"
							path={undefined}
						/>
					</Row>
					<hr />
					<Row>
						<Col className="fw-bold center">Left outside cradle</Col>
						<Col className="fw-bold center">Right outside cradle</Col>
					</Row>

					<Row>
						<ImageForm
							name="Left outside cradle"
							path={undefined}
						/>
						<ImageForm
							name="Right outside cradle"
							path={undefined}
						/>
					</Row>
					<hr />
					<Row>
						<Col className="fw-bold center">Left outside cradle primary</Col>
						<Col className="fw-bold center">Right outside cradle primary</Col>
					</Row>
					<Row>
						<ImageForm
							name="Left outside cradle primary"
							path={""}
						/>
						<ImageForm
							name="right_outside_cradle_primary"
							path={undefined}
						/>
					</Row>
					<hr />
					<Row>
						<Col className="fw-bold center">Earbuds</Col>
						<Col className="fw-bold center">Transition</Col>
					</Row>
					<Row>
						<ImageForm
							name="earbuds"
							path={undefined}
						/>
						<ImageForm
							name="transition"
							path={undefined}
						/>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
		);
		return <>{temp_assets_array}</>;
	}

	return (
		<Accordion>
			<PopulateAssetGroups />
		</Accordion>
	);
}
