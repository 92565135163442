import * as React from "react";
import { useState } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { generateTag, uploadToHermesCache } from "../api/cdn_apimanager";
import { confirmModels } from "../api/orderportal_apimanager";
import { CDN_response } from "../api/types/CDN_response";
import { Customer } from "../api/types/Customer";
import { ToBeModeledType } from "../api/types/ToBeModeledType";

interface Props {
	scan: ToBeModeledType;
	tenant_id: string;
	customers: Customer[];
	// priority: React.JSX.Element
	token: string;
}

export default function ToBeModeled(props: Props) {
	const [left, setLeft] = useState<CDN_response>();
	const [right, setRight] = useState<CDN_response>();
	const [uploaded, setUploaded] = useState<boolean>();
	const navigate = useNavigate();

	function handleUploadLeft(event: any) {
		upload(event.target.files[0], "left");
	}

	function handleUploadRight(event: any) {
		upload(event.target.files[0], "right");
	}

	function upload(file: any, side: string) {
		uploadToHermesCache(props.tenant_id, props.scan.customer_id, file).then((response) => {
			if (side === "left") setLeft(response);
			if (side === "right") setRight(response);
		});
	}

	function handleConfirm(event: any) {
		if (left && right) {
			const CDN_PATH = `${process.env.REACT_APP_CDN_BASEURL}/cache/hermes/${props.tenant_id}/${props.scan.customer_id}`;
			confirmModels(props.tenant_id, props.scan.customer_id, props.scan.id, `${CDN_PATH}/${left.data.document}`, `${CDN_PATH}/${right.data.document}`).then((response) => {
				if (response) setUploaded(true);
				else setUploaded(false);
			});
		}
	}
	return (
		<Form>
			<ListGroup.Item>
				<Row>
					<Col sm={3}>
						<Icon.BuildingFill /> {props.customers.find((e) => e.id === props.scan.customer_id)?.name} 
						<br />
						<Icon.TagFill />
						<> {props.scan.product_type ?? "Unknown"}</>
					</Col>
					<Col sm={2}>
						{" "}
						{left?.success === true && <h6 style={{ color: "green" }}>Successful upload</h6>}
						{left?.success === false && <h6 style={{ color: "red" }}>Upload failed</h6>}
					</Col>
					<Col sm={3}></Col>
					<Col sm={2}>
						{" "}
						{right?.success === true && <h6 style={{ color: "green" }}>Successful upload</h6>}
						{right?.success === false && <h6 style={{ color: "red" }}>Upload failed</h6>}
					</Col>
					<Col sm={2}></Col>
				</Row>
				<br />
				<Row>
					<Col sm={2}>
						<a
							title="download"
							role="button"
							className="btn btn-primary"
							download
							href={`${props.scan.left_scan}/download/?tag=${generateTag()}`}
						>
							<small>
								<Icon.Download />
								{props.scan.left_serial.slice(0, -1)}.stl
							</small>
						</a>
					</Col>
					<Col sm={3}>
						<Form.Control
							disabled={uploaded}
							type="file"
							id={`${props.scan.id}-left`}
							accept=".stl"
							onChange={handleUploadLeft}
						/>
					</Col>
					<Col sm={2}>
						<a
							title="download"
							role="button"
							className="btn btn-primary"
							download
							href={`${props.scan.right_scan}/download/?tag=${generateTag()}`}
						>
							<small>
								<Icon.Download />
								{props.scan.right_serial.slice(0, -1)}.stl
							</small>
						</a>
					</Col>
					<Col sm={3}>
						<Form.Control
							disabled={uploaded}
							type="file"
							id={`${props.scan.id}-right`}
							accept=".stl"
							onChange={handleUploadRight}
						/>
					</Col>

					<Col sm={2}>
						{left && right && !uploaded && <Button onClick={handleConfirm}>Confirm</Button>}
						{uploaded === true && <h6 style={{ color: "green" }}>Successfully confirmed</h6>}
						{uploaded === false && <h6 style={{ color: "red" }}>Confirming failed</h6>}
					</Col>
				</Row>
			</ListGroup.Item>
		</Form>
	);
}
