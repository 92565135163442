import React from "react";
import { Accordion, Col, Form, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { BC_AudioProfileType } from "../../api/types/BC_Product";
import { isNotEmpty } from "../helpers";
import { WarningLabel } from "./warninglabel";

interface Props {
	name: string;
	audioprofile: BC_AudioProfileType | undefined;
	noValue: string;
}

export default function AudioProfile(props: Props) {
	var name = props.name.replace(/ /g, "_").toLowerCase() + "_";

	return (
		<>
			<Accordion.Item eventKey={`${name}`}>
				<Accordion.Header>
					{props?.name} {props?.audioprofile ? "" : `${props.noValue}`}
				</Accordion.Header>
				<Accordion.Body>
					<Form.Group
						as={Row}
						className="mb-3 row-flex"
					>
						<Col>
							<Form.Label>Name NL</Form.Label>
							<Form.Control
								required={props?.audioprofile?.name[0].value === undefined}
								id={`${name}name_nl`}
								type="input"
								placeholder={props?.audioprofile?.name[0].value ?? props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Label>Name EN</Form.Label>
							<Form.Control
								required={props?.audioprofile?.name[1].value === undefined}
								id={`${name}name_en`}
								type="input"
								placeholder={props?.audioprofile?.name[1].value ?? props.noValue}
							></Form.Control>
						</Col>
					</Form.Group>
					<Form.Group
						as={Row}
						className="mb-3"
					>
						<Col>
							<Form.Label>
								Gain <WarningLabel />
							</Form.Label>
							<Form.Control
								required={props?.audioprofile?.gain === undefined}
								id={`${name}gain`}
								type="input"
								placeholder={isNotEmpty(props?.audioprofile?.gain) ? props?.audioprofile?.gain.toString() : props.noValue}
							></Form.Control>
						</Col>
						<Col>
							<Form.Label>Icon</Form.Label>
							<br />
							<ToggleButtonGroup
								type="radio"
								name={`${name} toggle_button_group`}
								defaultValue={props?.audioprofile?.icon ?? "n"}
							>
								<ToggleButton
									variant="outline-primary"
									id={`${name}f2`}
									value="f2"
								>
									f2
								</ToggleButton>
								<ToggleButton
									variant="outline-primary"
									id={`${name}f1`}
									value="f1"
								>
									f1
								</ToggleButton>
								<ToggleButton
									variant="outline-primary"
									id={`${name}n`}
									value="n"
								>
									n
								</ToggleButton>
								<ToggleButton
									variant="outline-primary"
									id={`${name}t1`}
									value="t1"
								>
									t1
								</ToggleButton>
								<ToggleButton
									variant="outline-primary"
									id={`${name}t2`}
									value="t2"
								>
									t2
								</ToggleButton>
							</ToggleButtonGroup>
						</Col>
					</Form.Group>

					<Form.Group
						as={Row}
						className="mb-3"
					>
						<Col>
							<Form.Label>
								Biquads <WarningLabel />{" "}
							</Form.Label>
						</Col>
						<Col>
							<Form.Text>B0</Form.Text>
						</Col>
						<Col>
							<Form.Text>B1</Form.Text>
						</Col>
						<Col>
							<Form.Text>B2</Form.Text>
						</Col>
						<Col>
							<Form.Text>A1</Form.Text>
						</Col>
						<Col>
							<Form.Text>A2</Form.Text>
						</Col>
						<Row>
							<Col>
								<Form.Text>0 </Form.Text>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[0][0] === undefined}
									id={`${name}biquad_0_0`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[0][0]) ? props.audioprofile?.biquads[0][0].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[0][1] === undefined}
									id={`${name}biquad_0_1`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[0][1]) ? props?.audioprofile?.biquads[0][1].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[0][2] === undefined}
									id={`${name}biquad_0_2`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[0][2]) ? props?.audioprofile?.biquads[0][2].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[0][3] === undefined}
									id={`${name}biquad_0_3`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[0][3]) ? props?.audioprofile?.biquads[0][3].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[0][4] === undefined}
									id={`${name}biquad_0_4`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[0][4]) ? props?.audioprofile?.biquads[0][4].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Text>1 </Form.Text>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[1][0] === undefined}
									id={`${name}biquad_1_0`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[1][0]) ? props?.audioprofile?.biquads[1][0].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[1][1] === undefined}
									id={`${name}biquad_1_1`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[1][1]) ? props?.audioprofile?.biquads[1][1].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[1][2] === undefined}
									id={`${name}biquad_1_2`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[1][2]) ? props?.audioprofile?.biquads[1][2].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[1][3] === undefined}
									id={`${name}biquad_1_3`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[1][3]) ? props?.audioprofile?.biquads[1][3].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[1][4] === undefined}
									id={`${name}biquad_1_4`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[1][4]) ? props?.audioprofile?.biquads[1][4].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Text>2</Form.Text>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[2][0] === undefined}
									id={`${name}biquad_2_0`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[2][0]) ? props?.audioprofile?.biquads[2][0].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[2][1] === undefined}
									id={`${name}biquad_2_1`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[2][1]) ? props?.audioprofile?.biquads[2][1].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[2][2] === undefined}
									id={`${name}biquad_2_2`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[2][2]) ? props?.audioprofile?.biquads[2][2].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[2][3] === undefined}
									id={`${name}biquad_2_3`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[2][3]) ? props?.audioprofile?.biquads[2][3].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[2][4] === undefined}
									id={`${name}biquad_2_4`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[2][4]) ? props?.audioprofile?.biquads[2][4].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Text>3</Form.Text>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[3][0] === undefined}
									id={`${name}biquad_3_0`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[3][0]) ? props?.audioprofile?.biquads[3][0].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[3][1] === undefined}
									id={`${name}biquad_3_1`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[3][1]) ? props?.audioprofile?.biquads[3][1].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[3][2] === undefined}
									id={`${name}biquad_3_2`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[3][2]) ? props?.audioprofile?.biquads[3][2].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[3][3] === undefined}
									id={`${name}biquad_3_3`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[3][3]) ? props?.audioprofile?.biquads[3][3].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[3][4] === undefined}
									id={`${name}biquad_3_4`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[3][4]) ? props?.audioprofile?.biquads[3][4].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Text>4</Form.Text>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[4][0] === undefined}
									id={`${name}biquad_4_0`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[4][0]) ? props?.audioprofile?.biquads[4][0].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[4][1] === undefined}
									id={`${name}biquad_4_1`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[4][1]) ? props?.audioprofile?.biquads[4][1].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[4][2] === undefined}
									id={`${name}biquad_4_2`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[4][2]) ? props?.audioprofile?.biquads[4][2].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[4][3] === undefined}
									id={`${name}biquad_4_3`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[4][3]) ? props?.audioprofile?.biquads[4][3].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[4][4] === undefined}
									id={`${name}biquad_4_4`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[4][4]) ? props?.audioprofile?.biquads[4][4].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Text>5</Form.Text>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[5][0] === undefined}
									id={`${name}biquad_5_0`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[5][0]) ? props?.audioprofile?.biquads[5][0].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[5][1] === undefined}
									id={`${name}biquad_5_1`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[5][1]) ? props?.audioprofile?.biquads[5][1].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[5][2] === undefined}
									id={`${name}biquad_5_2`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[5][2]) ? props?.audioprofile?.biquads[5][2].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[5][3] === undefined}
									id={`${name}biquad_5_3`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[5][3]) ? props?.audioprofile?.biquads[5][3].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									required={props?.audioprofile?.biquads[5][4] === undefined}
									id={`${name}biquad_5_4`}
									type="input"
									placeholder={isNotEmpty(props?.audioprofile?.biquads[5][4]) ? props?.audioprofile?.biquads[5][4].toString(16).toUpperCase().padStart(6, "0") : props.noValue}
								></Form.Control>
							</Col>
						</Row>
					</Form.Group>
				</Accordion.Body>
			</Accordion.Item>
		</>
	);
}
