import { toast } from "sonner";
import toastManager from "../modules/toastmanager";

export default function ErrorHandler(error: any) {
	console.log(error);
    const default_error_duration = 5000;

	if (error?.response?.data?.message === "Consumers on this order are missing modeling data.") {
		var temp: string[] = [];
		error.response.data.info.forEach((missingdata: any) => {
			temp.push(` ${missingdata.consumer}`);
		});
		toastManager("error", error.response.data.message, `The following consumer(s) are missing moddeling data: ${temp}`, default_error_duration , error.response.status);
		return;
	}
	if (error?.response?.data?.message) {
		toastManager("error", error.response.data.message, error.message, default_error_duration, error.response.status);
	}
	else if (error.response && error.response.status === 401) {
		toastManager("error", error.response.data.message, `Session expired, log in and try again`, default_error_duration, error.response.status);
	}
	else if (error.message) {
		toastManager("error", error.message, "Please contact the administrator if this problem is persistent");
	}
}
