import axios, { AxiosInstance } from "axios";
import saveAs from "file-saver";
import JSZip from "jszip";
import toastManager from "../modules/toastmanager";
import { generateTag } from "./cdn_apimanager";
import ErrorHandler from "./errorhandler";
import { AppointmentResult } from "./types/AppointmentType";
import { Consumer } from "./types/Consumer";
import { Customer } from "./types/Customer";
import { Department } from "./types/Department";
import { Question } from "./types/Impression";
import { Order } from "./types/Order";
import { PrintBatchDownloadType } from "./types/PrintBatchDownloadType";
import { ProductOrder } from "./types/ProductOrder";
import { Tenant } from "./types/Tenant";

var client: AxiosInstance;

export function createOPClient(token: string) {
	if (!client)
		client = axios.create({
			baseURL: process.env.REACT_APP_ORDERPORTAL_BASEURL!,
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	else return;
}

export async function getArticles(token: string) {
	if (!client) {
		toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/articles`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//! This endpoint is unauthenticated
export async function getOrderStatus(token: string, orderid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/${orderid}/status`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//#region Products

export async function getProduct(token: string, customerid: string, consumerid: string, productid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/consumers/${consumerid}/products/${productid}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getConsumerProducts(token: string, customerid: string, consumerid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/consumers/${consumerid}/products`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getRemovedConsumerProducts(token: string, customerid: string, consumerid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/consumers/${consumerid}/deleted_products`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//#endregion

//#region Tenant(s)

export async function getTenant(token: string, tenantid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/tenants/${tenantid}`);
		return data as Tenant;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getTenants(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/tenants`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//#endregion

//#region Customer(s)

export async function getCustomer(token: string, customerid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}`);
		return data as Customer;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
export async function addCustomer(tenantid: string, name: string, description: string, email: string, address1: string, address2: string, logourl: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/customers`, {
			name: name,
			description: description,
			contact_email: email,
			address_line_1: address1,
			address_line_2: address2,
			logo_url: logourl,
			tenant_id: tenantid,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
export async function updateCustomer(customerid: string, name: string, description: string, email: string, address1: string, address2: string, logourl: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.put(`/customers/${customerid}`, {
			name: name,
			description: description,
			contact_email: email,
			address_line_1: address1,
			address_line_2: address2,
			logo_url: logourl,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getCustomers(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//#endregion

//#region Department(s)

export async function getDepartment(token: string, customerid: string, departmentid: string) {
	if (!client) {
		toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/departments/${departmentid}`);
		return data as Department;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function addDepartment(customerid: string, name: string, description: string, dustlevel: string, gaslevel: string, average: string, peak: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/customers/${customerid}/departments/`, {
			name: name,
			description: description,
			dust_level: dustlevel,
			gas_level: gaslevel,
			average_noise_exposure: average,
			peak_noise_exposure: peak,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function updateDepartment(customerid: string, name: string, description: string, dustlevel: string, gaslevel: string, average: string, peak: string, departmentid: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.put(`/customers/${customerid}/departments/${departmentid}`, {
			name: name,
			description: description,
			dust_level: dustlevel,
			gas_level: gaslevel,
			average_noise_exposure: average,
			peak_noise_exposure: peak,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
export async function deleteDepartment(customerid: string, departmentid: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.delete(`/customers/${customerid}/departments/${departmentid}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getDepartments(token: string, customerid: string) {
	if (!client) {
		toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/departments`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//#endregion

//#region Impressions/Revisions/Scans/Models

export async function getImpressions(token: string, customerid: string, consumerid: string) {
	if (!client) {
		toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/consumers/${consumerid}/impressions`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
export async function removeImpression(token: string, customerid: string, consumerid: string, impressionid: string) {
	if (!client) {
		toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");
		createOPClient(token);
	}
	try {
		const { data } = await client.delete(`/customers/${customerid}/consumers/${consumerid}/impressions/${impressionid}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getRevisions(token: string, customerid: string, consumerid: string, impressionid: string) {
	if (!client) {
		toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/consumers/${consumerid}/impressions/${impressionid}/revisions`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function addImpressionBagCode(customerid: string, consumerid: string, bag_code_left: string, bag_code_right: string, method: string, questions: Question[]) {
	const left_scan_data: string = "";
	const right_scan_data: string = "";
	try {
		const { data } = await client.post(`/customers/${customerid}/consumers/${consumerid}/impressions`, {
			method: method,
			bag_code_left: bag_code_left,
			bag_code_right: bag_code_right,
			questions: questions,
			left_scan_data: left_scan_data,
			right_scan_data: right_scan_data,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
export async function updateImpressionBagCode(customerid: string, consumerid: string, bag_code_left: string, bag_code_right: string, method: string, questions: Question[], impressionid: string) {
	const left_scan_data: string = "";
	const right_scan_data: string = "";
	try {
		const { data } = await client.put(`/customers/${customerid}/consumers/${consumerid}/impressions/${impressionid}`, {
			method: method,
			bag_code_left: bag_code_left,
			bag_code_right: bag_code_right,
			questions: questions,
			left_scan_data: left_scan_data,
			right_scan_data: right_scan_data,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function addImpressionBagCode_UltimateEars(customerid: string, consumerid: string, left_scan_data: string, right_scan_data: string) {
	try {
		const { data } = await client.post(`/customers/${customerid}/consumers/${consumerid}/impressions`, {
			method: "UE_FitKit",
			bag_code_left: "UE_LEFT",
			bag_code_right: "UE_RIGHT",
			questions: [],
			left_scan_data: left_scan_data,
			right_scan_data: right_scan_data,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getImpressionAndRevisionID(token: string, customerid: string, consumerid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/consumers/${consumerid}/latest_imp_and_rev`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getToBeScanned(token: string, tenantid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/tenants/${tenantid}/scan_required`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getToBeModeled(token: string, tenantid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/tenants/${tenantid}/modeling_required`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function confirmScans(tenantid: string, customerid: string, id: string, left_scan_data: string, right_scan_data: string) {
	try {
		const { data } = await client.put(`/tenants/${tenantid}/customers/${customerid}/scan_required/${id}`, {
			left_scan_data: left_scan_data,
			right_scan_data: right_scan_data,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function confirmModels(tenantid: string, customerid: string, id: string, left_modeled_data: string, right_modeled_data: string) {
	try {
		const { data } = await client.put(`/tenants/${tenantid}/customers/${customerid}/modeling_required/${id}`, {
			left_modeled_data: left_modeled_data,
			right_modeled_data: right_modeled_data,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
//#endregion

//#region Order(s)

export async function getOrder(token: string, orderid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/${orderid}`);
		return data as Order;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function addOrder(customerId: string, tenantId: string, referenceId: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/orders/`, {
			customer_id: customerId,
			referenceId: referenceId,
			tenant_id: tenantId,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function confirmOrder(orderId: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.put(`/orders/${orderId}`, {
			confirmed: true,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function lockOrder(orderId: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.put(`/orders/${orderId}`, {
			locked: true,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return error;
	}
}

export async function removeOrder(orderId: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.delete(`/orders/${orderId}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
	}
}

export async function getOrders(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getAdminOrders(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/confirm`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//#endregion

//#region Consumer(s)

export async function getConsumer(token: string, customerid: string, consumerid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/consumers/${consumerid}`);
		return data as Consumer;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function addConsumer(customerId: string, first_name: string, family_name: string, short_name: string, department: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/customers/${customerId}/consumers`, {
			short_name: short_name,
			department: department,
			family_name: family_name,
			given_name: first_name,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function removeConsumer(customerId: string, consumerId: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.delete(`/customers/${customerId}/consumers/${consumerId}`, {});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function updateConsumer(customerId: string, consumerId: string, first_name: string, family_name: string, short_name: string, department?: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.put(`/customers/${customerId}/consumers/${consumerId}`, {
			short_name: short_name,
			department: department,
			family_name: family_name,
			given_name: first_name,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getConsumers(token: string, customerid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/customers/${customerid}/consumers`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//#endregion

//#region Print jobs/batches
export async function addPrintJob(order_id: string, production_id: string, earshell_revision: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.put(`/orders/${order_id}/production/${production_id}`, {
			print_job: {
				earshell_revision: earshell_revision,
			},
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getPrintJobs(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/printjobs`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getPrintBatches(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/printbatch/`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
export async function getPrintBatchDownload(token: string, printbatch_id: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/printbatch/${printbatch_id}/download`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function downloadPrintBatchAsZIP(printbatch: PrintBatchDownloadType) {
	const zip = new JSZip();
	const tag = generateTag();
	var failed = false;
	var failedCount = 0;
	var readme = `Batch name: ${printbatch.batch_name}\n\nFields in this file\n{COMPANY} \n\t- {CONSUMER} \n\tPID={PRINTJOB_ID} \n\t\t L={LEFT_EARSHELL} \n\t\t R={RIGHT_EARSHELL} \n\n`;

	const remoteZips = printbatch.print_jobs.map(async (printjob) => {
		const responseLeft = await fetch(`${printjob.left_stl_url}/?tag=${tag}`);
		const dataLeft = await responseLeft.blob();

		const responseRight = await fetch(`${printjob.right_stl_url}/?tag=${tag}`);
		const dataRight = await responseRight.blob();

		if (responseLeft.status === 500 || responseRight.status === 500) {
			failed = true;
			failedCount++;
			return undefined;
		}

		readme += `${printjob.tenant_name} \n\t - ${printjob.consumer_name} \n\tPID=${printjob.print_job} \n\t\t L= ${printjob.left_stl_name} \n\t\t R= ${printjob.right_stl_name} \n`;

		const folder = zip.folder(`${printjob.left_stl_name.split("_")[0]} - ${printjob.consumer_name}`);
		if (folder) folder.file(`${printjob.left_stl_name}`, dataLeft);
		if (folder) folder.file(`${printjob.right_stl_name}`, dataRight);
		return [dataLeft, dataRight];
	});

	Promise.all(remoteZips)
		.then(() => {
			if (!failed) {
				zip.file("Info.txt", readme);
				zip.generateAsync({ type: "blob" }).then((content) => {
					// give the zip file a name
					saveAs(content, printbatch.batch_name);
					toastManager("success", "Downloaded ZIP", `Saved ${printbatch.batch_name} with ${remoteZips.length} items to your filesystem `);
				});
			} else toastManager("error", "Downloading files failed", `Downloading of ${failedCount} file(s) has failed, please contact an administrator to try and fix this problem`);
		})
		.catch((error) => {
			ErrorHandler(error);
		});
}

export async function createPrintBatch(batch_name: string, print_jobs: string[]) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/orders/printbatch/`, {
			batch_name: batch_name,
			printjob_references: print_jobs,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
//#endregion

//#region Shipping
export async function getShipments(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/shipping/`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function createShipment(track_and_trace: string, orders: string[]) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/orders/shipping/`, {
			trackingData: track_and_trace,
			orders: orders,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function printPakbon(shippingid: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.put(`/orders/shipping/${shippingid}`, {
			pakbon_printed: true,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
//#endregion

//#region Production onrder(s)

export async function getProductionOrder(token: string, orderId: string, productionId: string) {
	if (!client) {
		toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/${orderId}/production/${productionId}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function addProductionOrder(order_id: string, order: ProductOrder) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/orders/${order_id}/production`, {
			consumer_id: order.consumer,
			exact_article_id: order.product,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function removeProductionOrder(order_id: string, production_id: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.delete(`/orders/${order_id}/production/${production_id}`, {});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getProductionOrders(token: string, orderId: string) {
	if (!client) {
		toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/orders/${orderId}/production`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
//#endregion

//#region Appointments/Results

export async function getAppointment(token: string, tenantid: string, appointmentid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/tenants/${tenantid}/appointments/${appointmentid}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function addAppointment(tenantid: string, consumers: string[], planned: number, type: "handoff" | "checkup") {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/tenants/${tenantid}/appointments/`, {
			consumers: consumers,
			planned: planned,
			type: type,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getAppointments(token: string, tenantid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/tenants/${tenantid}/appointments/`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function getAppointmentResult(token: string, tenantid: string, appointmentid: string, resultid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/tenants/${tenantid}/appointments/${appointmentid}/results/${resultid}`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

export async function addAppointmentResult(tenantid: string, appointmentid: string, result: AppointmentResult) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.post(`/tenants/${tenantid}/appointments/${appointmentid}/results`, {
			...result,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
export async function updateAppointmentResult(tenantid: string, appointmentid: string, result: AppointmentResult, resultID: string) {
	if (!client) toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");

	try {
		const { data } = await client.put(`/tenants/${tenantid}/appointments/${appointmentid}/results/${resultID}`, {
			consumer: result.consumer,
			fit_seal_remarks: result.fit_seal_remarks,
			left_fit: result.left_fit,
			left_seal: result.left_seal,
			rework_data: result.rework_data,
			right_fit: result.right_fit,
			right_seal: result.right_seal,
		});
		return data;
	} catch (error) {
		console.log("error", error);
		ErrorHandler(error);
		return undefined;
	}
}

export async function getAppointmentResults(token: string, tenantid: string, appointmentid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/tenants/${tenantid}/appointments/${appointmentid}/results`);
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}
export async function addAppointmentCompleted(token: string, tenantid: string, appointmentid: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.put(`/tenants/${tenantid}/appointments/${appointmentid}`, {
			completed: true,
		});
		return data;
	} catch (error) {
		ErrorHandler(error);
		return undefined;
	}
}

//#endregion

//#region Search

export async function searchSerialNumber(token: string, serial_number: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/search/products/${serial_number}`);
		return data;
	} catch (error) {
		return undefined;
	}
}
export async function searchImpressionID(token: string, impression_id: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/search/impressions/${impression_id}`);
		return data;
	} catch (error) {
		return undefined;
	}
}

export async function searchProductionNumber(token: string, production_number: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/search/productionorders/${production_number}`);
		return data;
	} catch (error) {
		return undefined;
	}
}
export async function searchConsumer(token: string, consumer_name: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/search/consumers?name=${consumer_name}`);
		return data;
	} catch (error) {
		return undefined;
	}
}

//#endregion

//#region Log

export async function getLogs(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/logs`);
		return "This does nothing yet";
	} catch (error) {
		return undefined;
	}
}

//#endregion

//#region Management

export async function getAdminUsers(token: string) {
	if (!client) {
		createOPClient(token);
	}
	try {
		const { data } = await client.get(`/admin/users`);
		return data;
	} catch (error) {
		return undefined;
	}
}

//#endregion
