import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { convertColorToRGBA, getBrandConfig, getBrandTree, postBrandConfig, updateBrandConfig } from "../api/brandconfig_apimanager_v2";
import { createCDNClient } from "../api/cdn_apimanager";
import { getCustomers, getDepartments, getTenants } from "../api/orderportal_apimanager";
import { BC_Brand } from "../api/types/BC_Brand";
import { BC_Tree } from "../api/types/BC_Tree";
import { Customer } from "../api/types/Customer";
import { Department } from "../api/types/Department";
import { ListItem } from "../api/types/ListItem";
import { Tenant } from "../api/types/Tenant";
import BackButton from "../modules/backbutton";
import BrandColors from "../modules/brandconfig/brandcolors";
import ImageForm from "../modules/brandconfig/imageform";
import { getChangedValueOrPlaceholder } from "../modules/helpers";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import toastManager from "../modules/toastmanager";

export default function BrandConfig() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);
	const queryParameters = new URLSearchParams(window.location.search);

	const tenantID = localStorage.getItem("tenant");

	const [tenants, setTenants] = useState<Tenant[]>();
	const [tenantsList, setTenantsList] = useState<ListItem[]>();
	const [selectedTenant, setSelectedTenant] = useState<ListItem | undefined | null>();

	const [reloadForms, setReloadForms] = useState<boolean>(false);

	const [customers, setCustomers] = useState<Customer[]>();
	const [customersList, setCustomersList] = useState<ListItem[]>();
	const [selectedCustomer, setSelectedCustomer] = useState<ListItem | undefined | null>();

	const [departments, setDepartments] = useState<Department[]>();
	const [departmentsList, setDepartmentsList] = useState<ListItem[]>();
	const [selectedDepartment, setSelectedDepartment] = useState<ListItem | null | undefined>();

	const noValue = "—";

	const [brandConfig, setBrandConfig] = useState<BC_Brand>();
	const [configPath, setConfigPath] = useState<string | null>(queryParameters.get("path"));
	const [brandTree, setBrandTree] = useState<BC_Tree>();
	const [accordionActiveKey, setAccordionActiveKey] = useState<any>("config");

	enum VALIDATIONGROUP {
		"None",
		"BrandName",
		"BackgroundImage",
		"BrandColors",
		"LogoURL",
	}

	const [currentValidationGroup, setCurrentValidationGroup] = useState<VALIDATIONGROUP>(VALIDATIONGROUP.None);

	const [configName, setConfigName] = useState<string>(brandConfig?.config_name ?? "");

	createCDNClient(token);

	const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);

	const [editConfiguration, setEditConfiguration] = useState<boolean>(false);

	const [copyPasteConfig, setCopyPasteConfig] = useState<boolean>(false);
	const [copyPaste, setCopyPaste] = useState<"copy" | "paste">();
	const [copiedData, setCopiedData] = useState<BC_Brand>();

	const handleEditConfiguration = () => {
		if (configPath) setEditConfiguration(true);
		else toastManager("warning", "No path selected", "Please select a path or create a new configuration");
	};

	function checkForValidationErrors(inputs: any[]) {
		var invalidInputs: string[] = [];

		inputs.forEach((input) => {
			if ((!input.value && input.placeholder === noValue) || input.value === noValue) invalidInputs.push(input.id);
		});

		if (invalidInputs.length === 0) {
			setCurrentValidationGroup(VALIDATIONGROUP.None);
			return false;
		} else {
			var issues = "";
			invalidInputs.forEach((input) => {
				issues += `${input}, `;
			});
			toastManager("error", "Validation error", `Not all required fields are filled in. The following fields are empty: ${issues}`);
			return true;
		}
	}

	function PopulateTreeButtons() {
		var temp: JSX.Element[] = [];

		if (brandTree?.name) {
			var mainChanges = "";
			if (brandTree.types?.length) {
				mainChanges = brandTree.types.join(", ");
			}
			temp.push(
				<ListGroup.Item
					key={`${brandTree.name}`}
					action
					onClick={() => handleSelectedConfig(`/`)}
					className="brandconfig-link"
					variant={configPath === `/` ? "success" : ""}
				>
					<b>{brandTree.name} </b>
					<small>{mainChanges !== "" ? mainChanges : "no changes"}</small>
					<br />
				</ListGroup.Item>
			);

			if (brandTree.children) {
				Object.entries(brandTree.children).forEach((tenant) => {
					var tenantChanges = "";
					if (tenant[1].types?.length) {
						tenantChanges = tenant[1].types.join(", ");
					}
					temp.push(
						<ListGroup.Item
							key={`${brandTree.name}${tenant[0]}`}
							action
							onClick={() => handleSelectedConfig(`/tenant/${tenant[0]}`)}
							className="brandconfig-link"
							variant={configPath === `/tenant/${tenant[0]}` ? "success" : ""}
						>
							<b>
								{brandTree.name} / {tenant[1].name}{" "}
							</b>
							<small>
								{tenant[1].name} <br />
								{tenantChanges !== "" ? tenantChanges : "no changes"}
							</small>
							<br />
						</ListGroup.Item>
					);
					if (tenant[1].children) {
						Object.entries(tenant[1].children).forEach((customer) => {
							var customerChanges = "";
							if (customer[1].types?.length) {
								customerChanges = customer[1].types.join(", ");
							}

							temp.push(
								<ListGroup.Item
									key={`${brandTree.name}${tenant[0]}${customer[0]}`}
									action
									onClick={() => handleSelectedConfig(`/tenant/${tenant[0]}/customer/${customer[0]}`)}
									className="brandconfig-link"
									variant={configPath === `/tenant/${tenant[0]}/customer/${customer[0]}` ? "success" : ""}
								>
									<b>
										{" "}
										{brandTree.name} / {tenant[1].name} / {customer[1].name}{" "}
									</b>
									<small>
										{customer[1].name} <br />
										{customerChanges !== "" ? customerChanges : "no changes"}
									</small>
									<br />
								</ListGroup.Item>
							);

							if (customer[1].children) {
								Object.entries(customer[1].children).forEach((department) => {
									var departmentChanges = "";
									if (department[1].types?.length) {
										departmentChanges = department[1].types.join(", ");
									}

									temp.push(
										<ListGroup.Item
											key={`${brandTree.name}${tenant[0]}${customer[0]}${department[0]}`}
											action
											onClick={() => handleSelectedConfig(`/tenant/${tenant[0]}/customer/${customer[0]}/department/${department[0]}`)}
											className="brandconfig-link"
											variant={configPath === `/tenant/${tenant[0]}/customer/${customer[0]}/department/${department[0]}` ? "success" : ""}
										>
											<>
												<b>
													{" "}
													{brandTree.name} / {tenant[1].name} / {customer[1].name} / {department[1].name}{" "}
												</b>
											</>
											<small>
												{department[1].name} <br />
												{departmentChanges !== "" ? departmentChanges : "no changes"}
											</small>
											<br />
										</ListGroup.Item>
									);
								});
							}
						});
					}
				});
			}
		}
		return <ListGroup>{temp}</ListGroup>;
	}
	function getTenantFromPath(path: string) {
		return path.substring(8, 32);
	}

	function reloadConfig() {
		if (configPath) {
			var tenant = getTenantFromPath(configPath);

			getBrandConfig(token, tenant, configPath).then((response) => {
				if (response) setBrandConfig(response);
			});
		}
	}

	function handleSelectedConfig(path: string) {
		setAccordionActiveKey("");
		setConfigPath(path);
		queryParameters.set("path", path ?? "");
		handleReloadForms();
		var tenant = getTenantFromPath(path);

		setSelectedTenant(tenantsList?.find((e) => e.value === tenant));

		getBrandConfig(token, tenant, path).then((response) => {
			if (response) setBrandConfig(response);
			window.scrollTo({ top: 0, behavior: "auto" });
		});
	}

	function handleReloadForms() {
		setReloadForms(true);
		setTimeout(() => setReloadForms(false), 200);
	}

	function saveBrandName(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.BrandName);

		if (!checkForValidationErrors([etf.brand_name]) && selectedTenant) {
			updateBrandConfig(configPath!, selectedTenant?.value, {
				brand_name: getChangedValueOrPlaceholder(etf.brand_name),
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Brand Name", `Successfully updated 'Brand Name' for config with the name: '${response.config_name}'`);
					reloadConfig();
				}
			});
		}
	}

	function pasteBrandName() {
		if (selectedTenant) {
			updateBrandConfig(configPath!, selectedTenant?.value, {
				brand_name: copiedData?.data.brand_name,
			}).then((response) => {
				if (response) {
					toastManager("success", "Pasted Brand Name", `Successfully pasted 'Brand Name' to config with the name: '${response.config_name}'`);
				}
				reloadConfig()
			});
		}
	}

	function saveLogoURL(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.LogoURL);

		if (!etf.logo_url.name) {
			toastManager("error", "No image uploaded", "No image was uploaded, please try again");
		}
		if (selectedTenant?.value && configPath) {
			updateBrandConfig(configPath, selectedTenant?.value, {
				logo_url: `${etf.logo_url.name}`,
			})
				.then((response) => {
					if (response) {
						toastManager("success", "Updated Logo url", `Successfully updated 'Logo url' for config with the name: '${response.config_name}'`);
					}
					reloadConfig()
				})
		}
	}
	function pasteLogoURL() {
		if (selectedTenant?.value && configPath) {
			updateBrandConfig(configPath, selectedTenant?.value, {
				logo_url: copiedData?.data.logo_url,
			})
				.then((response) => {
					if (response) {
						toastManager("success", "Pasted Logo url", `Successfully pasted 'Logo url' to config with the name: '${response.config_name}'`);
					}
					reloadConfig();
				})
		}
	}

	function saveBackgroundImage(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.BackgroundImage);
		if (!etf?.background_image?.name) {
			toastManager("error", "Something went wrong", "Something went wrong while trying to confirm the image");
			return;
		}

		if (selectedTenant?.value && configPath) {
			updateBrandConfig(configPath, selectedTenant.value, {
				background_image: {
					dark: `${etf.background_image.name}`,
					light: `${etf.background_image.name}`,
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Background Image", `Successfully updated 'Background Image' for config with the name: '${response.config_name}'`);
					reloadConfig();
				}
			});
		}
	}

	function pasteBackgroundImage() {
		if (selectedTenant?.value && configPath) {
			updateBrandConfig(configPath, selectedTenant.value, {
				background_image: {
					dark: copiedData?.data.background_image.dark,
					light: copiedData?.data.background_image.light,
				},
			})
				.then((response) => {
					if (response) {
						toastManager("success", "Updated Background Image", `Successfully updated 'Background Image' for config with the name: '${response.config_name}'`);
					}
					reloadConfig()
				})
		}
	}

	function saveBrandColors(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.BrandColors);
		// CheckValidationErrors currently doesn't do much here since the default color is #FFFFFF and this is 'valid' (added this for the future)
		if (
			!checkForValidationErrors([
				etf.primary_background.value,
				etf.primary_background_alpha.value,
				etf.primary_background_disabled.value,
				etf.primary_background_disabled_alpha.value,
				etf.primary_text.value,
				etf.primary_text_alpha.value,
				etf.primary_text_disabled.value,
				etf.primary_text_disabled_alpha.value,
				etf.secondary_background.value,
				etf.secondary_background_alpha.value,
				etf.secondary_text.value,
				etf.secondary_text_alpha.value,
				etf.secondary_text_disabled.value,
				etf.secondary_text_disabled_alpha.value,
				etf.control_active.value,
				etf.control_active_alpha.value,
				etf.control_inactive.value,
				etf.control_inactive_alpha.value,
				etf.primary.value,
				etf.primary_alpha.value,
				etf.secondary.value,
				etf.secondary_alpha.value,
				etf.background_gradient_bottom.value,
				etf.background_gradient_bottom_alpha.value,
				etf.background_gradient_top.value,
				etf.background_gradient_top_alpha.value,
				etf.control_background.value,
				etf.control_background_alpha.value,
				etf.control_foreground_active.value,
				etf.control_foreground_active_alpha.value,
				etf.control_foreground_inactive.value,
				etf.control_foreground_inactive_alpha.value,
				etf.divider.value,
				etf.divider_alpha.value,
				etf.foreground_layer.value,
				etf.foreground_layer_alpha.value,
				etf.status_disabled.value,
				etf.status_disabled_alpha.value,
				etf.status_neutral.value,
				etf.status_neutral_alpha.value,
				etf.status_positive.value,
				etf.status_positive_alpha.value,
			]) &&
			selectedTenant
		) {
			updateBrandConfig(configPath!, selectedTenant?.value, {
				colors: {
					dark: {
						button: {
							primary_background: convertColorToRGBA(etf.primary_background.value, etf.primary_background_alpha.value),
							primary_background_disabled: convertColorToRGBA(etf.primary_background_disabled.value, etf.primary_background_disabled_alpha.value),
							primary_text: convertColorToRGBA(etf.primary_text.value, etf.primary_text_alpha.value),
							primary_text_disabled: convertColorToRGBA(etf.primary_text_disabled.value, etf.primary_text_disabled_alpha.value),
							secondary_background: convertColorToRGBA(etf.secondary_background.value, etf.secondary_background_alpha.value),
							secondary_background_disabled: convertColorToRGBA(etf.secondary_background_disabled.value, etf.secondary_background_disabled_alpha.value),
							secondary_text: convertColorToRGBA(etf.secondary_text.value, etf.secondary_text_alpha.value),
							secondary_text_disabled: convertColorToRGBA(etf.secondary_text_disabled.value, etf.secondary_text_disabled_alpha.value),
						},
						text: {
							control_active: convertColorToRGBA(etf.control_active.value, etf.control_active_alpha.value),
							control_inactive: convertColorToRGBA(etf.control_inactive.value, etf.control_inactive_alpha.value),
							primary: convertColorToRGBA(etf.primary.value, etf.primary_alpha.value),
							secondary: convertColorToRGBA(etf.secondary.value, etf.secondary_alpha.value),
						},
						ui: {
							background_gradient_bottom: convertColorToRGBA(etf.background_gradient_bottom.value, etf.background_gradient_bottom_alpha.value),
							background_gradient_top: convertColorToRGBA(etf.background_gradient_top.value, etf.background_gradient_top_alpha.value),
							control_background: convertColorToRGBA(etf.control_background.value, etf.control_background_alpha.value),
							control_foreground_active: convertColorToRGBA(etf.control_foreground_active.value, etf.control_foreground_active_alpha.value),
							control_foreground_inactive: convertColorToRGBA(etf.control_foreground_inactive.value, etf.control_foreground_inactive_alpha.value),
							divider: convertColorToRGBA(etf.divider.value, etf.divider_alpha.value),
							foreground_layer: convertColorToRGBA(etf.foreground_layer.value, etf.foreground_layer_alpha.value),
							status_disabled: convertColorToRGBA(etf.status_disabled.value, etf.status_disabled_alpha.value),
							status_neutral: convertColorToRGBA(etf.status_neutral.value, etf.status_neutral_alpha.value),
							status_positive: convertColorToRGBA(etf.status_positive.value, etf.status_positive_alpha.value),
						},
					},
					light: {
						button: {
							primary_background: convertColorToRGBA(etf.primary_background.value, etf.primary_background_alpha.value),
							primary_background_disabled: convertColorToRGBA(etf.primary_background_disabled.value, etf.primary_background_disabled_alpha.value),
							primary_text: convertColorToRGBA(etf.primary_text.value, etf.primary_text_alpha.value),
							primary_text_disabled: convertColorToRGBA(etf.primary_text_disabled.value, etf.primary_text_disabled_alpha.value),
							secondary_background: convertColorToRGBA(etf.secondary_background.value, etf.secondary_background_alpha.value),
							secondary_background_disabled: convertColorToRGBA(etf.secondary_background_disabled.value, etf.secondary_background_disabled_alpha.value),
							secondary_text: convertColorToRGBA(etf.secondary_text.value, etf.secondary_text_alpha.value),
							secondary_text_disabled: convertColorToRGBA(etf.secondary_text_disabled.value, etf.secondary_text_disabled_alpha.value),
						},
						text: {
							control_active: convertColorToRGBA(etf.control_active.value, etf.control_active_alpha.value),
							control_inactive: convertColorToRGBA(etf.control_inactive.value, etf.control_inactive_alpha.value),
							primary: convertColorToRGBA(etf.primary.value, etf.primary_alpha.value),
							secondary: convertColorToRGBA(etf.secondary.value, etf.secondary_alpha.value),
						},
						ui: {
							background_gradient_bottom: convertColorToRGBA(etf.background_gradient_bottom.value, etf.background_gradient_bottom_alpha.value),
							background_gradient_top: convertColorToRGBA(etf.background_gradient_top.value, etf.background_gradient_top_alpha.value),
							control_background: convertColorToRGBA(etf.control_background.value, etf.control_background_alpha.value),
							control_foreground_active: convertColorToRGBA(etf.control_foreground_active.value, etf.control_foreground_active_alpha.value),
							control_foreground_inactive: convertColorToRGBA(etf.control_foreground_inactive.value, etf.control_foreground_inactive_alpha.value),
							divider: convertColorToRGBA(etf.divider.value, etf.divider_alpha.value),
							foreground_layer: convertColorToRGBA(etf.foreground_layer.value, etf.foreground_layer_alpha.value),
							status_disabled: convertColorToRGBA(etf.status_disabled.value, etf.status_disabled_alpha.value),
							status_neutral: convertColorToRGBA(etf.status_neutral.value, etf.status_neutral_alpha.value),
							status_positive: convertColorToRGBA(etf.status_positive.value, etf.status_positive_alpha.value),
						},
					},
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Brand Colors", `Successfully updated 'Brand Colors' for config with the name: '${response.config_name}'`);
				}
			});
		}
	}

	function pasteBrandColors() {
		if (selectedTenant) {
			updateBrandConfig(configPath!, selectedTenant?.value, {
				colors: copiedData?.data.colors,
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Brand Colors", `Successfully updated 'Brand Colors' for config with the name: '${response.config_name}'`);
				}
				reloadConfig()
			});
		}
	}

	useEffect(function getConfigFromURLOnPageLoad() {
		if (configPath && !brandConfig) {
			handleSelectedConfig(configPath);
		}
	}, []);

	useEffect(
		function getDepartmentsFromAPI() {
			if (selectedCustomer && token && token.length) {
				getDepartments(token, selectedCustomer.value).then((response) => {
					if (response) {
						setDepartments(response);
						var temp: ListItem[] = [];
						response.forEach((dep: Department) => {
							temp.push({ label: dep.name, value: dep.id });
						});
						setDepartmentsList(temp);
					}
				});
			}
		},
		[selectedCustomer, token]
	);

	useEffect(
		function getCustomersFromAPI() {
			if (token && token.length)
				getCustomers(token).then((response) => {
					if (response) {
						setCustomers(response);
						var temp: ListItem[] = [];
						response.forEach((cust: Customer) => {
							temp.push({ label: cust.name, value: cust.id });
						});
						setCustomersList(temp);
					}
				});
		},
		[token]
	);

	useEffect(
		function getTenantsFromAPI() {
			if (token && token.length) {
				getTenants(token).then((response) => {
					if (response) {
						setTenants(response);
						var temp: ListItem[] = [];
						response.forEach((ten: Tenant) => {
							temp.push({ label: ten.name, value: ten.id });
						});
						setTenantsList(temp);
						setSelectedTenant(temp.find((e) => e.value === tenantID));
					}
				});
			}
		},
		[tenantID, token]
	);

	useEffect(
		function getProductTreeFromAPI() {
			if (token && !brandTree && tenantID)
				getBrandTree(token, tenantID).then((response) => {
					if (response) setBrandTree(response);
				});
		},
		[brandTree, tenantID, token]
	);

	function checkValidation(validationGroup: VALIDATIONGROUP) {
		return currentValidationGroup === validationGroup;
	}

	function copyURL() {
		let sharableURL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_AUTH0_DEV_REDIRECT_URL : process.env.REACT_APP_AUTH0_REDIRECT_URL;

		navigator.clipboard.writeText(`${sharableURL}/adminpanel/brandconfig?path=${configPath}`);
		toastManager("info", `Copied to clipboard!`, `Copied a sharable URL to your clipboard`);
	}

	function getConfigName(): string {
		if (selectedDepartment) return selectedDepartment.label;
		else if (selectedCustomer) return selectedCustomer.label;
		else if (selectedTenant) return selectedTenant.label;
		return "no name";
	}

	function Separator() {
		return (
			<>
				<Icon.ArrowDown />
				<br />
				<br />
			</>
		);
	}

	function handleNewConfig(event: any) {
		if (event.target.form.config_name.value === "") {
			toastManager("error", "Config name is empty!", "Please add a name for this new config to continue");
			return;
		}

		if (selectedTenant) {
			var path = `/tenant/${selectedTenant?.value}`;
			if (selectedCustomer) {
				path += `/customer/${selectedCustomer.value}`;
				if (selectedDepartment) {
					path += `/department/${selectedDepartment.value}`;
				}
			}
			setConfigName(event.target.form.config_name.value);
			handleSelectedConfig(path);
			handleCloseModal();
		}
	}

	function handleCopyPasteConfig(pastingConfig: VALIDATIONGROUP) {
		switch (pastingConfig) {
			case VALIDATIONGROUP.None:
				if (copyPaste !== "paste") {
					setCopiedData(brandConfig);
					toastManager("info", "Copied data", `Copied brandconfig data`);
					setCopyPaste("paste");
					setCopyPasteConfig(!copyPasteConfig);
				} else if (copyPaste === "paste") {
					setCopyPaste("copy");
					toastManager("error", "Stopped copying data", `Removed copied data from clipboard`);
					setCopiedData(undefined);
					setCopyPasteConfig(!copyPasteConfig);
				}
				break;
			case VALIDATIONGROUP.BrandName:
				pasteBrandName();
				break;
			case VALIDATIONGROUP.BackgroundImage:
				pasteBackgroundImage();
				break;
			case VALIDATIONGROUP.BrandColors:
				pasteBrandColors();
				break;
			case VALIDATIONGROUP.LogoURL:
				pasteLogoURL();
				break;
			default:
				break;
		}
	}

	function handleTenantChange(event: any) {
		setSelectedCustomer(null);
		setCustomersList(undefined);

		setSelectedDepartment(null);
		setDepartmentsList(undefined);

		if (event === null) setSelectedTenant(undefined);
		else {
			setSelectedTenant(event);
			populateCustomerList(event);
		}
	}
	function populateCustomerList(ten: any) {
		var temp: ListItem[] = [];
		if (customers)
			customers.forEach((cust) => {
				if (cust.tenant_id === ten?.value) temp.push({ label: cust.name, value: cust.id });
			});
		setCustomersList(temp);
	}

	function handleCustomerChange(event: any) {
		setSelectedCustomer(null);
		setSelectedDepartment(null);
		setDepartmentsList(undefined);

		if (event === null) {
			setSelectedCustomer(undefined);
		} else {
			setSelectedCustomer(event);
		}
	}

	function handleDepartmentChange(event: any) {
		if (event === null) {
			setSelectedDepartment(undefined);
		} else {
			setSelectedDepartment(event);
		}
	}

	return (
		<>
			<Navigation />

			{isAuthenticated && (
				<>
					<Container className=" paddingTopBottom">
						<BackButton
							route="/adminpanel"
							title="admin panel"
						/>
						<h1>Brand configuration</h1>
						<Accordion
							activeKey={accordionActiveKey}
							onSelect={setAccordionActiveKey}
						>
							<Accordion.Item eventKey={"config"}>
								<Accordion.Header>Current configurations: </Accordion.Header>
								<Accordion.Body>
									<Button
										className="right"
										onClick={() => setBrandTree(undefined)}
									>
										{" "}
										<Icon.ArrowClockwise />
									</Button>
									<PopulateTreeButtons />
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>

						{!reloadForms && (
							<>
								<Row className="center">
									<Col>
										<Button
											variant="success"
											onClick={handleShowModal}
											disabled={editConfiguration}
										>
											<Icon.PlusSquare /> Add new configuration
										</Button>
									</Col>
									<Col>
										<Button
											variant={copyPasteConfig ? "danger" : "secondary"}
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.None)}
											disabled={editConfiguration}
										>
											<Icon.CodeSquare /> {copyPasteConfig ? "Cancel pasting" : "Copy configuration"}
										</Button>
									</Col>
									<Col>
										{!editConfiguration && (
											<Button
												variant="primary"
												onClick={handleEditConfiguration}
												disabled={copyPasteConfig}
											>
												<Icon.PencilSquare /> Edit configuration
											</Button>
										)}
										{editConfiguration && (
											<Button
												variant="danger"
												onClick={() => setEditConfiguration(false)}
											>
												<Icon.Terminal /> Stop editing
											</Button>
										)}
									</Col>
								</Row>
								<hr />
								<Row className="center">
									{" "}
									<Col>
										<small>
											{" "}
											<b>Current configuration path:</b> {configPath}
											<Button
												size="sm"
												onClick={copyURL}
												className="right"
											>
												<Icon.ShareFill />
											</Button>
										</small>
									</Col>
								</Row>
								<hr />
								<Row>
									<Col>
										<h3>Config name</h3>
									</Col>
									<Col>
										<h3>Brand name</h3>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form>
											<Form.Control
												required={brandConfig?.config_name === undefined}
												id="config_name"
												type="input"
												placeholder={brandConfig?.config_name ? brandConfig.config_name : configName ?? noValue}
												disabled
											/>
										</Form>
									</Col>
									<Col>
										<Form validated={checkValidation(VALIDATIONGROUP.BrandName)}>
											<Form.Control
												required={brandConfig?.data?.brand_name === undefined}
												id="brand_name"
												type="input"
												placeholder={brandConfig?.data?.brand_name ?? noValue}
											/>
											<br />
											{editConfiguration && (
												<Button
													className="right"
													onClick={saveBrandName}
												>
													<Icon.FloppyFill />
												</Button>
											)}
											{copyPasteConfig && (
												<Button
													className="right"
													onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.BrandName)}
												>
													{<Icon.Save />}
												</Button>
											)}
										</Form>
									</Col>
								</Row>
								<hr />
								<Row>
									<Col>
										<h3>Logo</h3>
									</Col>
									<Col>
										<h3>Background image</h3>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form validated={checkValidation(VALIDATIONGROUP.LogoURL)}>
											<ImageForm
												name="logo_url"
												path={brandConfig?.data?.logo_url}
											/>
											{editConfiguration && (
												<Button
													className="right"
													onClick={saveLogoURL}
												>
													<Icon.FloppyFill />
												</Button>
											)}
											{copyPasteConfig && (
												<Button
													className="right"
													onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.LogoURL)}
												>
													{<Icon.Save />}
												</Button>
											)}
										</Form>
									</Col>
									<Col>
										<Form validated={checkValidation(VALIDATIONGROUP.BackgroundImage)}>
											<ImageForm
												name="background_image"
												path={brandConfig?.data?.background_image?.dark}
											/>
											{editConfiguration && (
												<Button
													className="right"
													onClick={saveBackgroundImage}
												>
													<Icon.FloppyFill />
												</Button>
											)}
											{copyPasteConfig && (
												<Button
													className="right"
													onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.BackgroundImage)}
												>
													{<Icon.Save />}
												</Button>
											)}
										</Form>
									</Col>
								</Row>
								<hr />
								<Form validated={checkValidation(VALIDATIONGROUP.BrandColors)}>
									<BrandColors
										editConfiguration={editConfiguration}
										noValue={noValue}
										brandConfig={brandConfig}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveBrandColors}
										>
											<Icon.FloppyFill />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.BrandColors)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
							</>
						)}
					</Container>

					<Modal
						show={showModal}
						onHide={handleCloseModal}
					>
						<Modal.Header closeButton>
							<Modal.Title>Add a new product configuration</Modal.Title>
						</Modal.Header>
						<Form>
							<Modal.Body>
								<Form.Label className="fw-bold"> </Form.Label>
								<Select
									defaultValue={{ label: "EarsOnly", value: 0 }}
									isDisabled
								/>
								<Separator />
								<Form.Label className="fw-bold">Tenant</Form.Label>
								<Select
									options={tenantsList}
									onChange={handleTenantChange}
									value={selectedTenant}
								/>
								<Separator />
								<Form.Label className="fw-bold">Customer</Form.Label>
								<Select
									isClearable
									options={customersList}
									onChange={handleCustomerChange}
									isDisabled={!selectedTenant}
									value={selectedCustomer}
								/>
								<Separator />
								<Form.Label className="fw-bold">Department</Form.Label>
								<Select
									isClearable
									options={departmentsList}
									onChange={handleDepartmentChange}
									isDisabled={!selectedCustomer}
									value={selectedDepartment}
								/>
								<br />
								Current selected config path: <br />
								<small className="fw-bold">
									{!selectedCustomer && !selectedDepartment && <>{`EarsOnly -> ${selectedTenant?.label}`}</>}

									{selectedCustomer && !selectedDepartment && <>{`EarsOnly -> ${selectedTenant?.label} -> ${selectedCustomer.label}`}</>}

									{selectedCustomer && selectedDepartment && <>{`EarsOnly -> ${selectedTenant?.label} -> ${selectedCustomer.label} -> ${selectedDepartment.label}`}</>}
								</small>
								<br />
								<br />
								<br />
								<Form.Label>Config name:</Form.Label>
								<Form.Control
									disabled
									type="input"
									id="config_name"
									value={getConfigName()}
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={handleNewConfig}>Add config</Button>
							</Modal.Footer>
						</Form>
					</Modal>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
