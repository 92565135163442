/**
 * @returns the value or placeholder of the current (event.target.form).item
 */
export function getChangedValueOrPlaceholder(item: any) {
	return item.value !== "" ? item.value : item.placeholder;
}

/**
 * @returns the value or placeholder of the current (event.target.form).item
 */
export function getChangedNameOrPlaceholder(item: any) {
	// Hacky way to see if a new file is uploaded
	return item.name.substr(item.name.length - 9) !== "undefined" ? item.name : item.placeholder; 
}

/**
 *
 * @param value possible empty value
 * @returns true if item is empty
 */
export function isNotEmpty(value: any): boolean {
	if (value === undefined || value === null || value === "") return false;
	else return true;
}
