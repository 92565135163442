import * as React from "react";
import * as Icon from "react-bootstrap-icons";
import { toast } from "sonner";

export default function toastManager(type: "success" | "error" | "warning" | "info", title: string, description: string, duration?: number, status_code?: string) {
	if (type === "success") {
		toast.success(`${title}`, {
			classNames: {
				title: "fw-bold text-uppercase",
			},
			description: `${description}`,
			icon: <Icon.CheckLg />,
			duration: duration,
		});
	}

	if (type === "error") {
		toast.error(`${title}`, {
			classNames: {
				title: "fw-bold text-uppercase",
				error: "sonner-icon"
			},
			description: `${description}`,
			icon: <p>{status_code}</p>,
			duration: duration,
		});
	}

	if (type === "warning") {
		toast.warning(`${title}`, {
			classNames: {
				title: "fw-bold text-uppercase",
			},
			description: `${description}`,
			icon: <Icon.ExclamationLg />,
			duration: duration,
		});
	}

	if (type === "info") {
		toast.info(`${title}`, {
			classNames: {
				title: "fw-bold text-uppercase",
			},
			description: `${description}`,
			icon: <Icon.InfoLg />,
			duration: duration,
		});
	}
}
