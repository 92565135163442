import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { BC_Fontstyle } from "../../api/types/BC_Product";
import { isNotEmpty } from "../helpers";

interface Props {
	name: string;
	style: BC_Fontstyle | undefined;
	noValue: string;
}
// Takes the name of the font and replaces the ' '(spaces) to '_' and makes everything lowercase
function createTag(name: string) {
	return name.replace(/ /g, "_").toLowerCase();
}

export default function FontStyle(props: Props) {
	const [bold, setBold] = useState(props.style?.font);
	const [caps, setCaps] = useState(props.style?.caps);
	const [size, setSize] = useState(props.style?.size);

	const changeBold = () => {
		if (bold === "bold") {
			setBold("regular");
		} else {
			setBold("bold");
		}
	};
	const changeCaps = () => setCaps(!caps);

	useEffect(() => {
		setBold(props.style?.font);
		setCaps(props.style?.caps);
		setSize(props.style?.size);
	}, [props.style?.caps, props.style?.font, props.style?.size]);

	var tag = createTag(props.name);
	return (
		<>
			<Col sm={3}>
				<Form.Label>{props.name}</Form.Label>
				<InputGroup>
					<Col sm={3}>
						<Form.Control
							required={size === undefined}
							type="number"
							id={tag + "_size"}
							placeholder={isNotEmpty(size) ? size?.toString() : props.noValue}
							onChange={(e) => setSize(e.target.value as unknown as number)}
						/>
					</Col>
					<Button
						variant="outline-secondary"
						id={tag + "_type"}
						active={bold === "bold"}
						size="sm"
						onClick={changeBold}
						value={bold ?? 'regular'}
					>
						Bold
					</Button>
					<Button
						variant="outline-secondary"
						id={tag + "_caps"}
						active={caps}
						size="sm"
						onClick={changeCaps}
						value={caps ? 1 : 0}
					>
						Caps
					</Button>
				</InputGroup>
			</Col>
		</>
	);
}
