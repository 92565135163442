import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BC_Product } from "../../api/types/BC_Product";
import { isNotEmpty } from "../helpers";

interface ValueProps {
	productConfig: BC_Product | undefined;
	editConfiguration: boolean;
	noValue: string;
}

export default function Values(props: ValueProps) {
	if (props?.productConfig?.data?.values || props.editConfiguration)
		return (
			<>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<Col>
						<Form.Label>Button corner radius</Form.Label>
						<Form.Control
							required={props.productConfig?.data?.values?.button_corner_radius === undefined}
							id="button_corner_radius"
							type="number"
							placeholder={isNotEmpty(props.productConfig?.data?.values?.button_corner_radius) ? props?.productConfig?.data.values.button_corner_radius.toString() : props.noValue}
						/>
					</Col>
					<Col>
						<Form.Label>Container corner radius</Form.Label>
						<Form.Control
							required={props.productConfig?.data?.values?.container_corner_radius === undefined}
							id="container_corner_radius"
							type="number"
							placeholder={isNotEmpty(props.productConfig?.data?.values?.container_corner_radius) ? props?.productConfig?.data.values.container_corner_radius.toString() : props.noValue}
						/>
					</Col>
					<Col>
						<Form.Label>Control corner radius</Form.Label>
						<Form.Control
							required={props.productConfig?.data?.values?.control_corner_radius === undefined}
							id="control_corner_radius"
							type="number"
							placeholder={isNotEmpty(props?.productConfig?.data?.values?.control_corner_radius) ? props?.productConfig?.data.values.control_corner_radius.toString() : props.noValue}
						/>
					</Col>
					<Col>
						<Form.Label>Sheet corner radius</Form.Label>
						<Form.Control
							required={props.productConfig?.data?.values?.sheet_corner_radius === undefined}
							id="sheet_corner_radius"
							type="number"
							placeholder={isNotEmpty(props?.productConfig?.data?.values?.sheet_corner_radius) ? props?.productConfig?.data.values.sheet_corner_radius.toString() : props.noValue}
						/>
					</Col>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<Col>
						<Form.Label>Button style primary</Form.Label>
						<Form.Select
							required={props.productConfig?.data?.values?.button_style_primary === undefined}
							id="button_style_primary"
							defaultValue={props?.productConfig?.data?.values?.button_style_primary}
						>
							<option value="filled">filled</option>
							<option value="outlined">outlined</option>
							<option value="text_only">text only</option>
						</Form.Select>
					</Col>
					<Col>
						<Form.Label>Button style secondary</Form.Label>
						<Form.Select
							required={props.productConfig?.data?.values?.button_style_secondary === undefined}
							id="button_style_secondary"
							defaultValue={props?.productConfig?.data?.values?.button_style_secondary}
						>
							<option value="filled">filled</option>
							<option value="outlined">outlined</option>
							<option value="text_only">text only</option>
						</Form.Select>
					</Col>
				</Form.Group>
			</>
		);
	else return <>No changes to Values</>;
}
