import React, { useEffect, useState } from "react";
import { Col, Form, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { getImageFromTasselCache, uploadToTasselCache } from "../../api/cdn_apimanager";
import { CDN_response } from "../../api/types/CDN_response";
import { ImageType } from "../../api/types/ImageType";
import toastManager from "../toastmanager";
interface Props {
	name: string;
	path: string | undefined;
}

//* Values get updated automaticaly when a file is selected
export default function ImageForm(props: Props) {
	var name = props.name.replace(/ /g, "_").toLowerCase();

	const [file, setFile] = useState<CDN_response>();
	const [image, setImage] = useState<ImageType>();

	function handleUpload(event: any) {
		event.preventDefault();
		uploadToTasselCache(event.target.files[0]).then((response) => {
			toastManager("success", "Uploaded file", `Uploaded file: ${event.target.files[0].name}, Please save to confirm`);
			setFile(response);
		});
	}

	useEffect(() => {
		if (file) {
			getImageFromTasselCache(file.data.document).then((response) => {
				try {
					if (response) setImage(response);
				} catch (error) {
					toastManager("error", "Error with retrieving image", error as string);
				}
			});
		}
	}, [file, props.path]);

	return (
		<>
			<Col
				className="text-truncate"
				sm={4}
			>
				<Form.Control
					type="file"
					id={name}
					accept=".svg, .png, .jpg, .jpeg"
					onChange={handleUpload}
					name={`${process.env.REACT_APP_CDN_BASEURL}/cache/tassel/${file?.data.document}`}
					placeholder={props.path}
				/>
				{!file && (
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip id={`tooltip-${props.name}`}>{props.path}</Tooltip>}
					>
						<Form.Text muted>{props.path}</Form.Text>
					</OverlayTrigger>
				)}
				{image && (
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip id={`tooltip-${props.name}`}>{image.title}</Tooltip>}
					>
						<>
							<Form.Text muted>{image.title}</Form.Text>
							<h6 className="text-success center">
								<Icon.Check />
								Image uploaded <br />
								<small>(save to confirm change)</small>
							</h6>
						</>
					</OverlayTrigger>
				)}
			</Col>

			<Col sm={2}>
				{image && (
					<>
						<Image
							thumbnail
							src={`data:image/*; base64,${image?.base64}`}
							alt={props.name}
						/>
					</>
				)}
				{props.path && !image && (
					<Image
						thumbnail
						src={`${props.path}`}
						alt={`${props.path}`}
					></Image>
				)}
			</Col>
		</>
	);
}