import React from "react";
import { Form, Row } from "react-bootstrap";
import { BC_Brand } from "../../api/types/BC_Brand";
import ColorPicker from "./colorpicker";

interface Props {
	brandConfig: BC_Brand | undefined;
	editConfiguration: boolean;
	noValue: string;
}

export default function BrandColors(props: Props) {
	if (props.brandConfig?.data?.colors || props.editConfiguration)
		return (
			<>
				<Form.Group
					as={Row}
					className="mb-3"
				></Form.Group>
				{/* BUTTON COLORS */}
				<p>Button Colors</p>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Primary background"
						colors={props?.brandConfig?.data?.colors?.light?.button.primary_background}
					/>
					<ColorPicker
						name="Secondary background"
						colors={props?.brandConfig?.data?.colors?.light?.button.secondary_background}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Primary background disabled"
						colors={props?.brandConfig?.data?.colors?.light?.button.primary_background_disabled}
					/>
					<ColorPicker
						name="Secondary background disabled"
						colors={props?.brandConfig?.data?.colors?.light?.button.secondary_background_disabled}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Primary text"
						colors={props?.brandConfig?.data?.colors?.light?.button.primary_text}
					/>
					<ColorPicker
						name="Secondary text"
						colors={props?.brandConfig?.data?.colors?.light?.button.secondary_text}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Primary text disabled"
						colors={props?.brandConfig?.data?.colors?.light?.button.primary_text_disabled}
					/>
					<ColorPicker
						name="Secondary text disabled"
						colors={props?.brandConfig?.data?.colors?.light?.button.secondary_text_disabled}
					/>
				</Form.Group>

				{/* TEXT COLORS */}
				<p>Text Colors</p>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Primary"
						colors={props?.brandConfig?.data?.colors?.light?.text.primary}
					/>
					<ColorPicker
						name="Secondary"
						colors={props?.brandConfig?.data?.colors?.light?.text.secondary}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Control active"
						colors={props?.brandConfig?.data?.colors?.light?.text.control_active}
					/>
					<ColorPicker
						name="Control inactive"
						colors={props?.brandConfig?.data?.colors?.light?.text.control_inactive}
					/>
				</Form.Group>

				{/* UI COLORS */}
				<p>UI Colors</p>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Background gradient top"
						colors={props?.brandConfig?.data?.colors?.light?.ui.background_gradient_top}
					/>
					<ColorPicker
						name="Background gradient bottom"
						colors={props?.brandConfig?.data?.colors?.light?.ui.background_gradient_bottom}
					/>
				</Form.Group>

				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Control foreground active"
						colors={props?.brandConfig?.data?.colors?.light?.ui.control_foreground_active}
					/>
					<ColorPicker
						name="Control foreground Inactive"
						colors={props?.brandConfig?.data?.colors?.light?.ui.control_foreground_inactive}
					/>
				</Form.Group>

				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Control background"
						colors={props?.brandConfig?.data?.colors?.light?.ui.control_background}
					/>
					<ColorPicker
						name="Foreground layer"
						colors={props?.brandConfig?.data?.colors?.light?.ui.foreground_layer}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Divider"
						colors={props?.brandConfig?.data?.colors?.light?.ui.divider}
					/>
					<ColorPicker
						name="Status neutral"
						colors={props?.brandConfig?.data?.colors?.light?.ui.status_neutral}
					/>
				</Form.Group>
				<Form.Group
					as={Row}
					className="mb-3"
				>
					<ColorPicker
						name="Status positive"
						colors={props?.brandConfig?.data?.colors?.light?.ui.status_positive}
					/>
					<ColorPicker
						name="Status disabled"
						colors={props?.brandConfig?.data?.colors?.light?.ui.status_disabled}
					/>
				</Form.Group>
			</>
		);
	else return <>No changes to Brand Colors</>;
}
