/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { getOrderStatus } from "../api/orderportal_apimanager";

interface Props {
	orderid: string | null;
}

type orderStatusList = {
	created: number;
	locked: number;
	confirmed: number;
	production: number;
	shipping: number;
	completed: number;
};

export default function StatusComponent(props: Props) {
	const token = JSON.parse(localStorage.getItem("accessToken")!);

	const amountOfStatusOptions = 6;
	const increasePercentage = 100 / amountOfStatusOptions;

	const [createdDate, setCreatedDate] = useState("");
	const [lockedDate, setLockedDate] = useState("");
	const [confirmedDate, setConfirmedDate] = useState("");
	const [productionDate, setProductionDate] = useState("");
	const [shippingDate, setShippingDate] = useState("");
	const [completedDate, setCompletedDate] = useState("");

	var timeout: NodeJS.Timeout | null = null;

	const [retrievedData, setRetrievedData] = useState(false);
	const [orderProgress, setOrderProgress] = useState(0);
	const [orderStatus, setOrderStatus] = useState("");
	const [orderTime, setOrderTime] = useState("");
	const [orderDate, setOrderDate] = useState("");
	const [orderStatusList, setOrderStatusList] = useState<orderStatusList>();

	useEffect(() => {
		if (props.orderid && !retrievedData) {
			const responseHandler = (response: orderStatusList) => {
				var temp = response;
				setOrderStatusList(temp);
				handleData(temp);
				setRetrievedData(true);
				// eslint-disable-next-line react-hooks/exhaustive-deps
				timeout = setTimeout(() => t(), 5000);
			};
			// make the element update each 5 seconds
			const t = () => {
				if (!document.hidden) getOrderStatus(token, props.orderid!).then(responseHandler);
				else {
					timeout = setTimeout(() => t(), 5000);
				}
			};
			t();
		}
		return () => {
			if (timeout) clearTimeout(timeout);
		};
	}, []);

	function handleData(temp: orderStatusList) {
		if (temp) {
			var percent = 0;
			var lastUpdate = 0;

			if (temp.created) {
				setOrderStatus("Created");
				lastUpdate = temp.created;
				percent += increasePercentage;

				var tempTimestamp = new Date(0);
				tempTimestamp.setUTCMilliseconds(temp.created);
				setCreatedDate(`${tempTimestamp.toLocaleDateString()} @ ${tempTimestamp.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`);
			}

			if (temp.locked) {
				setOrderStatus("Locked");
				lastUpdate = temp.locked;
				percent += increasePercentage;

				var tempTimestamp = new Date(0);
				tempTimestamp.setUTCMilliseconds(temp.locked);
				setLockedDate(`${tempTimestamp.toLocaleDateString()} @ ${tempTimestamp.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`);
			}

			if (temp.confirmed) {
				setOrderStatus("Confirmed");
				lastUpdate = temp.confirmed;
				percent += increasePercentage;

				var tempTimestamp = new Date(0);
				tempTimestamp.setUTCMilliseconds(temp.confirmed);
				setConfirmedDate(`${tempTimestamp.toLocaleDateString()} @ ${tempTimestamp.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`);
			}

			if (temp.production) {
				setOrderStatus("Production");
				lastUpdate = temp.production;
				percent += increasePercentage;

				var tempTimestamp = new Date(0);
				tempTimestamp.setUTCMilliseconds(temp.production);
				setProductionDate(`${tempTimestamp.toLocaleDateString()} @ ${tempTimestamp.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`);
			}

			if (temp.shipping) {
				setOrderStatus("Shipping");
				lastUpdate = temp.shipping;
				percent += increasePercentage;

				var tempTimestamp = new Date(0);
				tempTimestamp.setUTCMilliseconds(temp.shipping);
				setShippingDate(`${tempTimestamp.toLocaleDateString()} @ ${tempTimestamp.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`);
			}

			if (temp.completed) {
				setOrderStatus("Completed");
				lastUpdate = temp.completed;
				percent = 100;

				var tempTimestamp = new Date(0);
				tempTimestamp.setUTCMilliseconds(temp.completed);
				setCompletedDate(`${tempTimestamp.toLocaleDateString()} @ ${tempTimestamp.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`);
			}
			setOrderProgress(percent);
			var lastUpdateDate = new Date(0);
			lastUpdateDate.setUTCMilliseconds(lastUpdate);
			setOrderTime(lastUpdateDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
			setOrderDate(lastUpdateDate.toLocaleDateString());
		}
	}

	if (orderStatusList)
		return (
			<>
				{/* THIS IS VISIBLE ON LARGE SCREENS */}
				<div className="d-none d-lg-block ">
					<div className="center">
						<Row>
							<OverlayTrigger
								overlay={
									<Tooltip id="0">
										Created <br /> {createdDate}
									</Tooltip>
								}
							>
								<Col>
									{/* CREATED */}
									{!orderStatusList.locked && (
										<>
											{orderStatusList.created ? (
												<Col className="tracker tracker-current">
													<Icon.Basket2Fill />
												</Col>
											) : (
												<Col className="tracker">
													<Icon.Basket2 />
												</Col>
											)}
										</>
									)}
									{orderStatusList.locked && (
										<Col className="tracker tracker-passed">
											<Icon.Basket2Fill />
										</Col>
									)}
								</Col>
							</OverlayTrigger>

							{/* LOCKED */}
							<OverlayTrigger
								overlay={
									<Tooltip id="1">
										Locked <br /> {lockedDate}
									</Tooltip>
								}
							>
								<Col>
									{!orderStatusList.confirmed && (
										<>
											{orderStatusList.locked ? (
												<Col className="tracker tracker-current">
													<Icon.LockFill />
												</Col>
											) : (
												<Col className="tracker">
													<Icon.Lock />
												</Col>
											)}
										</>
									)}
									{orderStatusList.confirmed && (
										<Col className="tracker tracker-passed">
											<Icon.LockFill />
										</Col>
									)}
								</Col>
							</OverlayTrigger>
							{/* CONFIRMED */}
							<OverlayTrigger
								overlay={
									<Tooltip id="2">
										Confirmed <br /> {confirmedDate}
									</Tooltip>
								}
							>
								<Col>
									{!orderStatusList.production && (
										<>
											{orderStatusList.confirmed ? (
												<Col className="tracker tracker-current">
													<Icon.CheckSquareFill />
												</Col>
											) : (
												<Col className="tracker">
													<Icon.CheckSquare />
												</Col>
											)}
										</>
									)}
									{orderStatusList.production && (
										<Col className="tracker tracker-passed">
											<Icon.CheckSquareFill />
										</Col>
									)}
								</Col>
							</OverlayTrigger>

							{/* PRODUCTION*/}
							<OverlayTrigger
								overlay={
									<Tooltip id="3">
										Production <br /> {productionDate}
									</Tooltip>
								}
							>
								<Col>
									{!orderStatusList.shipping && (
										<>
											{orderStatusList.production ? (
												<Col className="tracker tracker-current">
													<Icon.CpuFill />
												</Col>
											) : (
												<Col className="tracker">
													<Icon.Cpu />
												</Col>
											)}
										</>
									)}
									{orderStatusList.shipping && (
										<Col className="tracker tracker-passed">
											<Icon.CpuFill />
										</Col>
									)}
								</Col>
							</OverlayTrigger>
							{/* SHIPPING */}
							<OverlayTrigger
								overlay={
									<Tooltip id="1">
										Shipping <br /> {shippingDate}
									</Tooltip>
								}
							>
								<Col>
									{!orderStatusList.completed && (
										<>
											{orderStatusList.shipping ? (
												<Col className="tracker tracker-current">
													<Icon.SendFill />
												</Col>
											) : (
												<Col className="tracker">
													<Icon.Send />
												</Col>
											)}
										</>
									)}
									{orderStatusList.completed && (
										<Col className="tracker tracker-passed">
											<Icon.SendFill />
										</Col>
									)}
								</Col>
							</OverlayTrigger>
							{/* COMPLETED */}
							<OverlayTrigger
								overlay={
									<Tooltip id="1">
										Completed <br /> {completedDate}
									</Tooltip>
								}
							>
								<Col>
									{orderStatusList.completed ? (
										<Col className="tracker tracker-current">
											<Icon.PersonFill />
										</Col>
									) : (
										<Col className="tracker">
											<Icon.Person />
										</Col>
									)}
								</Col>
							</OverlayTrigger>
						</Row>
						<br />
						<Row>
							<Col>
								<ProgressBar>
									{orderProgress > increasePercentage && (
										<ProgressBar
											variant="secondary"
											now={orderProgress - increasePercentage}
											animated={orderProgress !== 100}
											key={2}
										/>
									)}
									<ProgressBar
										label={`${orderStatus}`}
										variant="primary"
										now={increasePercentage}
										animated={orderProgress !== 100}
										key={1}
									/>
								</ProgressBar>
							</Col>
						</Row>
						<Row>
							<Col>
								Last update: {orderDate} @ {orderTime}
							</Col>
						</Row>
					</div>
				</div>
				{/* THIS IS VISIBLE ON MOBILE */}
				<div className="d-block d-sm-none gy-3 tracker-mobile ">
					{createdDate && !lockedDate && (
						<Row className="tracker-current">
							<Col>
								<Icon.Basket2Fill />
								Created
							</Col>
							<Col>
								<small>{createdDate}</small>
							</Col>
						</Row>
					)}
					{createdDate && lockedDate && (
						<Row className="tracker-passed">
							<Col>
								<Icon.Basket2Fill />
								Created
							</Col>
							<Col>
								<small>{createdDate}</small>
							</Col>
						</Row>
					)}
					<hr />
					{/* LOCKED */}
					{!lockedDate && (
						<Row>
							<Col>
								<Icon.LockFill />
								Locked
							</Col>
							<Col></Col>
						</Row>
					)}
					{lockedDate && !confirmedDate && (
						<Row className="tracker-current">
							<Col>
								<Icon.LockFill />
								Locked
							</Col>
							<Col>
								<small>{lockedDate}</small>
							</Col>
						</Row>
					)}
					{lockedDate && confirmedDate && (
						<Row className="tracker-passed">
							<Col>
								<Icon.LockFill />
								Locked
							</Col>
							<Col>
								<small>{lockedDate}</small>
							</Col>
						</Row>
					)}
					<hr />
					{/* CONFIRMED */}
					{!confirmedDate && (
						<Row>
							<Col>
								<Icon.CheckSquareFill />
								Confirmed
							</Col>
							<Col></Col>
						</Row>
					)}
					{confirmedDate && !productionDate && (
						<Row className="tracker-current">
							<Col>
								<Icon.CheckSquareFill />
								Confirmed
							</Col>
							<Col>
								<small>{confirmedDate}</small>
							</Col>
						</Row>
					)}

					{confirmedDate && productionDate && (
						<Row className="tracker-passed">
							<Col>
								<Icon.CheckSquareFill />
								Confirmed
							</Col>
							<Col>
								<small>{confirmedDate}</small>
							</Col>
						</Row>
					)}

					<hr />
					{/* PRODUCTION */}
					{!productionDate && (
						<Row>
							<Col>
								<Icon.CpuFill />
								Production
							</Col>
							<Col></Col>
						</Row>
					)}
					{productionDate && !shippingDate && (
						<Row className="tracker-current">
							<Col>
								<Icon.CpuFill />
								Production
							</Col>
							<Col>
								<small>{productionDate}</small>
							</Col>
						</Row>
					)}
					{productionDate && shippingDate && (
						<Row className="tracker-passed">
							<Col>
								<Icon.CpuFill />
								Production
							</Col>
							<Col>
								<small>{productionDate}</small>
							</Col>
						</Row>
					)}
					<hr />
					{/* SHIPPING */}
					{!shippingDate && (
						<Row>
							<Col>
								<Icon.SendFill />
								Shipping
							</Col>
							<Col></Col>
						</Row>
					)}
					{shippingDate && !completedDate && (
						<Row className="tracker-current">
							<Col>
								<Icon.SendFill />
								Shipping
							</Col>
							<Col>
								<small>{shippingDate}</small>
							</Col>
						</Row>
					)}
					{shippingDate && completedDate && (
						<Row className="tracker-passed">
							<Col>
								<Icon.SendFill />
								Shipping
							</Col>
							<Col>
								<small>{shippingDate}</small>
							</Col>
						</Row>
					)}
					<hr />
					{/* COMPLETED */}
					{!completedDate && (
						<Row>
							<Col>
								<Icon.PersonFill />
								Completed
							</Col>
							<Col></Col>
						</Row>
					)}
					{completedDate && (
						<Row className="tracker-current">
							<Col>
								<Icon.PersonFill />
								Completed -
							</Col>
							<Col>
								<small>{completedDate}</small>
							</Col>
						</Row>
					)}
				</div>
			</>
		);
	else return <></>;
}
