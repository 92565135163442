import * as React from "react";
import { Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

interface Props {
	route: string;
	title: string;
}
export default function BackButton(props: Props) {
	const navigate = useNavigate();
	if (props.route === "-1") {
		return (
			<>
				<br className="d-block d-sm-none " />
				<br className="d-block d-sm-none " />
				<Button
					onClick={() => navigate(-1)}
					variant="outline-secondary"
				>
					{" "}
					<Icon.ArrowLeft className="backbutton" />
					Back to {props.title}
				</Button>
				<br className="d-block d-sm-none " />
				<br className="d-block d-sm-none " />
			</>
		);
	} else {
		return (
			<>
				<br className="d-block d-sm-none " />
				<br className="d-block d-sm-none " />
				<Button
					onClick={() => navigate(props.route)}
					variant="outline-secondary"
				>
					{" "}
					<Icon.ArrowLeft className="backbutton" />
					Back to {props.title}
				</Button>
				<br className="d-block d-sm-none " />
				<br className="d-block d-sm-none " />
			</>
		);
	}
}
