import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { addDepartment, deleteDepartment, getCustomer, getDepartments, updateDepartment } from "../api/orderportal_apimanager";
import { Customer } from "../api/types/Customer";
import { Department } from "../api/types/Department";
import BackButton from "./backbutton";
import Navigation from "./navigation";
import toastManager from "./toastmanager";
import { useNavigate } from "react-router-dom";

interface Props {
	customer: Customer;
	departments: Department[];
}

export default function Departments(props: Props) {
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const [showAddDepartment, setShowAddDepartment] = useState<boolean>(false);
	const [pressedAdd, setPressedAdd] = useState<boolean>(false);
	const [showEditDepartment, setShowEditDepartment] = useState<boolean>(false);
	const [editDepartment, setEditDepartment] = useState<Department>();

	const [reload, setReload] = useState<boolean>(false);

	function handleAddDepartment(event: any) {
		event.preventDefault();
		setPressedAdd(true);
		var etf = event.target.form;
		if (etf.name.value && etf.description.value && etf.dust_level.value && etf.gas_level.value && etf.average_noise_exposure.value && etf.peak_noise_exposure.value) {
			addDepartment(props.customer.id, etf.name.value, etf.description.value, etf.dust_level.value, etf.gas_level.value, etf.average_noise_exposure.value, etf.peak_noise_exposure.value).then(
				(response) => {
					toastManager("success", `Successfully added ${etf.name.value}`, `Added the department ${etf.name.value} to ${props.customer.name}`);
					handleAddDepartmentClose();
					setReload(true);
				}
			);
		}
	}

	function handleUpdateDepartment(event: any) {
		event.preventDefault();
		setReload(true);
		setPressedAdd(true);
		var etf = event.target.form;
		if (etf.name.value && etf.description.value && etf.dust_level.value && etf.gas_level.value && etf.average_noise_exposure.value && etf.peak_noise_exposure.value) {
			updateDepartment(
				props.customer.id!,
				etf.name.value,
				etf.description.value,
				etf.dust_level.value,
				etf.gas_level.value,
				etf.average_noise_exposure.value,
				etf.peak_noise_exposure.value,
				editDepartment!.id
			).then((response) => {
				toastManager("info", `Successfully updated ${etf.name.value}`, `Updated the department ${etf.name.value} of ${props.customer.name}`);
				handleEditDepartmentClose();
			});
		}
	}

	//TODO add a 'are you sure' alert?!
	function handleRemoveDepartment(event: any) {
		setReload(true);
		deleteDepartment(props.customer.id, editDepartment!.id).then((response) => {
			toastManager("warning", `Successfully removed ${editDepartment?.name}`, `Successfully removed the department ${editDepartment?.name} from ${props.customer.name}`);
			handleEditDepartmentClose();
		});
	}

	const handleAddDepartmentOpen = () => setShowAddDepartment(true);

	const handleAddDepartmentClose = () => {
		setShowAddDepartment(false);
		setPressedAdd(false);
	};
	const handleEditDepartmentClose = () => {
		setShowEditDepartment(false);
		setPressedAdd(false);
	};

	function Edit(dep: Department) {
		setEditDepartment(dep);
		setShowEditDepartment(true);
	}

	function DepartmentsCards() {
		var departmentsCards: JSX.Element[] | undefined = [];
		if (props.departments !== undefined) {
			props.departments.forEach((department) => {
				if (departmentsCards !== undefined)
					departmentsCards.push(
						<Col
							className="padding-bottom"
							sm={4}
							key={department.id}
						>
							<Card>
								<Card.Header className="text-truncate center">
									<h5>{department.name}</h5>
								</Card.Header>
								<Card.Body>
									<Row>
										<Col
											sm={5}
											className="text-truncate fw-bold"
										>
											Description:{" "}
										</Col>
										<Col>{department.description}</Col>
									</Row>
									<hr />
									<Row>
										<Col
											sm={5}
											className="text-truncate fw-bold"
										>
											Dust level:{" "}
										</Col>
										<Col className="text-truncate">{department.dust_level}</Col>
									</Row>
									<Row>
										<Col
											sm={5}
											className="text-truncate fw-bold"
										>
											Gas level:{" "}
										</Col>
										<Col className="text-truncate">{department.gas_level}</Col>
									</Row>
									<hr />
									<Row>
										<Col className="text-truncate fw-bold">Noise exposure</Col>
									</Row>
									<Row>
										<Col
											sm={5}
											className="text-truncate fw-bold"
										>
											Average:{" "}
										</Col>
										<Col className="text-truncate">{department.average_noise_exposure}</Col>
									</Row>
									<Row>
										<Col
											sm={5}
											className="text-truncate fw-bold"
										>
											Peak:
										</Col>
										<Col className="text-truncate">{department.peak_noise_exposure}</Col>
									</Row>
								</Card.Body>
								<Card.Footer className="text-truncate">
									<Button
										className="right rounded-pill"
										variant="outline-primary"
										onClick={() => Edit(department)}
									>
										EDIT
									</Button>
								</Card.Footer>
							</Card>
						</Col>
					);
			});
		}
		if (departmentsCards !== undefined) {
			return <>{departmentsCards}</>;
		} else return <></>;
	}

	return (
		<>
			{props.customer && (
				<>
					<Button
						variant="outline-primary"
						onClick={handleAddDepartmentOpen}
					>
						<Icon.BuildingFillAdd /> Add a new Department
					</Button>

					<Row>
						<DepartmentsCards />
					</Row>

					<Modal
						show={showAddDepartment}
						onHide={handleAddDepartmentClose}
						keyboard={true}
						centered
					>
						<Form validated={pressedAdd}>
							<Modal.Header closeButton>
								<Modal.Title>Add a new department</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form.Label className="fw-bold">Name</Form.Label>
								<Form.Control
									required
									placeholder="Smeltery"
									id="name"
									type="text"
								></Form.Control>
								<br />
								<Form.Label className="fw-bold">Description</Form.Label>
								<Form.Control
									required
									as="textarea"
									rows={3}
									placeholder="Department for everyone who is working close by the smeltery"
									id="description"
								></Form.Control>
								<hr />
								<Row>
									<Col>
										<Form.Label className="fw-bold">Dust level</Form.Label>
										<Form.Control
											required
											defaultValue={0}
											id="dust_level"
											type="text"
										></Form.Control>
									</Col>
									<Col>
										<Form.Label className="fw-bold">Gas level</Form.Label>
										<Form.Control
											required
											defaultValue={0}
											id="gas_level"
											type="text"
										></Form.Control>
									</Col>
								</Row>
								<hr />
								<Row>
									<Col>
										<Form.Label className="fw-bold">Average noise exposure</Form.Label>
										<Form.Control
											required
											defaultValue="0dB"
											id="average_noise_exposure"
											type="text"
										></Form.Control>
									</Col>
									<Col>
										<Form.Label className="fw-bold">Peak noise exposure</Form.Label>
										<Form.Control
											required
											defaultValue="0dB"
											id="peak_noise_exposure"
											type="text"
										></Form.Control>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="primary"
									type="submit"
									onClick={handleAddDepartment}
								>
									{" "}
									Add{" "}
								</Button>
							</Modal.Footer>
						</Form>
					</Modal>
					<Modal
						show={showEditDepartment}
						onHide={() => setShowEditDepartment(false)}
						keyboard={true}
						centered
					>
						<Form validated={pressedAdd}>
							<Modal.Header closeButton>
								<Modal.Title>
									'{editDepartment?.name}'{" "}
									
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form.Label className="fw-bold">Name</Form.Label>
								<Form.Control
									required
									defaultValue={editDepartment?.name}
									id="name"
									type="text"
								></Form.Control>
								<br />
								<Form.Label className="fw-bold">Description</Form.Label>
								<Form.Control
									required
									as="textarea"
									rows={3}
									defaultValue={editDepartment?.description}
									id="description"
								></Form.Control>
								<hr />
								<Row>
									<Col>
										<Form.Label className="fw-bold">Dust level</Form.Label>
										<Form.Control
											required
											defaultValue={editDepartment?.dust_level}
											id="dust_level"
											type="text"
										></Form.Control>
									</Col>
									<Col>
										<Form.Label className="fw-bold">Gas level</Form.Label>
										<Form.Control
											required
											defaultValue={editDepartment?.gas_level}
											id="gas_level"
											type="text"
										></Form.Control>
									</Col>
								</Row>
								<hr />
								<Row>
									<Col>
										<Form.Label className="fw-bold">Average noise exposure</Form.Label>
										<Form.Control
											required
											defaultValue={editDepartment?.average_noise_exposure}
											id="average_noise_exposure"
											type="text"
										></Form.Control>
									</Col>
									<Col>
										<Form.Label className="fw-bold">Peak noise exposure</Form.Label>
										<Form.Control
											required
											defaultValue={editDepartment?.peak_noise_exposure}
											id="peak_noise_exposure"
											type="text"
										></Form.Control>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>

							<Button
										variant="outline-danger border-0"
										type="button"
										className="rounded-pill"
										onClick={handleRemoveDepartment}
									>
										<Icon.Trash /> Delete department, <small>Deleting could leave users without a department and their settings</small>
									</Button>{" "}
								<Button
									variant="primary"
									type="submit"
									onClick={handleUpdateDepartment}
								>
									{" "}
									Update{" "}
								</Button>
							</Modal.Footer>
						</Form>
					</Modal>
				</>
			)}
			{!props.customer && <p>Please select a customer</p>}
		</>
	);
}
