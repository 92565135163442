import { Document, Image, Line, Page, Path, Rect, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { AppointmentResult, AppointmentType, ResultProduct } from "../../api/types/AppointmentType";
import Logo from "./EarsOnly_Logo_FullColour_Grey.png";
import Check from "./check.png";
import Cross from "./delete.png";
import C from "./C.png";
import L from "./L.svg";
import R from "./R.png";

interface Props {
	appointment: AppointmentType;
	result: AppointmentResult | undefined;
	selectedProduct: ResultProduct | undefined;
	currentPortalUser: string;
}

export const CreateLeaktestRaport = (props: Props) => {
	return (
		<Document>
			<Page>
				<Header />
				<PersonalDetails />
				<Results />
				<Disclaimer />
			</Page>
		</Document>
	);

	function Header() {
		const style = StyleSheet.create({
			logo: {
				alignSelf: "center",
				width: 350,
			},
			title: {
				fontFamily: "Helvetica-Bold",
				color: "#ff6d2d",
				fontSize: 20,
				paddingBottom: 10,
				textAlign: "center",
			},
			text: {
				fontFamily: "Helvetica",
				fontSize: 12,
			},
			row: {
				alignSelf: "center",
				flexDirection: "row",
			},
			svgL: {
				marginRight: 2,
			},
			svg: {
				marginRight: 2,
				marginLeft: 8,
			},
		});

		return (
			<>
				<Image
					src={Logo}
					style={style.logo}
				/>
				<Text style={style.title}>Persoonlijk controlerapport lektest</Text>

				<View style={[style.row]}>
					<View style={style.row}>
						<Svg
							style={style.svgL}
							width="8"
							height="10"
							fill="none"
							viewBox="0 0 8 10"
						>
							<Path
								fill="#5C798C"
								d="M.825 10V.2h2.268v7.952h4.915V10H.825z"
							></Path>
						</Svg>
						<Text style={style.text}>{props.selectedProduct?.serial_left}</Text>
					</View>
					<View style={style.row}>
						<Svg
							style={style.svg}
							width="10"
							height="10"
							fill="none"
							viewBox="0 0 10 10"
						>
							<Path
								fill="#5C798C"
								d="M.515 10V.2h4.242c.877 0 1.633.145 2.268.434.634.28 1.124.686 1.47 1.218.345.532.518 1.167.518 1.904 0 .728-.173 1.358-.518 1.89-.346.523-.836.924-1.47 1.204-.635.28-1.391.42-2.268.42H1.775l1.008-.994V10H.515zm6.23 0l-2.45-3.556h2.422L9.195 10h-2.45zM2.783 6.528L1.775 5.464H4.63c.7 0 1.222-.15 1.568-.448.345-.308.518-.728.518-1.26 0-.541-.173-.961-.518-1.26-.346-.299-.868-.448-1.568-.448H1.775L2.783.97v5.558z"
							></Path>
						</Svg>

						<Text style={style.text}>{props.selectedProduct?.serial_right}</Text>
					</View>
					<View style={style.row}>
						<Svg
							style={style.svg}
							width="24"
							height="14"
							viewBox="0 0 24 14"
						>
							<Rect
								width="22"
								height="12"
								x="1"
								y="1"
								stroke="#5C798C"
								strokeWidth="2"
								rx="6"
								ry="6"
							></Rect>
							<Line
								x1="1"
								y1="6.5"
								x2="23"
								y2="6.5"
								stroke="#5C798C"
							/>
							<Line
								x1="8"
								y1="7.27759"
								x2="16"
								y2="7.27759"
								stroke="#5C798C"
							/>
						</Svg>

						<Text style={style.text}>{props.selectedProduct?.serial_cradle}</Text>
					</View>
				</View>
			</>
		);
	}

	function PersonalDetails() {
		const style = StyleSheet.create({
			details: {
				fontFamily: "Helvetica",
				paddingTop: 50,
				alignSelf: "center",
			},
			text: {
				fontSize: 15,
			},
			col: {
				flexDirection: "column",
			},
			row: {
				flexDirection: "row",
				justifyContent: "space-between",
			},
			right: {
				paddingRight: 50,
			},
			icon: {
				width: 15,
			},
		});
		var date = new Date(0);
		date.setMilliseconds(props.appointment.completed);

		return (
			<View style={[style.details, style.row]}>
				<View style={style.col}>
					<Text style={style.text}>Naam: {props?.selectedProduct?.consumer_name}</Text>
					<Text style={style.text}>Bedrijf: {props.appointment.customer_name}</Text>
					<Text style={style.text}>Getest: {date.toLocaleDateString(["NL"], { hour: "2-digit", minute: "2-digit" })}</Text>
					<Text style={style.text}>Tester: {props.result?.operator_name}</Text>
				</View>
			</View>
		);
	}
	function Results() {
		const style = StyleSheet.create({
			details: {
				fontFamily: "Helvetica",

				paddingTop: 100,
				paddingLeft: 50,
			},
			text: {
				fontSize: 20,
			},
			col: {
				flexDirection: "column",
			},
			row: {
				flexDirection: "row",
				justifyContent: "space-around",
			},
			right: {
				paddingRight: 50,
			},
			icon: {
				paddingTop: 15,
				width: 50,
			},
		});
		return (
			<View style={[style.details, style.row]}>
				<View style={style.col}>
					<Text style={style.text}>LINKS</Text>
					{props.result?.left_seal ? (
						<Image
							src={Check}
							style={style.icon}
						/>
					) : (
						<Image
							src={Cross}
							style={style.icon}
						/>
					)}
				</View>
				<View style={[style.col, style.right]}>
					<Text style={style.text}>RECHTS</Text>
					{props.result?.right_seal ? (
						<Image
							src={Check}
							style={style.icon}
						/>
					) : (
						<Image
							src={Cross}
							style={style.icon}
						/>
					)}
				</View>
			</View>
		);
	}
	function Disclaimer() {
		const style = StyleSheet.create({
			details: {
				fontFamily: "Helvetica",
				paddingTop: 25,
				paddingLeft: 50,
			},
			text: {
				position: "absolute",
				bottom: -350,
				left: 25,
				fontSize: 10,
				padding: 5,
				border: "1px solid black",
			},
			printed: {
				fontFamily: "Helvetica",
				position: "absolute",
				bottom: -300,
				left: 25,
				fontSize: 10,
			},
		});
		var date = new Date();
		return (
			<View style={style.details}>
				<Text style={style.printed}>
					Document door '{props.currentPortalUser}' geprint op: {date.toLocaleDateString()}
				</Text>

				<Text style={style.text}>
					Geldigheid van het rapport: Houd er rekening mee dat de vorm van uw gehoorgang in de loop van de tijd kan veranderen. Om het gewenste dempingsniveau te bereiken, moet de passing
					van de gehoorbescherming regelmatig worden gecontroleerd, overeenkomstig de plaatselijke wettelijke voorschriften in uw land.
				</Text>
			</View>
		);
	}
};
