import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { getButtonActions } from "../../api/brandconfig_apimanager_v2";
import { BC_Product } from "../../api/types/BC_Product";
import { ButtonType } from "../../api/types/ButtonType";
import { ListItem } from "../../api/types/ListItem";

interface Props {
	productConfig: BC_Product | undefined;
	productType: string | undefined;
	editConfiguration: boolean;
	noValue: string;
}

export default function DefaultButtons( props: Props) {
	const [buttonOptionsList, setButtonOptionsList] = useState<ListItem[]>();
	useEffect(
		function RetrieveButtonOptions() {
			if (props.productType) {
				var templist: ListItem[] = [];
				getButtonActions(props.productType).then((response) => {
					response.forEach((buttonoption: ButtonType) => {
						templist.push({ label: `(${buttonoption.Byte}) ${buttonoption.Name}`, value: buttonoption.Byte.toString() });
					});
					setButtonOptionsList(templist);
				});
			}
		},
		[props.productType, props.productConfig]
	);

	if ((props?.productConfig?.data?.default_buttons || props.editConfiguration) && buttonOptionsList)
		return (
			<Row>
				<Col>
					<Form.Label>
						<b>Left</b>
					</Form.Label>
					<br />
					Double press
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.left?.double_press.toString()) ?? buttonOptionsList[0]}
						name="left_double_press"
						id="left_double_press"
					/>
					Double tap
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.left?.double_tap.toString()) ?? buttonOptionsList[0]}
						name="left_double_tap"
						id="left_double_tap"
					/>
					Long press
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.left?.long_press.toString()) ?? buttonOptionsList[0]}
						name="left_long_press"
						id="left_long_press"
					/>
					Short press
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.left?.short_press.toString()) ?? buttonOptionsList[0]}
						name="left_short_press"
						id="left_short_press"
					/>
					Single tap
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.left?.single_tap.toString()) ?? buttonOptionsList[0]}
						name="left_single_tap"
						id="left_single_tap"
					/>
				</Col>
				<Col>
					<Form.Label>
						<b>Right</b>
					</Form.Label>
					<br />
					Double press
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.right?.double_press.toString()) ?? buttonOptionsList[0]}
						name="right_double_press"
						id="right_double_press"
					/>
					Double tap
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.right?.double_tap.toString()) ?? buttonOptionsList[0]}
						name="right_double_tap"
						id="right_double_tap"
					/>
					Long press
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.right?.long_press.toString()) ?? buttonOptionsList[0]}
						name="right_long_press"
						id="right_long_press"
					/>
					Short press
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.right?.short_press.toString()) ?? buttonOptionsList[0]}
						name="right_short_press"
						id="right_short_press"
					/>
					Single tap
					<Select
						required
						options={buttonOptionsList}
						defaultValue={buttonOptionsList?.find((e) => e.value === props?.productConfig?.data?.default_buttons?.right?.single_tap.toString()) ?? buttonOptionsList[0]}
						name="right_single_tap"
						id="right_single_tap"
					/>
				</Col>
			</Row>
		);
	else return <>No changes to Default Buttons</>;
}
