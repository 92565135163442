import { Form, Col, Row } from "react-bootstrap";
import React, { useState } from "react";

interface Props {
	name: string;
	colors: Colors | undefined;
}

interface Colors {
	red: number | 0;
	green: number | 0;
	blue: number | 0;
	alpha?: number | 0;
}

//* Converts the RGBA from the brandconfig to a hex which <InputColor> can use
//? Change the RGBA values in the brandconfig to hex?
function RGBToHex(r: number, g: number, b: number) {
	var red = r.toString(16);
	var green = g.toString(16);
	var blue = b.toString(16);
	if (red.length === 1) red = "0" + red;
	if (green.length === 1) green = "0" + green;
	if (blue.length === 1) blue = "0" + blue;

	return "#" + red + green + blue;
}

export default function ColorPicker(props: Props) {
	const [color, setColor] = useState<any>(RGBToHex(props?.colors?.red ?? 255, props?.colors?.green ?? 255, props?.colors?.blue ?? 255));
	const [alpha, setAlpha] = useState<any>(props?.colors?.alpha ?? 100);

	var name = props.name.replace(/ /g, "_").toLowerCase();

	const handleChange = (event: any) => {
		setColor(event.target.value);
	};

	const changeAlpha = (event: any) => {
		setAlpha(event.target.value);
	};

	return (
		<>
			<Col>
				<Form.Label>
					{" "}
					{props.name} <Form.Text>{color}</Form.Text>
				</Form.Label>
			</Col>

			<Col>
				<Row>
					<Form.Control
						required
						type="color"
						id={name}
						onChange={handleChange}
						value={color}
					></Form.Control>

					<Col sm={4}>
						<Form.Text>
							Alpha - {alpha}
							<Form.Range
								required
								title={alpha}
								id={name + "_alpha"}
								value={alpha}
								onChange={changeAlpha}
							/>
						</Form.Text>
					</Col>
				</Row>
			</Col>
		</>
	);
}
