import * as React from "react";
import { Container } from "react-bootstrap";

export default function NotSignedIn() {
	return (
		<Container className="paddingTopBottom">
			<h1>
				Not signed in please{" "}
				<a
					className="notsignedin"
					href="/home"
				>
					Login
				</a>
			</h1>
		</Container>
	);
}
