/* eslint-disable react/jsx-no-undef */
import { useAuth0 } from "@auth0/auth0-react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, ListGroup, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { createShipment, getCustomers, getShipments, getTenants, printPakbon } from "../api/orderportal_apimanager";
import { Customer } from "../api/types/Customer";
import { ListItem } from "../api/types/ListItem";
import { inShipping, Shipment, Shipments } from "../api/types/Shipment";
import { Tenant } from "../api/types/Tenant";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import { Pakbon } from "../modules/pdf/createPakbon";
import toastManager from "../modules/toastmanager";

export default function Shipping() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const [customers, setCustomers] = useState<Customer[]>();
	const [tenants, setTenants] = useState<Tenant[]>();
	const [selectedTenant, setSelectedTenant] = useState<Tenant>();
	const [tenantsList, setTenantsList] = useState<ListItem[]>();

	const [receivedShipments, setReceivedShipments] = useState<boolean>(false);

	const [readyForShipping, setReadyForShipping] = useState<Shipment[]>();

	const [readyToBePackaged, setReadyToBePackaged] = useState<inShipping[]>();
	const [packaged, setPackaged] = useState<inShipping[]>();
	const [shipped, setShipped] = useState<inShipping[]>();

	const [currentShipmentBatch, setCurrentShipmentBatch] = useState<string[]>([]);
	const [amountInShipmentBatch, setAmountInShipmentBatch] = useState<number>(currentShipmentBatch?.length);
	const [amountOfProducts, setAmountOfProducts] = useState<number>(0);

	function handleTenantChange(event: any) {
		setSelectedTenant(tenants?.find((e) => e.id === event.value));
		setCurrentShipmentBatch([]);
		setAmountInShipmentBatch(0);
		setAmountOfProducts(0);
	}

	function toggleShipmentBatch(shipment: Shipment) {
		var temp = currentShipmentBatch;
		const index = temp.indexOf(shipment.id);
		if (index !== -1) {
			temp.splice(index, 1);
			setAmountInShipmentBatch(amountInShipmentBatch - 1);
			setAmountOfProducts(amountOfProducts - shipment.prNumbers.length);
			setCurrentShipmentBatch(temp);
		} else {
			temp.push(shipment.id);
			setAmountInShipmentBatch(amountInShipmentBatch + 1);
			setAmountOfProducts(amountOfProducts + shipment.prNumbers.length);
			setCurrentShipmentBatch(temp);
		}
	}

	function confirmShipment(event: any) {
		if (event.target.form.track_and_trace.value) {
			createShipment(event.target.form.track_and_trace.value, currentShipmentBatch).then((response) => {
				if (response) {
					setReceivedShipments(false);
					toastManager("success", "Shipment created", `Shipment with Track & trace-code '${event.target.form.track_and_trace.value}' successfully created`);
					setCurrentShipmentBatch([]);
					setAmountInShipmentBatch(0);
				}
			});
		} else {
			toastManager("warning", `No batch name`, `A batch name is required confirm a print batch `);
		}
	}

	function PopulateReadyForShipping() {
		var temp_array: JSX.Element[] = [];
		if (readyForShipping && selectedTenant)
			readyForShipping
				.filter((shipment) => {
					return shipment.tenant_id === selectedTenant.id;
				})
				.forEach((shipment) => {
					var cust = customers?.find((e) => e.id === shipment.customer_id);
					var isSelected = currentShipmentBatch.some((id) => id === shipment.id);
					temp_array.push(
						<ListGroup.Item
							action
							onClick={() => {
								toggleShipmentBatch(shipment);
							}}
							variant={isSelected ? "success" : ""}
						>
							<Row>
								<Col sm={1}>
									<Image
										src={cust?.logo_url}
										height={"auto"}
										width={"25px"}
										onError={({ currentTarget }) => {
											currentTarget.onerror = null; // prevents looping
											currentTarget.src = "building.svg";
										}}
									/>
								</Col>
								<Col>{cust?.name}</Col>
								<Col>{shipment.referenceId}</Col>
								<Col className="center">{shipment.prNumbers.length}</Col>
							</Row>
						</ListGroup.Item>
					);
				});

		return <>{temp_array}</>;
	}

	function handlePrintPakbon(shipmentId: string) {
		printPakbon(shipmentId).then((response) => {
			if (response) {
				toastManager("success", "Pakbon printed", 'successfully registered printing this "pakbon"');
				setReceivedShipments(false);
			} else toastManager("error", "Not registered ", 'Registering printing this "pakbon" failed, please try again');
		});
	}

	function PopulateReadyToBePackaged() {
		var temp_array: JSX.Element[] = [];
		if (readyToBePackaged) {
			readyToBePackaged.forEach((shipment) => {
				var cust = customers?.find((e) => e.id === shipment.items[0].customer_id);
				var productAmount = 0;
				shipment.items.forEach((item) => {
					productAmount += item.prNumbers.length;
				});
				temp_array.push(
					<ListGroup.Item
						key={shipment.shippingID}
						className="download-pdf-button"
					>
						<Row>
							<Col sm={1}>
								<Image
									src={cust?.logo_url}
									height={"auto"}
									width={"25px"}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src = "building.svg";
									}}
								/>
							</Col>
							<Col>{shipment.items[0].trackingData}</Col>
							<Col
								sm={1}
								className="center"
							>
								{productAmount}
							</Col>
							<Col sm={2}>
								{customers && tenants && tenants[0] && (
									<PDFDownloadLink
										document={
											<Pakbon
												customers={customers}
												shipment={shipment}
												tenant={tenants?.find((e) => e.id === shipment.items[0].tenant_id) ?? tenants[0]}
												// ! tenants[0] is a fallback and dangerous here, .find() should always find a tenant. but if a tenant is not part of the current portal user this could give a problem!?!
											/>
										}
										fileName={`PAKBON_${shipment.shippingID}.pdf`}
										onClick={() => handlePrintPakbon(shipment.shippingID)}
									>
										{({ blob, url, loading, error }) => (loading ? "Loading.." : <Icon.Download />)}
									</PDFDownloadLink>
								)}
							</Col>
						</Row>
					</ListGroup.Item>
				);
			});
		}
		return <>{temp_array}</>;
	}
	function PopulatePackaged() {
		var temp_array: JSX.Element[] = [];
		if (packaged) {
			packaged.forEach((shipment) => {
				var cust = customers?.find((e) => e.id === shipment.items[0].customer_id);
				var productAmount = 0;
				shipment.items.forEach((item) => {
					productAmount += item.prNumbers.length;
				});
				temp_array.push(
					<ListGroup.Item
						key={shipment.shippingID}
						className="download-pdf-button"
					>
						<Row>
							<Col sm={1}>
								<Image
									src={cust?.logo_url}
									height={"auto"}
									width={"25px"}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src = "building.svg";
									}}
								/>
							</Col>
							<Col>{shipment.items[0].trackingData}</Col>
							<Col
								sm={1}
								className="center"
							>
								{productAmount}
							</Col>
							<Col sm={2}>
								{customers && tenants && tenants[0] && (
									<PDFDownloadLink
										document={
											<Pakbon
												customers={customers}
												shipment={shipment}
												tenant={tenants?.find((e) => e.id === shipment.items[0].tenant_id) ?? tenants[0]}
												// ! tenants[0] is a fallback and dangerous here, .find() should always find a tenant. but if a tenant is not part of the current portal user this could give a problem!?!
											/>
										}
										fileName={`PAKBON_${shipment.shippingID}.pdf`}
									>
										{({ blob, url, loading, error }) => (loading ? "Loading.." : <Icon.Download />)}
									</PDFDownloadLink>
								)}
							</Col>
						</Row>
					</ListGroup.Item>
				);
			});
		}
		return <>{temp_array}</>;
	}
	function PopulateShipping() {
		var temp_array: JSX.Element[] = [];
		if (shipped) {
			shipped.forEach((shipment) => {
				var cust = customers?.find((e) => e.id === shipment.items[0].customer_id);
				var productAmount = 0;
				shipment.items.forEach((item) => {
					productAmount += item.prNumbers.length;
				});
				var date = new Date(0);
				if (shipment.items[0].shipping) date.setUTCMilliseconds(shipment.items[0].shipping);

				temp_array.push(
					<ListGroup.Item key={shipment.shippingID}>
						<Row>
							<Col sm={1}>
								<Image
									src={cust?.logo_url}
									height={"auto"}
									width={"25px"}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src = "building.svg";
									}}
								/>
							</Col>
							<Col>{shipment.items[0].trackingData}</Col>
							<Col
								sm={1}
								className="center"
							>
								{productAmount}
							</Col>
							<Col sm={2}>{date.toLocaleDateString()}</Col>
						</Row>
					</ListGroup.Item>
				);
			});
		}
		return <>{temp_array}</>;
	}

	useEffect(
		function getTenantsFromAPI() {
			if (token && token.length && !tenants) {
				getTenants(token).then((response) => {
					if (response) {
						setTenants(response);
						setSelectedTenant(response[0]);
					}
				});
			}
		},
		[tenants, token]
	);

	useEffect(
		function getCustomersFromAPI() {
			if (token && token.length && !customers) {
				getCustomers(token).then((response) => {
					setCustomers(response);
				});
			}
		},
		[customers, token]
	);

	useEffect(
		function GetShipmentsFromAPI() {
			if (token && token.length && !receivedShipments)
				getShipments(token).then((response: Shipments) => {
					if (response) {
						setReadyForShipping(response.readyForShipping);
						var temp_readytobepackaged: inShipping[] = [];
						var temp_packaged: inShipping[] = [];
						var temp_shipped: inShipping[] = [];

						response.inShipping.forEach((inShipping) => {
							if (inShipping.items[0].pakbon_printed === false && inShipping.items[0].shipping === null) {
								temp_readytobepackaged.push(inShipping);
							} else if (inShipping.items[0].pakbon_printed === true && inShipping.items[0].shipping === null) {
								temp_packaged.push(inShipping);
							} else if (inShipping.items[0].pakbon_printed === true && inShipping.items[0].shipping !== null) {
								temp_shipped.push(inShipping);
							} else {
								console.error(inShipping);
							}
						});

						setReadyToBePackaged(temp_readytobepackaged);
						setPackaged(temp_packaged);
						setShipped(temp_shipped);
						setReceivedShipments(true);
					}
				});
		},
		[receivedShipments, token]
	);

	interface tenantsItems {
		tenant_id: string;
		tenant_name: string;
		items: number;
	}

	useEffect(function AddReadyToBePackagedToTenantsList() {
		if (readyForShipping && tenants) {
			var tenantitems: tenantsItems[] = [];
			tenants.forEach((tenant) => {
				tenantitems.push({ tenant_id: tenant.id, tenant_name: tenant.name, items: 0 });
			});

			readyForShipping.forEach((shipment) => {
				tenantitems.forEach((tenantitem) => {
					if (shipment.tenant_id === tenantitem.tenant_id) tenantitem.items++;
				});
			});
			var temp: ListItem[] = [];
			tenantitems.forEach((item) => {
				temp.push({ label: `[${item.items}] - ${item.tenant_name} `, value: item.tenant_id });
			});
			setTenantsList(temp)
		}
	}, [readyForShipping, tenants]);

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>

						<Row>
							<Col>
								<h1>Create shipment</h1>
								<Select
									options={tenantsList}
									onChange={handleTenantChange}
								/>
								<br />
								<Form>
									<Row>
										<Col className="middle">
											<Form.Control
												placeholder="Track & trace-code"
												maxLength={50}
												required
												type="input"
												id="track_and_trace"
											/>
										</Col>
										<Col>
											<Button
												onClick={confirmShipment}
												disabled={amountInShipmentBatch === 0}
											>
												Add {amountInShipmentBatch} order(s) <br /> with {amountOfProducts} product(s) <br /> to shipping batch
											</Button>
										</Col>
									</Row>
								</Form>

								<br />
								<ListGroup>
									<ListGroup.Item>
										<Row className="fw-bold">
											<Col sm={1}></Col>
											<Col>Customer</Col>
											<Col>reference</Col>
											<Col>Product count</Col>
										</Row>
									</ListGroup.Item>
									<PopulateReadyForShipping />
								</ListGroup>
							</Col>
							<Col>
								<h1>Ready to be packaged</h1>
								<hr />
								<ListGroup.Item>
									<Row className="fw-bold">
										<Col sm={1}></Col>
										<Col>Track and trace-code</Col>
										<Col
											sm={2}
											className="center"
										>
											Products
										</Col>
										<Col sm={2}>Download</Col>
									</Row>
								</ListGroup.Item>
								<PopulateReadyToBePackaged />
								<hr />
								<h1>Packaging</h1>
								<ListGroup.Item>
									<Row className="fw-bold">
										<Col sm={1}></Col>
										<Col>Track and trace-code</Col>
										<Col
											sm={2}
											className="center"
										>
											Products
										</Col>
										<Col sm={2}>Download</Col>
									</Row>
								</ListGroup.Item>
								<PopulatePackaged />
								<hr />
								<h1>In shipping</h1>
								<ListGroup.Item>
									<Row className="fw-bold">
										<Col sm={1}></Col>
										<Col>Track and trace-code</Col>
										<Col
											sm={2}
											className="center"
										>
											Products
										</Col>
										<Col sm={2}>Shipped</Col>
									</Row>
								</ListGroup.Item>
								<PopulateShipping />
							</Col>
						</Row>
					</Container>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
