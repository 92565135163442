import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navigation from "../modules/navigation";
import BackButton from "../modules/backbutton";
import NotSignedIn from "../modules/notsignedin";
import { getLogs } from "../api/orderportal_apimanager";

export default function Logs() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const tenantID = localStorage.getItem("tenant");

	useEffect(
		function getLogsFromAPI() {
			getLogs(token).then((response) => {
				console.log("response", response);
			});
		},
		[token]
	);

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<Container className="paddingTopBottom">
					<BackButton
						route={`/home`}
						title="Home"
					/>
				</Container>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
