/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getButtonActions, getProductConfig, getProductTree, postProductConfig, updateProductConfig } from "../api/brandconfig_apimanager_v2";
import { createCDNClient } from "../api/cdn_apimanager";
import { getCustomers, getDepartments, getTenants } from "../api/orderportal_apimanager";
import { BC_Product } from "../api/types/BC_Product";
import { BC_Tree } from "../api/types/BC_Tree";
import { Customer } from "../api/types/Customer";
import { Department } from "../api/types/Department";
import { ListItem } from "../api/types/ListItem";
import { Tenant } from "../api/types/Tenant";
import BackButton from "../modules/backbutton";
import Assets from "../modules/brandconfig/assets";
import AudioProfiles from "../modules/brandconfig/audioprofiles";
import ButtonOptions from "../modules/brandconfig/ButtonOptions";
import DefaultButtons from "../modules/brandconfig/defaultbuttons";
import DeviceConfig from "../modules/brandconfig/deviceconfig";
import Equalizer from "../modules/brandconfig/equalizer";
import Fonts from "../modules/brandconfig/fonts";
import Values from "../modules/brandconfig/values";
import { getChangedNameOrPlaceholder, getChangedValueOrPlaceholder } from "../modules/helpers";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import toastManager from "../modules/toastmanager";
import { ButtonType } from "../api/types/ButtonType";

export default function ProductConfig() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);
	createCDNClient(token);

	const queryParameters = new URLSearchParams(window.location.search);
	const [tenants, setTenants] = useState<Tenant[]>();
	const [tenantsList, setTenantsList] = useState<ListItem[]>();
	const [selectedTenant, setSelectedTenant] = useState<ListItem | undefined | null>();

	const [reloadForms, setReloadForms] = useState<boolean>(false);

	const [customers, setCustomers] = useState<Customer[]>();
	const [customersList, setCustomersList] = useState<ListItem[]>();
	const [selectedCustomer, setSelectedCustomer] = useState<ListItem | undefined | null>();

	const [departments, setDepartments] = useState<Department[]>();
	const [departmentsList, setDepartmentsList] = useState<ListItem[]>();
	const [selectedDepartment, setSelectedDepartment] = useState<ListItem | null | undefined>();

	enum VALIDATIONGROUP {
		"None",
		"DeviceName",
		"DefaultLEDColor",
		"DefaultAudioBalance",
		"DefaultButtons",
		"ButtonOptions",
		"Assets",
		"AudioProfiles",
		"DeviceConfig",
		"Equalizer",
		"Fonts",
		"Values",
	}

	const [currentValidationGroup, setCurrentValidationGroup] = useState<VALIDATIONGROUP>(VALIDATIONGROUP.None);

	const [copyPasteConfig, setCopyPasteConfig] = useState<boolean>(false);
	const [copyPaste, setCopyPaste] = useState<"copy" | "paste">();
	const [copiedData, setCopiedData] = useState<BC_Product>();

	const [productTree, setProductTree] = useState<BC_Tree>();
	const [productConfig, setProductConfig] = useState<BC_Product>();
	const [configName, setConfigName] = useState<string>(productConfig?.config_name ?? "");

	const [accordionActiveKey, setAccordionActiveKey] = useState<any>("config");
	const [configPath, setConfigPath] = useState<string | null>(queryParameters.get("path"));
	const [buttonOptionsList, setButtonOptionsList] = useState<ListItem[]>();


	const productTypes = [
		{
			label: "DP005",
			value: "DP005",
		},
		{
			label: "DP011",
			value: "DP011",
		},
	];

	const noValue = "—";

	const [selectedProduct, setSelectedProduct] = useState<ListItem | undefined | null>(productTypes[1]);

	const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);

	const [editConfiguration, setEditConfiguration] = useState<boolean>(false);

	const handleEditConfiguration = () => {
		if (configPath) setEditConfiguration(true);
		else toastManager("warning", "No path selected", "Please select a path or create a new configuration");
	};

	function getConfigName(): string {
		if (selectedDepartment) return selectedDepartment.label;
		else if (selectedCustomer) return selectedCustomer.label;
		else if (selectedTenant) return selectedTenant.label;
		return "no name";
	}

	function PopulateTreeButtons() {
		var temp: JSX.Element[] = [];
		if (customers)
			if (productTree?.children) {
				Object.entries(productTree.children).forEach((product) => {
					var productChanges = "";
					if (product[1].types?.length) {
						productChanges = product[1].types.join(", ");
					}

					temp.push(
						<ListGroup.Item
							key={`${product[0]}`}
							action
							onClick={() => handleSelectedConfig(`product/${product[0]}`)}
							className="brandconfig-link"
							disabled
							variant={configPath === `product/${product[0]}` ? "success" : ""}
						>
							<b>{product[0]} </b>
							<small>
								{product[1].name} <br />
								{productChanges !== "" ? productChanges : "no changes"}
							</small>
							<br />
						</ListGroup.Item>
					);

					if (product[1].children) {
						Object.entries(product[1].children).forEach((tenant) => {
							var tenantChanges = "";
							if (tenant[1].types?.length) {
								tenantChanges = tenant[1].types.join(", ");
							}
							temp.push(
								<ListGroup.Item
									key={`${product[0]}${tenant[0]}`}
									action
									onClick={() => handleSelectedConfig(`product/${product[0]}/tenant/${tenant[0]}`)}
									className="brandconfig-link"
									variant={configPath === `product/${product[0]}/tenant/${tenant[0]}` ? "success" : ""}
								>
									<b>
										{product[0]} / {tenant[1].name}{" "}
									</b>
									<small>
										{tenant[1].name} <br />
										{tenantChanges !== "" ? tenantChanges : "no changes"}
									</small>
									<br />
								</ListGroup.Item>
							);
							if (tenant[1].children) {
								Object.entries(tenant[1].children).forEach((customer) => {
									var customerChanges = "";
									if (customer[1].types?.length) {
										customerChanges = customer[1].types.join(", ");
									}

									temp.push(
										<ListGroup.Item
											key={`${product[0]}${tenant[0]}${customer[0]}`}
											action
											onClick={() => handleSelectedConfig(`product/${product[0]}/tenant/${tenant[0]}/customer/${customer[0]}`)}
											className="brandconfig-link"
											variant={configPath === `product/${product[0]}/tenant/${tenant[0]}/customer/${customer[0]}` ? "success" : ""}
										>
											<b>
												{" "}
												{product[0]} / {tenant[1].name} / {customer[1].name}{" "}
											</b>
											<small>
												{customer[1].name} <br />
												{customerChanges !== "" ? customerChanges : "no changes"}
											</small>
											<br />
										</ListGroup.Item>
									);

									if (customer[1].children) {
										Object.entries(customer[1].children).forEach((department) => {
											var departmentChanges = "";
											if (department[1].types?.length) {
												departmentChanges = department[1].types.join(", ");
											}

											temp.push(
												<ListGroup.Item
													key={`${product[0]}${tenant[0]}${customer[0]}${department[0]}`}
													action
													onClick={() => handleSelectedConfig(`product/${product[0]}/tenant/${tenant[0]}/customer/${customer[0]}/department/${department[0]}`)}
													className="brandconfig-link"
													variant={configPath === `product/${product[0]}/tenant/${tenant[0]}/customer/${customer[0]}/department/${department[0]}` ? "success" : ""}
												>
													<>
														<b>
															{" "}
															{product[0]} / {tenant[1].name} / {customer[1].name} / {department[1].name}{" "}
														</b>
													</>
													<small>
														{department[1].name} <br />
														{departmentChanges !== "" ? departmentChanges : "no changes"}
													</small>
													<br />
												</ListGroup.Item>
											);
										});
									}
								});
							}
						});
					}
				});
			}
		return <ListGroup>{temp}</ListGroup>;
	}

	function checkValidation(validationGroup: VALIDATIONGROUP) {
		if (currentValidationGroup === validationGroup) return true;
		else return false;
	}

	function handleProductChange(event: any) {
		setSelectedDepartment(null);
		setDepartmentsList(undefined);
		setSelectedCustomer(null);

		if (event === null) {
			setSelectedProduct(undefined);
		} else {
			setSelectedProduct(event);
		}
	}

	function populateCustomerList(ten: any) {
		var temp: ListItem[] = [];
		if (customers)
			customers.forEach((cust) => {
				if (cust.tenant_id === ten?.value) temp.push({ label: cust.name, value: cust.id });
			});
		setCustomersList(temp);
	}

	function handleTenantChange(event: any) {
		setSelectedCustomer(null);
		setCustomersList(undefined);

		setSelectedDepartment(null);
		setDepartmentsList(undefined);

		if (event === null) setSelectedTenant(undefined);
		else {
			setSelectedTenant(event);
			populateCustomerList(event);
		}
	}

	function handleCustomerChange(event: any) {
		setSelectedCustomer(null);
		setSelectedDepartment(null);
		setDepartmentsList(undefined);

		if (event === null) {
			setSelectedCustomer(undefined);
		} else {
			setSelectedCustomer(event);
		}
	}

	function handleDepartmentChange(event: any) {
		if (event === null) {
			setSelectedDepartment(undefined);
		} else {
			setSelectedDepartment(event);
		}
	}

	function checkForValidationErrors(inputs: any[]) {
		var invalidInputs: string[] = [];

		inputs.forEach((input) => {
			if ((!input.value && input.placeholder === noValue) || input.value === noValue) invalidInputs.push(input.id);
		});

		if (invalidInputs.length === 0) {
			setCurrentValidationGroup(VALIDATIONGROUP.None);
			return false;
		} else {
			var issues = "";
			invalidInputs.forEach((input) => {
				issues += `${input}, `;
			});
			toastManager("error", "Validation error", `Not all required fields are filled in. The following fields are empty: ${issues}`);
			return true;
		}
	}

	function handleNewConfig(event: any) {
		if (event.target.form.config_name.value === "") {
			toastManager("error", "Config name is empty!", "Please add a name for this new config to continue");
			return;
		}

		var path = `product/${selectedProduct?.value}/tenant/${selectedTenant?.value}`;
		if (selectedCustomer) {
			path += `/customer/${selectedCustomer.value}`;
			if (selectedDepartment) {
				path += `/department/${selectedDepartment.value}`;
			}
		}

		setConfigName(event.target.form.config_name.value);
		handleSelectedConfig(path);
		handleCloseModal();
	}
	function reloadConfig() {
		if (configPath) {
			getProductConfig(token, configPath).then((response) => {
				if (response) setProductConfig(response);
			});
		}
	}

	function saveDefaultDeviceName(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.DeviceName);

		if (!checkForValidationErrors([etf.default_device_name])) {
			updateProductConfig(configPath!, {
				default_device_name: getChangedValueOrPlaceholder(etf.default_device_name),
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Default Device Name", `Successfully updated 'Default Device Name' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}
	function pasteDefaultDeviceName() {
		updateProductConfig(configPath!, {
			default_device_name: copiedData?.data.default_device_name,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Default Device Name", `Successfully pasted 'Default Device Name' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveDefaultLEDColor(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.DefaultLEDColor);

		if (!checkForValidationErrors([etf.default_cradle_led_color_red, etf.default_cradle_led_color_green, etf.default_cradle_led_color_blue])) {
			updateProductConfig(configPath!, {
				default_cradle_led_color: {
					r: parseInt(getChangedValueOrPlaceholder(etf.default_cradle_led_color_red)),
					g: parseInt(getChangedValueOrPlaceholder(etf.default_cradle_led_color_green)),
					b: parseInt(getChangedValueOrPlaceholder(etf.default_cradle_led_color_blue)),
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Default Cradle LED color", `Successfully updated 'Default Cradle LED color' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}
	function pasteDefaultLEDColor() {
		updateProductConfig(configPath!, {
			default_cradle_led_color: copiedData?.data.default_cradle_led_color,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Default Device Name", `Successfully pasted 'Default Device Name' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveDefaultAudioBalance(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.DefaultAudioBalance);

		if (!checkForValidationErrors([etf.default_audio_balance])) {
			updateProductConfig(configPath!, {
				default_audio_balance: parseInt(getChangedValueOrPlaceholder(etf.default_audio_balance)),
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Default Audio Balance", `Successfully updated 'Default Audio Balance' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}
	function pasteDefaultAudioBalance() {
		updateProductConfig(configPath!, {
			default_audio_balance: copiedData?.data.default_audio_balance,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Default Audio Balance", `Successfully pasted 'Default Audio Balance' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveAssets(event: any) {
		var etf = event.currentTarget.form;
		const itemsInsideAssetsAccordion = 17; // A magic number but, each 17 items is a new accordion element in the form
		setCurrentValidationGroup(VALIDATIONGROUP.Assets);
		var assetArray: any[] | null = [];
		for (let index = 0; index < (etf.length - 1) / itemsInsideAssetsAccordion; index++) {
			var offset = index * itemsInsideAssetsAccordion;
			if (etf[offset + 1].checked) {
				assetArray.push({
					color_code: getChangedValueOrPlaceholder(etf[offset + 2]),
					assets: {
						cradle_closed: getChangedNameOrPlaceholder(etf[offset + 3]),
						cradle_open: getChangedNameOrPlaceholder(etf[offset + 4]),
						earbud_left: getChangedNameOrPlaceholder(etf[offset + 5]),
						earbud_right: getChangedNameOrPlaceholder(etf[offset + 6]),
						earbud_left_back: getChangedNameOrPlaceholder(etf[offset + 7]),
						earbud_right_back: getChangedNameOrPlaceholder(etf[offset + 8]),
						left_in_cradle: getChangedNameOrPlaceholder(etf[offset + 9]),
						right_in_cradle: getChangedNameOrPlaceholder(etf[offset + 10]),
						left_outside_cradle: getChangedNameOrPlaceholder(etf[offset + 11]),
						right_outside_cradle: getChangedNameOrPlaceholder(etf[offset + 12]),
						left_outside_cradle_primary: getChangedNameOrPlaceholder(etf[offset + 13]),
						right_outside_cradle_primary: getChangedNameOrPlaceholder(etf[offset + 14]),
						earbuds: getChangedNameOrPlaceholder(etf[offset + 15]),
						transition: getChangedNameOrPlaceholder(etf[offset + 16]),
					},
				});
			} else {
				toastManager(
					"warning",
					`Not including asset group ${getChangedValueOrPlaceholder(etf[offset + 2])}`,
					`Assetgroup ${getChangedValueOrPlaceholder(etf[offset + 2])} was not selected and will not be added to this config`
				);
			}
		}
		if (assetArray.length === 0) {
			updateProductConfig(configPath!, {
				assets: null,
			}).then((response) => {
				if (response) {
					toastManager("error", "Removed Assets", `Successfully removed 'Assets' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		} else {
			updateProductConfig(configPath!, {
				assets: {
					assets: assetArray,
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Assets", `Successfully updated 'Assets' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}
	function pasteAssets() {
		updateProductConfig(configPath!, {
			assets: copiedData?.data.assets,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Assets", `Successfully pasted 'Assets' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveAudioProfiles(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.AudioProfiles);

		const getIcon = (f2: any, f1: any, n: any, t1: any, t2: any) => {
			if (f2.checked === true) return "f2";
			else if (f1.checked === true) return "f1";
			else if (n.checked === true) return "n";
			else if (t1.checked === true) return "t1";
			else if (t2.checked === true) return "t2";
		};

		if (
			!checkForValidationErrors([
				etf.profile_1_name_nl,
				etf.profile_1_name_en,
				etf.profile_1_gain,
				etf.profile_1_biquad_0_0,
				etf.profile_1_biquad_0_1,
				etf.profile_1_biquad_0_2,
				etf.profile_1_biquad_0_3,
				etf.profile_1_biquad_0_4,
				etf.profile_1_biquad_1_0,
				etf.profile_1_biquad_1_1,
				etf.profile_1_biquad_1_2,
				etf.profile_1_biquad_1_3,
				etf.profile_1_biquad_1_4,
				etf.profile_1_biquad_2_0,
				etf.profile_1_biquad_2_1,
				etf.profile_1_biquad_2_2,
				etf.profile_1_biquad_2_3,
				etf.profile_1_biquad_2_4,
				etf.profile_1_biquad_3_0,
				etf.profile_1_biquad_3_1,
				etf.profile_1_biquad_3_2,
				etf.profile_1_biquad_3_3,
				etf.profile_1_biquad_3_4,
				etf.profile_1_biquad_4_0,
				etf.profile_1_biquad_4_1,
				etf.profile_1_biquad_4_2,
				etf.profile_1_biquad_4_3,
				etf.profile_1_biquad_4_4,
				etf.profile_1_biquad_5_0,
				etf.profile_1_biquad_5_1,
				etf.profile_1_biquad_5_2,
				etf.profile_1_biquad_5_3,
				etf.profile_1_biquad_5_4,
				etf.profile_2_name_nl,
				etf.profile_2_name_en,
				etf.profile_2_gain,
				etf.profile_2_biquad_0_0,
				etf.profile_2_biquad_0_1,
				etf.profile_2_biquad_0_2,
				etf.profile_2_biquad_0_3,
				etf.profile_2_biquad_0_4,
				etf.profile_2_biquad_1_0,
				etf.profile_2_biquad_1_1,
				etf.profile_2_biquad_1_2,
				etf.profile_2_biquad_1_3,
				etf.profile_2_biquad_1_4,
				etf.profile_2_biquad_2_0,
				etf.profile_2_biquad_2_1,
				etf.profile_2_biquad_2_2,
				etf.profile_2_biquad_2_3,
				etf.profile_2_biquad_2_4,
				etf.profile_2_biquad_3_0,
				etf.profile_2_biquad_3_1,
				etf.profile_2_biquad_3_2,
				etf.profile_2_biquad_3_3,
				etf.profile_2_biquad_3_4,
				etf.profile_2_biquad_4_0,
				etf.profile_2_biquad_4_1,
				etf.profile_2_biquad_4_2,
				etf.profile_2_biquad_4_3,
				etf.profile_2_biquad_4_4,
				etf.profile_2_biquad_5_0,
				etf.profile_2_biquad_5_1,
				etf.profile_2_biquad_5_2,
				etf.profile_2_biquad_5_3,
				etf.profile_2_biquad_5_4,
				etf.profile_3_name_nl,
				etf.profile_3_name_en,
				etf.profile_3_gain,
				etf.profile_3_biquad_0_0,
				etf.profile_3_biquad_0_1,
				etf.profile_3_biquad_0_2,
				etf.profile_3_biquad_0_3,
				etf.profile_3_biquad_0_4,
				etf.profile_3_biquad_1_0,
				etf.profile_3_biquad_1_1,
				etf.profile_3_biquad_1_2,
				etf.profile_3_biquad_1_3,
				etf.profile_3_biquad_1_4,
				etf.profile_3_biquad_2_0,
				etf.profile_3_biquad_2_1,
				etf.profile_3_biquad_2_2,
				etf.profile_3_biquad_2_3,
				etf.profile_3_biquad_2_4,
				etf.profile_3_biquad_3_0,
				etf.profile_3_biquad_3_1,
				etf.profile_3_biquad_3_2,
				etf.profile_3_biquad_3_3,
				etf.profile_3_biquad_3_4,
				etf.profile_3_biquad_4_0,
				etf.profile_3_biquad_4_1,
				etf.profile_3_biquad_4_2,
				etf.profile_3_biquad_4_3,
				etf.profile_3_biquad_4_4,
				etf.profile_3_biquad_5_0,
				etf.profile_3_biquad_5_1,
				etf.profile_3_biquad_5_2,
				etf.profile_3_biquad_5_3,
				etf.profile_3_biquad_5_4,
			])
		) {
			updateProductConfig(configPath!, {
				audio_profiles: {
					profile1: {
						biquads: [
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_0_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_0_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_0_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_0_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_0_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_1_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_1_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_1_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_1_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_1_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_2_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_2_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_2_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_2_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_2_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_3_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_3_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_3_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_3_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_3_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_4_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_4_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_4_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_4_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_4_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_5_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_5_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_5_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_5_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_1_biquad_5_4), 16),
							],
						],
						gain: parseInt(getChangedValueOrPlaceholder(etf.profile_1_gain)),
						icon: getIcon(etf.profile_1_f2, etf.profile_1_f1, etf.profile_1_n, etf.profile_1_t1, etf.profile_1_t2),
						name: [
							{
								language_code: "nl",
								value: getChangedValueOrPlaceholder(etf.profile_1_name_nl),
							},
							{
								language_code: "en",
								value: getChangedValueOrPlaceholder(etf.profile_1_name_en),
							},
						],
					},
					profile2: {
						biquads: [
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_0_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_0_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_0_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_0_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_0_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_1_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_1_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_1_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_1_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_1_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_2_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_2_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_2_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_2_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_2_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_3_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_3_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_3_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_3_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_3_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_4_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_4_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_4_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_4_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_4_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_5_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_5_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_5_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_5_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_2_biquad_5_4), 16),
							],
						],
						gain: parseInt(getChangedValueOrPlaceholder(etf.profile_2_gain)),
						icon: getIcon(etf.profile_2_f2, etf.profile_2_f1, etf.profile_2_n, etf.profile_2_t1, etf.profile_2_t2),
						name: [
							{
								language_code: "nl",
								value: getChangedValueOrPlaceholder(etf.profile_2_name_nl),
							},
							{
								language_code: "en",
								value: getChangedValueOrPlaceholder(etf.profile_2_name_en),
							},
						],
					},
					profile3: {
						biquads: [
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_0_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_0_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_0_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_0_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_0_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_1_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_1_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_1_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_1_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_1_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_2_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_2_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_2_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_2_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_2_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_3_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_3_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_3_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_3_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_3_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_4_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_4_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_4_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_4_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_4_4), 16),
							],
							[
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_5_0), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_5_1), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_5_2), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_5_3), 16),
								parseInt(getChangedValueOrPlaceholder(etf.profile_3_biquad_5_4), 16),
							],
						],
						gain: parseInt(getChangedValueOrPlaceholder(etf.profile_3_gain)),
						icon: getIcon(etf.profile_3_f2, etf.profile_3_f1, etf.profile_3_n, etf.profile_3_t1, etf.profile_3_t2),
						name: [
							{
								language_code: "nl",
								value: getChangedValueOrPlaceholder(etf.profile_3_name_nl),
							},
							{
								language_code: "en",
								value: getChangedValueOrPlaceholder(etf.profile_3_name_en),
							},
						],
					},
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Audio Profiles", `Successfully updated 'Audio Profiles' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}

	function pasteAudioProfiles() {
		updateProductConfig(configPath!, {
			audio_profiles: copiedData?.data.audio_profiles,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Audio Profiles", `Successfully pasted 'Audio Profiles' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveDefaultButtons(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.DefaultButtons);

		updateProductConfig(configPath!, {
			default_buttons: {
				left: {
					double_press: parseInt(getChangedValueOrPlaceholder(etf["left_double_press"])),
					double_tap: parseInt(getChangedValueOrPlaceholder(etf["left_double_tap"])),
					long_press: parseInt(getChangedValueOrPlaceholder(etf["left_long_press"])),
					short_press: parseInt(getChangedValueOrPlaceholder(etf["left_short_press"])),
					single_tap: parseInt(getChangedValueOrPlaceholder(etf["left_single_tap"])),
				},
				right: {
					double_press: parseInt(getChangedValueOrPlaceholder(etf["right_double_press"])),
					double_tap: parseInt(getChangedValueOrPlaceholder(etf["right_double_tap"])),
					long_press: parseInt(getChangedValueOrPlaceholder(etf["right_long_press"])),
					short_press: parseInt(getChangedValueOrPlaceholder(etf["right_short_press"])),
					single_tap: parseInt(getChangedValueOrPlaceholder(etf["right_single_tap"])),
				},
			},
		}).then((response) => {
			if (response) {
				toastManager("success", "Updated Default Buttons", `Successfully updated 'Default Buttons' for config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function pasteDefaultButtons() {
		updateProductConfig(configPath!, {
			default_buttons: copiedData?.data.default_buttons,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Default Buttons", `Successfully pasted 'Default Buttons' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function getSelectedButtonOptions(element: any) {
		var temp: string[] = [];
		if (!element.length) return temp;

		element.forEach((option: any) => {
			temp.push(option.value);
		});
		return temp;
	}

	function saveButtonOptions(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.ButtonOptions);

		updateProductConfig(configPath!, {
			button_options: {
				double_press: getSelectedButtonOptions(etf["double_press"]),
				double_tap: getSelectedButtonOptions(etf["double_tap"]),
				long_press: getSelectedButtonOptions(etf["long_press"]),
				short_press: getSelectedButtonOptions(etf["short_press"]),
				single_tap: getSelectedButtonOptions(etf["single_tap"]),
			},
		}).then((response) => {
			if (response) {
				toastManager("success", "Updated Button Options", `Successfully updated 'Button Options' for config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function pasteButtonOptions() {
		updateProductConfig(configPath!, {
			button_options: copiedData?.data.button_options,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Button Options", `Successfully pasted 'Button Options' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveDeviceConfig(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.DeviceConfig);
		if (!checkForValidationErrors([etf.level_detect_audio_profile, etf.level_detect_high, etf.level_detect_low, etf.level_detect_on, etf.vpu, etf.volume_balance_options])) {
			updateProductConfig(configPath!, {
				device_config: {
					level_detect_actions: parseInt(getChangedValueOrPlaceholder(etf.level_detect_actions)),
					level_detect_audio_profile: parseInt(getChangedValueOrPlaceholder(etf.level_detect_audio_profile)),
					level_detect_high: parseInt(getChangedValueOrPlaceholder(etf.level_detect_high)),
					level_detect_low: parseInt(getChangedValueOrPlaceholder(etf.level_detect_low)),
					level_detect_on: etf.level_detect_on.checked,
					vpu: etf.vpu.value,
					volume_balance_options: etf.volume_balance_options.value,
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Device Config", `Successfully updated 'Device Config' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}

	function pasteDeviceConfig() {
		updateProductConfig(configPath!, {
			device_config: copiedData?.data.device_config,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Default Device Name", `Successfully pasted 'Default Device Name' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveEqualizer(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.Equalizer);

		if (
			!checkForValidationErrors([
				etf.eq_preset_1_nl,
				etf.eq_preset_1_en,
				etf.eq_preset_1_1,
				etf.eq_preset_1_2,
				etf.eq_preset_1_3,
				etf.eq_preset_1_4,
				etf.eq_preset_1_5,
				etf.eq_preset_1_6,
				etf.eq_preset_1_7,
				etf.eq_preset_1_8,
				etf.eq_preset_1_9,
				etf.eq_preset_1_10,
				etf.eq_preset_1_11,
				etf.eq_preset_1_12,
				etf.eq_preset_1_13,
				etf.eq_preset_1_14,
				etf.eq_preset_1_15,

				etf.eq_preset_2_nl,
				etf.eq_preset_2_en,
				etf.eq_preset_2_1,
				etf.eq_preset_2_2,
				etf.eq_preset_2_3,
				etf.eq_preset_2_4,
				etf.eq_preset_2_5,
				etf.eq_preset_2_6,
				etf.eq_preset_2_7,
				etf.eq_preset_2_8,
				etf.eq_preset_2_9,
				etf.eq_preset_2_10,
				etf.eq_preset_2_11,
				etf.eq_preset_2_12,
				etf.eq_preset_2_13,
				etf.eq_preset_2_14,
				etf.eq_preset_2_15,

				etf.eq_preset_3_nl,
				etf.eq_preset_3_en,
				etf.eq_preset_3_1,
				etf.eq_preset_3_2,
				etf.eq_preset_3_3,
				etf.eq_preset_3_4,
				etf.eq_preset_3_5,
				etf.eq_preset_3_6,
				etf.eq_preset_3_7,
				etf.eq_preset_3_8,
				etf.eq_preset_3_9,
				etf.eq_preset_3_10,
				etf.eq_preset_3_11,
				etf.eq_preset_3_12,
				etf.eq_preset_3_13,
				etf.eq_preset_3_14,
				etf.eq_preset_3_15,

				etf.eq_preset_4_nl,
				etf.eq_preset_4_en,
				etf.eq_preset_4_1,
				etf.eq_preset_4_2,
				etf.eq_preset_4_3,
				etf.eq_preset_4_4,
				etf.eq_preset_4_5,
				etf.eq_preset_4_6,
				etf.eq_preset_4_7,
				etf.eq_preset_4_8,
				etf.eq_preset_4_9,
				etf.eq_preset_4_10,
				etf.eq_preset_4_11,
				etf.eq_preset_4_12,
				etf.eq_preset_4_13,
				etf.eq_preset_4_14,
				etf.eq_preset_4_15,

				etf.eq_preset_5_nl,
				etf.eq_preset_5_en,
				etf.eq_preset_5_1,
				etf.eq_preset_5_2,
				etf.eq_preset_5_3,
				etf.eq_preset_5_4,
				etf.eq_preset_5_5,
				etf.eq_preset_5_6,
				etf.eq_preset_5_7,
				etf.eq_preset_5_8,
				etf.eq_preset_5_9,
				etf.eq_preset_5_10,
				etf.eq_preset_5_11,
				etf.eq_preset_5_12,
				etf.eq_preset_5_13,
				etf.eq_preset_5_14,
				etf.eq_preset_5_15,

				etf.eq_preset_6_nl,
				etf.eq_preset_6_en,
				etf.eq_preset_6_1,
				etf.eq_preset_6_2,
				etf.eq_preset_6_3,
				etf.eq_preset_6_4,
				etf.eq_preset_6_5,
				etf.eq_preset_6_6,
				etf.eq_preset_6_7,
				etf.eq_preset_6_8,
				etf.eq_preset_6_9,
				etf.eq_preset_6_10,
				etf.eq_preset_6_11,
				etf.eq_preset_6_12,
				etf.eq_preset_6_13,
				etf.eq_preset_6_14,
				etf.eq_preset_6_15,
			])
		) {
			updateProductConfig(configPath!, {
				equalizer: {
					presets: [
						{
							name: [
								{
									language_code: "nl",
									value: getChangedValueOrPlaceholder(etf.eq_preset_1_nl),
								},
								{
									language_code: "en",
									value: getChangedValueOrPlaceholder(etf.eq_preset_1_en),
								},
							],
							values: [
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_0)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_1)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_2)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_3)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_4)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_5)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_6)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_7)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_8)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_9)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_10)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_11)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_12)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_13)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_14)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_1_15)),
							],
						},
						{
							name: [
								{
									language_code: "nl",
									value: getChangedValueOrPlaceholder(etf.eq_preset_2_nl),
								},
								{
									language_code: "en",
									value: getChangedValueOrPlaceholder(etf.eq_preset_2_en),
								},
							],
							values: [
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_0)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_1)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_2)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_3)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_4)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_5)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_6)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_7)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_8)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_9)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_10)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_11)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_12)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_13)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_14)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_2_15)),
							],
						},
						{
							name: [
								{
									language_code: "nl",
									value: getChangedValueOrPlaceholder(etf.eq_preset_3_nl),
								},
								{
									language_code: "en",
									value: getChangedValueOrPlaceholder(etf.eq_preset_3_en),
								},
							],
							values: [
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_0)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_1)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_2)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_3)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_4)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_5)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_6)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_7)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_8)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_9)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_10)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_11)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_12)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_13)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_14)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_3_15)),
							],
						},
						{
							name: [
								{
									language_code: "nl",
									value: getChangedValueOrPlaceholder(etf.eq_preset_4_nl),
								},
								{
									language_code: "en",
									value: getChangedValueOrPlaceholder(etf.eq_preset_4_en),
								},
							],
							values: [
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_0)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_1)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_2)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_3)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_4)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_5)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_6)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_7)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_8)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_9)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_10)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_11)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_12)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_13)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_14)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_4_15)),
							],
						},
						{
							name: [
								{
									language_code: "nl",
									value: getChangedValueOrPlaceholder(etf.eq_preset_5_nl),
								},
								{
									language_code: "en",
									value: getChangedValueOrPlaceholder(etf.eq_preset_5_en),
								},
							],
							values: [
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_0)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_1)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_2)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_3)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_4)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_5)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_6)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_7)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_8)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_9)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_10)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_11)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_12)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_13)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_14)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_5_15)),
							],
						},
						{
							name: [
								{
									language_code: "nl",
									value: getChangedValueOrPlaceholder(etf.eq_preset_6_nl),
								},
								{
									language_code: "en",
									value: getChangedValueOrPlaceholder(etf.eq_preset_6_en),
								},
							],
							values: [
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_0)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_1)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_2)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_3)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_4)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_5)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_6)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_7)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_8)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_9)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_10)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_11)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_12)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_13)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_14)),
								parseInt(getChangedValueOrPlaceholder(etf.eq_preset_6_15)),
							],
						},
					],
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Equalizer", `Successfully updated 'Equalizer' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}

	function pasteEqualizer() {
		updateProductConfig(configPath!, {
			equalizer: copiedData?.data.equalizer,
		}).then((response) => {
			if (response) {
				toastManager("success", "Pasted Equalizer", `Successfully pasted 'Equalizer' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveFonts(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.Fonts);

		if (
			!checkForValidationErrors([
				etf.big_body_size,
				etf.big_body_type,
				etf.big_body_caps,
				etf.big_title_size,
				etf.big_title_type,
				etf.big_title_caps,
				etf.body_size,
				etf.body_type,
				etf.body_caps,
				etf.button_size,
				etf.button_type,
				etf.button_caps,

				etf.control_active_size,
				etf.control_active_type,
				etf.control_active_caps,
				etf.control_inactive_size,
				etf.control_inactive_type,
				etf.control_inactive_caps,
				etf.header_1_size,
				etf.header_1_type,
				etf.header_1_caps,
				etf.header_2_size,
				etf.header_2_type,
				etf.header_2_caps,

				etf.label_size,
				etf.label_type,
				etf.label_caps,
				etf.link_size,
				etf.link_type,
				etf.link_caps,
				etf.subtitle_size,
				etf.subtitle_type,
				etf.subtitle_caps,
				etf.tab_active_size,
				etf.tab_active_type,
				etf.tab_active_caps,

				etf.tab_inactive_size,
				etf.tab_inactive_type,
				etf.tab_inactive_caps,
				etf.title_size,
				etf.title_type,
				etf.title_caps,
				etf.huge_title_size,
				etf.huge_title_type,
				etf.huge_title_caps,

				etf.regular_font,
				etf.bold_font,
			])
		) {
			updateProductConfig(configPath!, {
				fonts: {
					fonts: {
						bold: getChangedValueOrPlaceholder(etf.bold_font),
						regular: getChangedValueOrPlaceholder(etf.regular_font),
					},

					styles: {
						big_body: {
							caps: etf.big_body_caps.value === "1" ? true : false,
							font: etf.big_body_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.big_body_size)),
						},
						big_title: {
							caps: etf.big_title_caps.value === "1" ? true : false,
							font: etf.big_title_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.big_title_size)),
						},
						body: {
							caps: etf.body_caps.value === "1" ? true : false,
							font: etf.body_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.body_size)),
						},
						button: {
							caps: etf.button_caps.value === "1" ? true : false,
							font: etf.button_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.button_size)),
						},
						control_active: {
							caps: etf.control_active_caps.value === "1" ? true : false,
							font: etf.control_active_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.control_active_size)),
						},
						control_inactive: {
							caps: etf.control_inactive_caps.value === "1" ? true : false,
							font: etf.control_inactive_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.control_inactive_size)),
						},
						header_1: {
							caps: etf.header_1_caps.value === "1" ? true : false,
							font: etf.header_1_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.header_1_size)),
						},
						header_2: {
							caps: etf.header_2_caps.value === "1" ? true : false,
							font: etf.header_2_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.header_2_size)),
						},
						label: {
							caps: etf.label_caps.value === "1" ? true : false,
							font: etf.label_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.label_size)),
						},
						link: {
							caps: etf.link_caps.value === "1" ? true : false,
							font: etf.link_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.link_size)),
						},
						subtitle: {
							caps: etf.subtitle_caps.value === "1" ? true : false,
							font: etf.subtitle_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.subtitle_size)),
						},
						tab_active: {
							caps: etf.tab_active_caps.value === "1" ? true : false,
							font: etf.tab_active_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.tab_active_size)),
						},
						tab_inactive: {
							caps: etf.tab_inactive_caps.value === "1" ? true : false,
							font: etf.tab_inactive_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.tab_inactive_size)),
						},
						title: {
							caps: etf.title_caps.value === "1" ? true : false,
							font: etf.title_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.title_size)),
						},
						huge_title: {
							caps: etf.huge_title_caps.value === "1" ? true : false,
							font: etf.huge_title_type.value,
							size: parseInt(getChangedValueOrPlaceholder(etf.huge_title_size)),
						},
					},
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Fonts", `Successfully updated 'Fonts' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}

	function pasteFonts() {
		updateProductConfig(configPath!, {
			fonts: copiedData?.data.fonts,
		}).then((response) => {
			if (response) {
				toastManager("success", "Fonts", `Successfully pasted 'Fonts' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function saveValues(event: any) {
		var etf = event.currentTarget.form;
		setCurrentValidationGroup(VALIDATIONGROUP.Values);

		if (
			!checkForValidationErrors([etf.button_corner_radius, etf.container_corner_radius, etf.control_corner_radius, etf.sheet_corner_radius, etf.button_style_primary, etf.button_style_secondary])
		) {
			updateProductConfig(configPath!, {
				values: {
					button_corner_radius: parseInt(getChangedValueOrPlaceholder(etf.button_corner_radius)),
					container_corner_radius: parseInt(getChangedValueOrPlaceholder(etf.container_corner_radius)),
					control_corner_radius: parseInt(getChangedValueOrPlaceholder(etf.control_corner_radius)),
					sheet_corner_radius: parseInt(getChangedValueOrPlaceholder(etf.sheet_corner_radius)),
					button_style_primary: etf.button_style_primary.value,
					button_style_secondary: etf.button_style_secondary.value,
				},
			}).then((response) => {
				if (response) {
					toastManager("success", "Updated Values", `Successfully updated 'Values' for config with the name: '${response.config_name}'`);
				}
				reloadConfig();
			});
		}
	}

	function pasteValues() {
		updateProductConfig(configPath!, {
			values: copiedData?.data.values,
		}).then((response) => {
			if (response) {
				toastManager("success", "PastedValues", `Successfully pasted 'Values' to config with the name: '${response.config_name}'`);
			}
			reloadConfig();
		});
	}

	function Separator() {
		return (
			<>
				<Icon.ArrowDown />
				<br />
				<br />
			</>
		);
	}

	function handleCopyPasteConfig(pastingConfig: VALIDATIONGROUP) {
		switch (pastingConfig) {
			case VALIDATIONGROUP.None:
				if (copyPaste !== "paste") {
					setCopiedData(productConfig);
					toastManager("info", "Copied data", `Copied brandconfig data`);
					setCopyPaste("paste");
					setCopyPasteConfig(!copyPasteConfig);
				} else if (copyPaste === "paste") {
					setCopyPaste("copy");
					toastManager("error", "Stopped copying data", `Removed copied data from clipboard`);
					setCopiedData(undefined);
					setCopyPasteConfig(!copyPasteConfig);
				}
				break;
			case VALIDATIONGROUP.DeviceName:
				pasteDefaultDeviceName();
				break;
			case VALIDATIONGROUP.DefaultLEDColor:
				pasteDefaultLEDColor();
				break;
			case VALIDATIONGROUP.DefaultAudioBalance:
				pasteDefaultAudioBalance();
				break;
			case VALIDATIONGROUP.DefaultButtons:
				pasteDefaultButtons();
				break;
			case VALIDATIONGROUP.ButtonOptions:
				pasteButtonOptions();
				break;
			case VALIDATIONGROUP.Assets:
				pasteAssets();
				break;
			case VALIDATIONGROUP.AudioProfiles:
				pasteAudioProfiles();
				break;
			case VALIDATIONGROUP.DeviceConfig:
				pasteDeviceConfig();
				break;
			case VALIDATIONGROUP.Equalizer:
				pasteEqualizer();
				break;
			case VALIDATIONGROUP.Fonts:
				pasteFonts();
				break;
			case VALIDATIONGROUP.Values:
				pasteValues();
				break;
			default:
				break;
		}
	}


	useEffect(
		function RetrieveButtonOptions() {
			if (selectedProduct?.value) {
				var templist: ListItem[] = [];
				getButtonActions(selectedProduct.value).then((response) => {
					response.forEach((buttonoption: ButtonType) => {
						templist.push({ label: `(${buttonoption.Byte}) ${buttonoption.Name}`, value: buttonoption.Key });
					});
					setButtonOptionsList(templist);
				});
			}
		},
		[productConfig, selectedProduct?.value]
	);

	function handleSelectedConfig(path: string) {
		setAccordionActiveKey("");
		setConfigPath(path);
		queryParameters.set("path", path ?? "");
		handleReloadForms();
		getProductConfig(token, path).then((response) => {
			if (response) setProductConfig(response);
			window.scrollTo({ top: 0, behavior: "auto" });
		});
	}

	useEffect(function getConfigFromURLOnPageLoad() {
		if (configPath && !productConfig) {
			handleSelectedConfig(configPath);
		}
	}, []);

	useEffect(
		function getDepartmentsFromAPI() {
			if (selectedCustomer && token && token.length) {
				getDepartments(token, selectedCustomer.value).then((response) => {
					if (response) {
						setDepartments(response);
						var temp: ListItem[] = [];
						response.forEach((dep: Department) => {
							temp.push({ label: dep.name, value: dep.id });
						});
						setDepartmentsList(temp);
					}
				});
			}
		},
		[selectedCustomer, token]
	);

	useEffect(
		function getCustomersFromAPI() {
			if (token && token.length)
				getCustomers(token).then((response) => {
					if (response) {
						setCustomers(response);
						var temp: ListItem[] = [];
						response.forEach((cust: Customer) => {
							temp.push({ label: cust.name, value: cust.id });
						});
						setCustomersList(temp);
					}
				});
		},
		[token]
	);

	useEffect(
		function getTenantsFromAPI() {
			if (token && token.length) {
				getTenants(token).then((response) => {
					if (response) {
						setTenants(response);
						var temp: ListItem[] = [];
						response.forEach((ten: Tenant) => {
							temp.push({ label: ten.name, value: ten.id });
						});
						setTenantsList(temp);
						setSelectedTenant(temp[0]);
					}
				});
			}
		},
		[token]
	);

	useEffect(
		function getProductTreeFromAPI() {
			if (token && !productTree)
				getProductTree(token).then((response) => {
					if (response) setProductTree(response);
				});
		},
		[productTree, token]
	);

	function handleReloadForms() {
		setReloadForms(true);
		setTimeout(() => setReloadForms(false), 10);
	}

	function copyURL() {
		let sharableURL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_AUTH0_DEV_REDIRECT_URL : process.env.REACT_APP_AUTH0_REDIRECT_URL;

		navigator.clipboard.writeText(`${sharableURL}/adminpanel/productconfig?path=${configPath}`);
		toastManager("info", `Copied to clipboard!`, `Copied a sharable URL to your clipboard`);
	}

	const renderTooltip = (props: any) => {
		if (editConfiguration)
			return (
				<Tooltip
					id="button-tooltip"
					{...props}
				>
					Exit edit mode to add a new configuration
				</Tooltip>
			);
		else return <></>;
	};

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route={`/adminpanel`}
							title="admin panel"
						/>
						<h1>Product configuration </h1>
						<Accordion
							activeKey={accordionActiveKey}
							onSelect={setAccordionActiveKey}
						>
							<Accordion.Item eventKey={"config"}>
								<Accordion.Header>Current configurations: </Accordion.Header>
								<Accordion.Body>
									<Button
										className="right"
										onClick={() => setProductTree(undefined)}
									>
										{" "}
										<Icon.ArrowClockwise />
									</Button>
									<PopulateTreeButtons />
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<br />
						{!reloadForms && (
							<>
								<Row className="center">
									<Col>
										<OverlayTrigger
											placement="right"
											delay={{ show: 250, hide: 400 }}
											overlay={renderTooltip}
										>
											<span>
												<Button
													variant="success"
													onClick={handleShowModal}
													disabled={editConfiguration}
												>
													<Icon.TerminalPlus /> Add new configuration
												</Button>
											</span>
										</OverlayTrigger>
									</Col>
									<Col>
										<Button
											variant={copyPasteConfig ? "danger" : "secondary"}
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.None)}
											disabled={editConfiguration}
										>
											<Icon.CodeSquare /> {copyPasteConfig ? "Cancel pasting" : "Copy configuration"}
										</Button>
									</Col>
									<Col>
										{!editConfiguration && (
											<Button
												variant="primary"
												onClick={handleEditConfiguration}
												disabled={copyPasteConfig}
											>
												<Icon.Terminal /> Edit configuration
											</Button>
										)}
										{editConfiguration && (
											<Button
												variant="danger"
												onClick={() => setEditConfiguration(false)}
											>
												<Icon.Terminal /> Stop editing
											</Button>
										)}
									</Col>
								</Row>
								<hr />
								<Row className="center">
									{" "}
									<Col>
										<small>
											{" "}
											<b>Current configuration path:</b> {configPath}
											<Button
												size="sm"
												onClick={copyURL}
												className="right"
											>
												<Icon.ShareFill />
											</Button>
										</small>
									</Col>
								</Row>
								<hr />
								<Row>
									<Col>
										<h3>Config name</h3>
									</Col>
									<Col>
										<h3>Product</h3>
									</Col>
									<Col>
										<h3>Default device name</h3>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form>
											<Form.Control
												id="config_name"
												type="input"
												disabled
												placeholder={productConfig?.config_name ? productConfig.config_name : configName ?? noValue}
											/>
										</Form>
									</Col>
									<Col>
										{productConfig?.product || editConfiguration ? (
											<Form.Control
												required
												id="product"
												disabled
												type="input"
												value={productConfig?.product}
											/>
										) : (
											<>No changes to Product</>
										)}
									</Col>
									<Col>
										<Form validated={checkValidation(VALIDATIONGROUP.DeviceName)}>
											{productConfig?.data?.default_device_name || editConfiguration ? (
												<Form.Control
													required={productConfig?.data?.default_device_name === undefined}
													id="default_device_name"
													type="input"
													placeholder={productConfig?.data?.default_device_name !== undefined ? productConfig?.data.default_device_name : noValue}
												/>
											) : (
												<>No changes to Default device name</>
											)}
											{editConfiguration && (
												<Button
													className="right"
													onClick={saveDefaultDeviceName}
												>
													<Icon.Floppy />
												</Button>
											)}
											{copyPasteConfig && (
												<Button
													className="right"
													onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.DeviceName)}
												>
													{<Icon.Save />}
												</Button>
											)}
										</Form>
									</Col>
								</Row>
								<h3>Default cradle LED color</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.DefaultLEDColor)}>
									{productConfig?.data?.default_cradle_led_color || editConfiguration ? (
										<Row>
											<Col>
												<Form.Label>Red </Form.Label>
												<Form.Control
													required
													id="default_cradle_led_color_red"
													type="input"
													placeholder={productConfig?.data?.default_cradle_led_color?.r !== undefined ? productConfig.data.default_cradle_led_color.r.toString() : noValue}
												/>
											</Col>
											<Col>
												<Form.Label>Green</Form.Label>
												<Form.Control
													required
													id="default_cradle_led_color_green"
													type="input"
													placeholder={productConfig?.data?.default_cradle_led_color?.g !== undefined ? productConfig?.data.default_cradle_led_color.g.toString() : noValue}
												/>
											</Col>
											<Col>
												<Form.Label>Blue</Form.Label>
												<Form.Control
													required
													id="default_cradle_led_color_blue"
													type="input"
													placeholder={productConfig?.data?.default_cradle_led_color?.b !== undefined ? productConfig.data.default_cradle_led_color.b.toString() : noValue}
												/>
											</Col>
										</Row>
									) : (
										"No changes to Default cradle LED color"
									)}
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveDefaultLEDColor}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.DefaultLEDColor)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<h3>Default audio balance</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.DefaultAudioBalance)}>
									{productConfig?.data?.default_audio_balance !== undefined || editConfiguration ? (
										<Form.Control
											required={productConfig?.data?.default_audio_balance === undefined}
											id="default_audio_balance"
											type="input"
											placeholder={productConfig?.data?.default_audio_balance !== undefined ? productConfig?.data.default_audio_balance.toString() : noValue}
										/>
									) : (
										"No changes to Default audio balance"
									)}
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveDefaultAudioBalance}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.DefaultAudioBalance)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<br />
								<h3>Assets</h3>
								<Form>
									<Assets
										productConfig={productConfig}
										editConfiguration={editConfiguration}
										noValue={noValue}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveAssets}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.Assets)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<br />
								<h3>Audio profiles</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.AudioProfiles)}>
									<AudioProfiles
										productConfig={productConfig}
										editConfiguration={editConfiguration}
										noValue={noValue}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveAudioProfiles}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.AudioProfiles)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<br />
								<h3>Default Buttons</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.DefaultButtons)}>
									<DefaultButtons
										editConfiguration={editConfiguration}
										noValue={noValue}
										productConfig={productConfig}
										productType={selectedProduct?.value}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveDefaultButtons}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.DefaultButtons)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<br />
								<h3>Button Options</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.ButtonOptions)}>
									<ButtonOptions
										editConfiguration={editConfiguration}
										noValue={noValue}
										productConfig={productConfig}
										productType={selectedProduct?.value}
										buttonActions={buttonOptionsList}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveButtonOptions}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.ButtonOptions)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<h3>Device Config</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.DeviceConfig)}>
									<DeviceConfig
										productConfig={productConfig}
										editConfiguration={editConfiguration}
										noValue={noValue}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveDeviceConfig}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.DeviceConfig)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<br />
								<h3>Equalizer</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.Equalizer)}>
									<Equalizer
										productConfig={productConfig}
										editConfiguration={editConfiguration}
										noValue={noValue}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveEqualizer}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.Equalizer)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<br />
								<h3>Fonts</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.Fonts)}>
									<Fonts
										productConfig={productConfig}
										editConfiguration={editConfiguration}
										noValue={noValue}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveFonts}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.Fonts)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<br />
								<h3>Values</h3>
								<Form validated={checkValidation(VALIDATIONGROUP.Values)}>
									<Values
										productConfig={productConfig}
										editConfiguration={editConfiguration}
										noValue={noValue}
									/>
									{editConfiguration && (
										<Button
											className="right"
											onClick={saveValues}
										>
											<Icon.Floppy />
										</Button>
									)}
									{copyPasteConfig && (
										<Button
											className="right"
											onClick={() => handleCopyPasteConfig(VALIDATIONGROUP.Values)}
										>
											{<Icon.Save />}
										</Button>
									)}
								</Form>
								<br />
							</>
						)}
					</Container>
					<Modal
						show={showModal}
						onHide={handleCloseModal}
					>
						<Modal.Header closeButton>
							<Modal.Title>Add a new product configuration</Modal.Title>
						</Modal.Header>
						<Form>
							<Modal.Body>
								<Form.Label className="fw-bold">Product </Form.Label>
								<Select
									options={productTypes}
									defaultValue={productTypes[1]}
									onChange={handleProductChange}
								/>
								<Separator />
								<Form.Label className="fw-bold">Tenant</Form.Label>
								<Select
									options={tenantsList}
									onChange={handleTenantChange}
									value={selectedTenant}
								/>
								<Separator />
								<Form.Label className="fw-bold">Customer</Form.Label>
								<Select
									isClearable
									options={customersList}
									onChange={handleCustomerChange}
									isDisabled={!selectedTenant}
									value={selectedCustomer}
								/>
								<Separator />
								<Form.Label className="fw-bold">Department</Form.Label>
								<Select
									isClearable
									options={departmentsList}
									onChange={handleDepartmentChange}
									isDisabled={!selectedCustomer}
									value={selectedDepartment}
								/>
								<br />
								Current selected config path: <br />
								<small className="fw-bold">
									{!selectedCustomer && !selectedDepartment && <>{`${selectedProduct?.label} -> ${selectedTenant?.label}`}</>}

									{selectedCustomer && !selectedDepartment && <>{`${selectedProduct?.label} -> ${selectedTenant?.label} -> ${selectedCustomer.label}`}</>}

									{selectedCustomer && selectedDepartment && (
										<>{`${selectedProduct?.label} -> ${selectedTenant?.label} -> ${selectedCustomer.label} -> ${selectedDepartment.label}`}</>
									)}
								</small>
								<br />
								<br />
								<br />
								<Form.Label>Config name:</Form.Label>
								<Form.Control
									disabled
									type="input"
									id="config_name"
									value={getConfigName()}
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={handleNewConfig}>Add config</Button>
							</Modal.Footer>
						</Form>
					</Modal>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
